import React, {useState} from 'react';
import debug from 'debug';
import {useDispatch, useSelector} from 'react-redux';
import {doUpdateLoginPolicy, doUpdateMfaToken, GrantType, loginSuccessfully, startLogin, startMfaChallenge} from '../_authSlice';
import {selectCustomerConfig, selectDisplayCompanyLogo, selectHasAzureProvider, selectUserAgentId} from '../_authSliceSelect';
import {useForm} from 'react-hook-form';
import {superstructResolver} from '@hookform/resolvers/superstruct';
import {Form} from 'react-bootstrap';
import * as s from 'superstruct';
import {define, size, string} from 'superstruct';
import isEmail from 'is-email';
import _ from 'lodash';
import {useHistory} from 'react-router-dom';
import {unwrapResult} from '@reduxjs/toolkit';
import {addToast} from '../../common/toast';
import {hasError} from '../../common/validation';
import {SfPassword} from '../../form/SfPassword';
import {LinkForgotPassword} from '../password/LinkForgotPassword';
import {LicenseTerms} from '../LicesnseTerms';
import {SfButton} from "../../form/SfButton";
import {portal} from "../../common/env";

const logger = debug('sf:ui:component:auth:BasicLogin');

export const BasicLogin = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const customerConfig = useSelector(selectCustomerConfig);
  const userAgentId = useSelector(selectUserAgentId);
  const [usernameErrorMessage, setUsernameErrorMessage] = useState('');
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [disabled, setDisabled] = useState(false);

  const Email = define('Email', isEmail);
  const schema = s.object({
    username: Email,
    password: size(string(), 10, 60)
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: superstructResolver(schema, {
      coerce: true
    })
  });
  const onSubmit = (data) => {
    setUsernameErrorMessage('');
    setPasswordErrorMessage('');
    if (!customerConfig || !customerConfig.realmId) {
      addToast('customer config not ready', 'error');
      return;
    }
    setDisabled(true);
    dispatch(
      startLogin({
        u: data.username,
        p: data.password,
        gt: GrantType.PASSWORD,
        ug: userAgentId
      })
    )
      .then(unwrapResult)
      .then((result) => {
        logger(result);
        loginSuccessfully({ dispatch, history, portal });
      })
      .catch((error) => {
        if (error.code === 'MFA_REQUIRED') {
          const mfaToken = _.get(error, 'parameters[0]');
          startMfaFLow(mfaToken);
        } else if (error.code === 'ERR_CODE_INVALID_CREDENTIALS') {
          const param = _.find(_.get(error, 'parameters', []), function (e) {
            return e.name === 'REMAINING_COUNT';
          });
          const remainingCount = _.get(param, 'value', 0);
          setPasswordErrorMessage(`The email or password entered is incorrect. You have ${remainingCount} attempts remaining.`);
        } else if (error.code === 'ACCOUNT_LOCKOUT_TIME') {
          setPasswordErrorMessage('Too many incorrect login attempts. Your account has been disabled.');
        } else {
          addToast(error.message, 'error');
        }
      })
      .finally(() => {
        setDisabled(false);
      });
  };

  async function startMfaFLow(mfaToken) {
    logger('MFA login is required: ', mfaToken);
    await dispatch(doUpdateMfaToken(mfaToken));
    // `startMfaChallenge` will be moved to UI for button to trigger if needed.
    dispatch(
      startMfaChallenge({
        mfaToken,
        type: 'PUSH_CODE',
        method: 'EMAIL'
      })
    )
      .then(unwrapResult)
      .then(() => {
        history.push('/auth/mfa');
        logger('MFA challenge successfully');
      })
      .catch((error) => {
        addToast(`MFA challenge failed: ${error.message}`, 'error');
      });
  }

  const onError = (error) => {
    const userErrMsg = _.get(error, 'username.message', '');
    const pwErrMsg = _.get(error, 'password.message', '');
    setUsernameErrorMessage(userErrMsg);
    setPasswordErrorMessage(pwErrMsg);
  };
  return (
    <div className='login'>
      <BasicLoginHeader />
      <Form onSubmit={handleSubmit(onSubmit, onError)}>
        <section>
          <Form.Group controlId='formBasicEmail'>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter email'
              name='username'
              {...register('username')}
              className={hasError(errors, `username`) ? 'is-invalid shake' : ''}
            />
            <div className='invalid-feedback' style={{ display: 'block' }}>
              {usernameErrorMessage}
            </div>
          </Form.Group>
          <Form.Group controlId='formBasicPassword'>
            <Form.Label>Password</Form.Label>
            <SfPassword
              placeholder='Password'
              name='password'
              {...register('password')}
              className={'icon-muted ' + (hasError(errors, `password`) ? 'is-invalid shake' : '')}
            />
            {passwordErrorMessage && (
              <div className='sf-text-f-body3 sf-text-c-red mt-3' style={{ display: 'block' }}>
                {passwordErrorMessage}
              </div>
            )}
          </Form.Group>
        </section>
        <section>
          <div className='row my-6'>
            <div className='col-12 col-md-6'>
              <SfButton variant='primary' className='sf-btn-w-xlg' type='submit' disabled={disabled}>
                Log in
              </SfButton>
            </div>
            <div className='col-12 col-md-6 d-flex align-items-center'>
              <div>
                <LinkAzure />
                <LinkForgotPassword text='Forgot Password?' />
              </div>
            </div>
          </div>
        </section>
        <section>
          <LicenseTerms />
        </section>
      </Form>
    </div>
  );
};

const BasicLoginHeader = () => {
  const displayCompanyLogo = useSelector(selectDisplayCompanyLogo);
  const customerConfig = useSelector(selectCustomerConfig);
  if (displayCompanyLogo) {
    const customerName = _.get(customerConfig, 'customerName', '');
    const customerLogo = _.get(customerConfig, 'logo', '');
    return <div className='mb-6'>{customerLogo ? <img className='logo' alt='' src={customerLogo} /> : <h2>{customerName}</h2>}</div>;
  } else {
    return (
      <section>
        <i className='sf-icon-linksys-logo' />
      </section>
    );
  }
};

const LinkAzure = () => {
  const hasAzureProvider = useSelector(selectHasAzureProvider);
  const dispatch = useDispatch();
  if (hasAzureProvider) {
    return (
      <div>
        <a
          href='#'
          className='sf-text-primary sf-font-h5'
          onClick={(e) => {
            e.preventDefault();
            dispatch(doUpdateLoginPolicy('AZURE'));
          }}
        >
          Login via Oauth
        </a>
      </div>
    );
  }
  return <></>;
};
