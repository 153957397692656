import React from 'react';
import {CenterContent} from '../CenterContent';
import {SendEmailContent} from './ResetPasswordSendEmail';
import {useQuery} from '../_authHooks';
import {ResetPasswordForm} from './ResetPasswordForm';

export const ResetPasswordPage = () => {
  return (
    <CenterContent className='reset-password'>
      <h1>Reset Password</h1>
      <RenderContent />
    </CenterContent>
  );
};

function RenderContent() {
  const query = useQuery();
  const token = query.get('verificationToken');
  const email = query.get('username');
  if (!!token && !!email) {
    return <ResetPasswordForm />;
  } else {
    return <SendEmailContent />;
  }
}
