import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {envBaseApiUrl} from '../common/env';
import {getAxiosInstance as axios} from '../auth/axios/axiosCommon';
import _ from 'lodash';
import {createLogger, isEmpty, topicToRegex} from '../common/util';

const logger = createLogger('ui:iot:slice');
const trace = createLogger('ui:iot:slice:trace');

export const startGetCredentials = createAsyncThunk('iot/getCredentials', async (input, thunkAPI) => {
  const threadCounter = _.get(thunkAPI.getState(), 'iot.credentialsThreadCounter');
  if (threadCounter === 1) {
    const { orgId, networkId } = input;
    let url = `${envBaseApiUrl}/remote-operation-service/rest/credentials`;
    if (!isEmpty(orgId)) {
      url += `/organizations/${orgId}`;
    }
    if (!isEmpty(networkId)) {
      url += `/networks/${networkId}`;
    }
    return await axios()
      .post(url)
      .then((response) => {
        trace('get credentials response: %o', response);
        return response.data;
      })
      .catch((error) => {
        logger('get credentials failed: %o', error);
        throw error;
      });
  }
});

export const slice = createSlice({
  name: 'iot',
  initialState: {
    id: 1,
    credentials: undefined,
    topics: {},
    credentialsThreadCounter: 0
  },
  reducers: {
    updateIotStatus: (state, action) => {
      state.status = action.payload;
      return state;
    },
    subscribeTopic: (state, action) => {
      const { topic } = action.payload;
      state.topics[topic] = {
        messages: []
      };
    },
    unsubscribeTopic: (state, action) => {
      const { topic } = action.payload;
      delete state.topics[topic];
    },
    pushMessageToQueue: (state, action) => {
      if (action.payload) {
        const { topic } = action.payload;
        const nextId = state.id + 1;
        Object.keys(state?.topics)
          .filter((k) => topicToRegex(k).test(topic))
          .map((k) => state.topics[k])
          .forEach((it) => {
            // Give the message an ID so we can delete it by id.
            const data = Object.assign(action.payload, { id: nextId });
            const messages = it?.messages || [];
            messages.push(data);
            it.messages = messages;
          });
        state.id = nextId;
      }
      return state;
    },
    removeMessageFromQueue: (state, action) => {
      const { topic, id } = action.payload;
      const obj = state?.topics[topic];
      const messages = obj?.messages || [];
      obj.messages = messages.filter((it) => it.id !== id);
      return state;
    },
    removeMessagesFromQueue: (state, action) => {
      const { topic, ids } = action.payload;
      logger('Removing message from queue: ', action.payload);
      const obj = state?.topics[topic];
      const messages = obj?.messages || [];
      if (obj) {
        obj.messages = messages.filter((it) => !ids.includes(it.id));
      }
      return state;
    },
    clearIotData: (state, action) => {
      state.credentials = undefined;
      state.topics = {};
      state.messageQueue = [];
      state.status = undefined;
      return state;
    }
  },
  extraReducers: {
    [startGetCredentials.pending]: (state) => {
      state.credentialsThreadCounter++;
      trace('[startGetCredentials.pending]');
      return state;
    },
    [startGetCredentials.fulfilled]: (state, action) => {
      state.credentialsThreadCounter--;
      trace('[startGetCredentials.fulfilled]', state, action);
      if (action.payload) {
        state.credentials = action.payload;
      }
      return state;
    },
    [startGetCredentials.rejected]: (state) => {
      state.credentialsThreadCounter--;
      trace('[startGetCredentials.rejected]');
      return state;
    }
  }
});

export const {
  updateIotStatus,
  pushMessageToQueue,
  removeMessageFromQueue,
  removeMessagesFromQueue,
  subscribeTopic,
  unsubscribeTopic,
  clearIotData
} = slice.actions;

export const selectIotCredentials = (state) => _.get(state, 'iot.credentials');
export const selectIotStatus = (state) => _.get(state, 'iot.status');
export const selectIotTopicSubscribed = (topic) => (state) => _.get(state, `iot.topics[${topic}]`) !== undefined;
export const selectIotTopicMessage = (topic, filter) => (state) => {
  const messages = _.get(state, `iot.topics[${topic}.messages]`);
  if (typeof filter === 'function') {
    return _.filter(messages, filter);
  }
  return messages;
};
