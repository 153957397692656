import debug from 'debug';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {composeCommonApiOptions, composeCommonHeaders} from '../common/util';
import {envBaseApiUrl} from '../common/env';
import {getAxiosInstance as axios} from '../auth/axios/axiosCommon';

const logger = debug('sf:ui:nav:index');
const trace = debug('sf:ui:nav:index:trace');

export const startFetchUserIcon = createAsyncThunk('nav/fetchUserIcon', async (options, thunkAPI) => {
  const url = `${envBaseApiUrl}/user-service/rest/accounts/self/icon`;
  return axios()
    .get(url, {
      headers: composeCommonHeaders(composeCommonApiOptions(thunkAPI.getState()))
    })
    .then(function (response) {
      logger('Fetch User Icon response: ', response.data);
      return response.data;
    })
    .catch(function (error) {
      logger('Fetch User Icon failed: ', error);
      throw error;
    });
});

export const slice = createSlice({
  name: 'nav',
  initialState: {
    userIcon: {}
  },
  reducers: {},
  extraReducers: {
    [startFetchUserIcon.pending]: (state, action) => {
      trace('[startFetchUserIcon.pending]');
      return state;
    },
    [startFetchUserIcon.fulfilled]: (state, action) => {
      trace('[startFetchUserIcon.fulfilled]', state, action);
      state.userIcon = action.payload;
      return state;
    },
    [startFetchUserIcon.rejected]: (state, action) => {
      trace('[startFetchUserIcon.rejected]');
      return state;
    }
  }
});
