import React, { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useBlockLayout, useRowSelect, useSortBy, useTable } from 'react-table';
import { createLogger } from '../common/util';
import { useRemoteDataAdapter } from './RemoteDataAdapter';

const logger = createLogger('ui:RemoteTable');

/**
 * @callback RemoteTable~RowDecorator
 * @param row {object} The data object of the current row
 * @param onItemClickHandler Handler to be invoked when clicked.
 * @param commonClassName The className that we recommend to use in the decorator.
 * The implementation can choose not to respect it, so long as the implementor knows what he is doing.
 * @return {object} The returning object will be passwd to the table row as properties.
 * Eg: `<div {...rowDecorator(row, {onItemClickHandler})}>...</div>`
 * @constructor
 */
const DEFAULT_ROW_DECORATOR = (row, { onItemClickHandler, commonClassName }) => {
  return {
    className: onItemClickHandler != null ? `${commonClassName} clickable` : commonClassName
  }
}

/**
 * Load and display data in a table. Table pagination is handled at server side.
 *
 * @param className
 * @param scrollableTarget
 * @param columns
 * @param data
 * @param loader
 * @param pageCount
 * @param tableHeaderHeight
 * @param onChangeSelection
 * @param onChangeSort
 * @param needRestPage
 * @param selectedPath
 * @param selectedValues
 * @param onItemClickHandler
 * @param rowDecorator {RemoteTable~RowDecorator} Override this function to decorate a row. Function must return an object, the object
 * content will be passed to row elements as props.
 *
 * Default implementation adds "tr d-flex align-items-center" to className.
 * @return {JSX.Element}
 * @constructor
 */
export function RemoteTable({
  className,
  scrollableTarget,
  columns,
  data,
  loader,
  pageCount,
  tableHeaderHeight = 59,
  onChangeSelection,
  onChangeSort,
  needRestPage,
  selectedPath,
  selectedValues,
  onItemClickHandler,
  rowDecorator = DEFAULT_ROW_DECORATOR,
}) {
  // const initiallySelectedRows = React.useMemo(() => new Set(['1']), []);

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    totalColumnsWidth,
    selectedFlatRows,
    toggleRowSelected,
    state: { sortBy }
  } = useTable(
    {
      columns,
      data,
      manualSortBy: onChangeSort != null
    },
    useBlockLayout,
    useSortBy,
    useRowSelect
  );

  useEffect(() => {
    if (selectedPath != null && selectedValues != null) {
      rows.forEach((row) => {
        logger('row=%o', row);
        if (selectedValues.includes(row.original[selectedPath])) {
          toggleRowSelected(row.id, true);
        }
      });
    }
  }, [selectedPath, selectedValues, rows, toggleRowSelected]);

  React.useEffect(() => {
    if (onChangeSelection != null) {
      logger('selectedFlatRows', selectedFlatRows.length);
      // logger('in useEffect selectedFlatRows=%o', selectedFlatRows);
      onChangeSelection(selectedFlatRows?.map((row) => row.original));
    }
  }, [selectedFlatRows, onChangeSelection]);

  useEffect(() => {
    setPageCount(pageCount);
  }, [pageCount]);

  const { next, setPageCount, hasMore, resetPage } = useRemoteDataAdapter({ loader });

  // Call next to load the first page once.
  useEffect(async () => {
    await next();
  }, []);

  useEffect(() => {
    if (onChangeSort != null) {
      onChangeSort({ sortBy });
    }
  }, [sortBy, onChangeSort]);

  useEffect(async () => {
    if (needRestPage != null && needRestPage === true) {
      resetPage();
    }
  }, [needRestPage]);
  // Render the UI for your table

  const onRowClick = (row) => {
    if (onItemClickHandler != null) {
      if (row.original != null) {
        onItemClickHandler(row.original);
      } else {
        onItemClickHandler(row);
      }
    }
  };

  return (
    <InfiniteScroll
      className={className}
      dataLength={rows.length}
      next={next}
      hasMore={hasMore()}
      scrollableTarget={scrollableTarget}
      loader={<h4>Loading ...</h4>}
    >
      <div {...getTableProps()} className='table hover-table'>
        <div
          className='thead d-flex align-items-center sf-position-sticky'
          style={{ height: `${tableHeaderHeight / 16}rem`, width: totalColumnsWidth }}
        >
          {headerGroups.map((headerGroup, i) => (
            <div key={i} {...headerGroup.getHeaderGroupProps()} className='tr d-flex align-items-center'>
              {headerGroup.headers.map((column, j) => (
                <div key={j} {...column.getHeaderProps(column.getSortByToggleProps())} className='th pl-4 pr-4'>
                  {column.render('Header')}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <i className='sf-icon-chevron-down ml-2'/>
                      ) : (
                        <i className='sf-icon-chevron-up ml-2'/>
                      )
                    ) : (
                      ''
                    )}
                  </span>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div {...getTableBodyProps()} className='tbody'>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <div
                key={i}
                {...row.getRowProps()}
                onClick={() => onRowClick(row)}
                {...rowDecorator(row, { onItemClickHandler, commonClassName: "tr d-flex align-items-center" })}
              >
                {row.cells.map((cell, j) => {
                  return (
                    <div key={j} {...cell.getCellProps()} className='td pl-4 pr-4'>
                      {cell.render('Cell')}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </InfiniteScroll>
  );
}
