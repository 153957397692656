import debug from 'debug';
import {envBaseApiUrl} from '../common/env';

const logger = debug('sf:ui:oauth:index');

export const OAuthType = Object.freeze({
  SMB: 'SMB',
  AZURE: 'AZURE',
  GOOGLE: 'GOOGLE',
  OKTA: 'OKTA'
});

export const doOauthLogin = (oauthType, realmId) => {
  switch (oauthType) {
    case OAuthType.AZURE:
      window.location.replace(
        `${envBaseApiUrl}/authorization-service/oauth/providers/azure/authorize?X-SMB-Realm-Id=${realmId}&ui_redirect_uri=${window.location.origin}/auth/redirect`
      );
      break;
    default:
      logger("OAUTH type doesn't match: ", oauthType);
  }
};
