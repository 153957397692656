import {slice} from "./_topologySlice";

export {TopologyDataProvider} from "./TopologyDataProvider";
export {HomeTopology} from "./HomeTopology";
export {HomeUnprovisionedNodes} from "./HomeUnprovisionedNodes";
export {HomeBandwidthUsage} from "./HomeBandwidthUsage";
export {HomeBandwidthIoT} from "./HomeBandwidthIoT";
export {HomeSpeedTest} from "./HomeSpeedTest";
export {HomeSpeedTestIoT} from "./HomeSpeedTestIoT";
export {HomeSsidBoard} from "./HomeSsidBoard";
export {
  selectViewWanBandwidth,
  selectViewNetworkId,
  selectViewTopology,
  selectViewTopologyLoadingState,
  doClearViewHomeData,
  doSetViewNetworkId,
  doUpdateTopology,
  doUpdateVpnBandwidth,
  doUpdateWanBandwidth,
  startFetchTopology,
  startMonitorWanStats,
  startCancelMonitorWanStats
} from "./_topologySlice";

export const topologyReducer = slice.reducer;