import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {selectCustomerConfig} from '../_authSliceSelect';
import {useDispatch, useSelector} from 'react-redux';
import {useQuery} from '../_authHooks';
import {addToast} from '../../common/toast';
import {GrantType, loginSuccessfully, startLogin} from '../_authSlice';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {unwrapResult} from '@reduxjs/toolkit';
import {portal} from "../../common/env";

export const RedirectPage = () => {
  const customerConfig = useSelector(selectCustomerConfig);
  const dispatch = useDispatch();
  const history = useHistory();
  // Retrieve token from url
  const query = useQuery();
  const code = query.get('code');
  const state = query.get('state');

  useEffect(() => {
    if (!!code && !!state && customerConfig && customerConfig.realmId) {
      dispatch(
        startLogin({
          gt: GrantType.CODE,
          code: code,
          state: state
        })
      )
        .then(unwrapResult)
        .then(() => {
          loginSuccessfully({ dispatch, history, portal });
        })
        .catch((error) => {
          addToast(error.message, 'error');
          history.push('/auth/login');
        });
    }
  }, [code, state]);

  return (
    <div
      className='position-fixed d-flex align-items-center justify-content-center'
      style={{ zIndex: 1000, top: 0, bottom: 0, right: 0, left: 0 }}
    >
      <div>
        <div className='d-flex justify-content-center'>
          <FontAwesomeIcon icon={faSpinner} spin size='8x' />
        </div>
        <div>
          <h2 className='mt-4'>Redirecting…</h2>
        </div>
      </div>
    </div>
  );
};
