import React from "react";
import {getInitials, hashCode} from "../util/string";

/**
 * Generates a rounded avatar with the initials of text.
 * @param text
 * @param letters the maximal number of letters
 * @param size avatar size: '' or 'lg'
 * @param bgColor assign background-color to avatar. Will use specific background-color by hashcode.
 * @returns {JSX.Element}
 * @constructor
 */
export const Avatar = ({className, text, letters = 3, size = 'md', bgColor = ''}) => {
  const initials = getInitials(letters, text);
  const hash = hashCode(initials);
  const styles = {
  };
  if(className !== 'peopleAvatar') {
    Object.assign(styles, {
      backgroundColor: bgColor === '' ? renderBgColor(hash) : bgColor
    });
  }
  return (
    <div className={`avatar-circle-${size} ${className}`}
         style={styles}>
      <span className="initials">{initials}</span>
    </div>
  )
};

function renderBgColor(hash) {
  return backgroundColors[hash % backgroundColors.length];
}

/**
 * This array should be replaced by Figma token in the future.
 * @type {string[]}
 */
const backgroundColors = [
  '#557DE4',
  '#683BB2',
  '#A83BB2',
  '#B2503B',
  '#B26D3B',
  '#93B23B',
  '#3BB287',
];
