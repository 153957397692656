import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import _ from 'lodash';
import {useSelector} from 'react-redux';
import {selectCustomerConfig} from '../auth/_authSliceSelect';

export function Brand({ logo }) {
  const history = useHistory();
  const { width, height, maxHeight, maxWidth, src } = logo;
  const style = {
    width: width || 'auto',
    height: height || 'auto',
    maxWidth: maxWidth || 'auto',
    maxHeight: maxHeight || 'auto'
  };
  const [logoSrc, setLogoSrc] = useState(logo.src);
  const cc = useSelector(selectCustomerConfig);
  useEffect(() => {
    if (cc && logo?.src == null) {
      setLogoSrc(_.get(cc, 'logo', ''));
    }
  }, [cc, logo?.src]);
  return (
    <a
      href='#'
      onClick={(e) => {
        e.preventDefault();
        history.push('/');
      }}
    >
      <div className='top-brand d-flex align-items-center justify-content-center'>
        <img alt='' src={logoSrc} className='navbar-brand' style={style} />
      </div>
    </a>
  );
}
