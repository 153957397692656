import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import _ from 'lodash';
import {createLogger, pathMatching} from '../common/util';

const logger = createLogger('ui:menuItem');

export const MenuItem = ({ item }) => {
  const location = useLocation();
  const hidden = _.get(item, 'hidden', () => {
    return false;
  });
  const [dynamicCSS, setDynamicCSS] = useState('');
  const [linkCssClazz, setLinkCssClazz] = useState('');
  const [linkTarget, setLinkTarget] = useState('');
  const { pathname } = location;
  useEffect(() => {
    let itemClazz = '';
    let linkClazz = '';

    const disabled = pathMatching({
      matchType: item?.disabledPathMatch,
      paths: item?.disabledPaths,
      currentPath: pathname
    });
    const inactive = pathMatching({
      matchType: item?.inactivePathMatch,
      paths: item?.inactivePaths,
      currentPath: pathname
    });

    if (inactive || disabled) {
      // disabled must be inactive, so they both get inactive.
      itemClazz += 'inactive';

      if (disabled) {
        // link css is disabled
        linkClazz = 'link-disabled';
        setLinkTarget(pathname);
      }
    } else {
      itemClazz += pathMatching({
        matchType: item.matchPath,
        paths: [item.link],
        currentPath: pathname
      })
        ? 'active'
        : 'inactive';
      setLinkTarget(item.link);
    }

    setDynamicCSS(itemClazz);
    setLinkCssClazz(linkClazz);
    logger('CSS displayed by active: ', dynamicCSS);
  }, [pathname]);
  return hidden() ? null : (
    <Link to={linkTarget} className={`sf-link-primary-reversed ${linkCssClazz || ''}`}>
      <div className={`menu-item rounded sf-btn-h-lg d-flex align-items-center menu-item ${dynamicCSS}`}>
        <Icon item={item} />
        <span className='menu-title'>{item.title}</span>
      </div>
    </Link>
  );
};

const Icon = ({ item }) => {
  const type = _.get(item, 'icon.type');
  let icon = null;
  switch (type) {
    case 'js':
      icon = _.get(item, 'icon.src', null);
      break;
    case 'url':
      icon = <img alt='' src={_.get(item, 'icon.src', '')} />;
      break;
  }
  if (icon == null) {
    return <span className='none-icon' />;
  }
  return <span className='menu-icon'>{icon}</span>;
};
