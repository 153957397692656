import { getAxiosInstance as axios } from '../../auth/axios/axiosCommon';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createLogger, LoadingState, unwrapErrorResponse } from "../../common/util";
import { envBaseApiUrl } from "../../common/env";

const logger = createLogger('ui:device:nodeClientDetails:slice');

const initialState = {
  nodeClientsLoadingState: LoadingState.NONE,
  nodeClientDetails: {},
};

export const fetchNodeClientDetails = createAsyncThunk('clientDevice/fetchNodeClientDetails', async (args, { dispatch, getState }) => {
  const { clientId, networkId } = args;
  logger('[fetchNodeClientDetails] begin: %o', args);

  return axios().get(
    `${envBaseApiUrl}/network-delegation-service/rest/networks/${networkId}/clients/${clientId}/details`
  ).then((response) => {
    return response.data;
  })
    .catch((error) => {
      const errorResp = unwrapErrorResponse(error);
      throw unwrapErrorResponse(errorResp);
    });
});

export const editNameAndIcon = createAsyncThunk('clientDevice/editNameAndIcon', async (args, { dispatch, getState }) => {
  const { networkId, clientId, name, logoId } = args;
  logger('[editNameAndIcon] begin: %o', args);

  return axios().put(
    `${envBaseApiUrl}/network-delegation-service/rest/networks/${networkId}/clients/${clientId}`,
    { name, logoId }
  ).then((response) => {
    return response.data;
  }).catch((error) => {
      const errorResp = unwrapErrorResponse(error);
      throw unwrapErrorResponse(errorResp);
    });
});

export const nodeClientDetailsSlice = createSlice({
  name: 'nodeClientDetails',
  initialState,
  reducers: {
    getNodeClientDetails: (state) => {
      state.nodeClientsLoadingState = LoadingState.LOADING;
    },
    setNodeNameAndIcon: (state) => {
      state.nodeClientsLoadingState = LoadingState.LOADING;
    }
  },
  extraReducers: {
    [editNameAndIcon.pending]: (state, action) => {
      state.nodeClientsLoadingState = LoadingState.LOADING;
    },
    [editNameAndIcon.fulfilled]: (state, action) => {
      state.nodeClientsLoadingState = LoadingState.LOADED;
    },
    [editNameAndIcon.rejected]: (state, action) => {
      logger("editNameAndIcon.rejected: %o, %o", state, action);
      state.nodeClientsLoadingState = LoadingState.LOADED;
    },
    [fetchNodeClientDetails.pending]: (state, action) => {
      state.nodeClientsLoadingState = LoadingState.LOADING;
    },
    [fetchNodeClientDetails.fulfilled]: (state, action) => {
      state.nodeClientDetails = action?.payload;
      state.nodeClientsLoadingState = LoadingState.LOADED;
    },
    [fetchNodeClientDetails.rejected]: (state, action) => {
      logger("fetchNodeClientDetails.rejected: %o, %o", state, action);
      state.nodeClientsLoadingState = LoadingState.LOADED;
    }
  }
});

export const { getNodeClientDetails, setNodeNameAndIcon } = nodeClientDetailsSlice.actions;

export const selectNodeClientDetails = (state) => state.nodeClientDetails.nodeClientDetails;
export const selectNodeClientDetailsMacAddress = (state) => state.nodeClientDetails.nodeClientDetails?.macAddress;
export const selectNodeClientDetailsDeviceName = (state) => state.nodeClientDetails.nodeClientDetails?.deviceName;
export const selectNodeClientDetailsOnline = (state) => state.nodeClientDetails.nodeClientDetails?.online;
export const selectNodeClientDetailsName = (state) => state.nodeClientDetails.nodeClientDetails?.name;
export const selectNodeClientDetailsIpAddress = (state) => state.nodeClientDetails.nodeClientDetails?.ipAddress;
export const selectNodeClientDetailsBand = (state) => state.nodeClientDetails.nodeClientDetails?.band;
export const selectNodeClientDetailsBrand = (state) => state.nodeClientDetails.nodeClientDetails?.brand;
export const selectNodeClientDetailsModel = (state) => state.nodeClientDetails.nodeClientDetails?.model;
export const selectNodeClientDetailsOS = (state) => state.nodeClientDetails.nodeClientDetails?.os;
export const selectNodeClientDetailsDeviceType = (state) => state.nodeClientDetails.nodeClientDetails?.deviceType;

export const selectNodeClientDetailsStatus = (state) => {
  return {
    loading: state.nodeClientDetails.nodeClientsLoadingState === LoadingState.LOADING,
    isNodeClientDetailsEmpty: state?.nodeClientDetails?.nodeClientDetails?.length > 0
  };
};

export const nodeClientDetailsReducer = nodeClientDetailsSlice.reducer;
