import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Button, Modal} from "react-bootstrap";
import FwDownloadImage from "../../assets/fwUpdate/fw-downloading.png"
import {startFetchFwUpdateInfo} from "./_firmwareUpdateSlice";
import {unwrapResult} from "@reduxjs/toolkit";
import {createLogger, LoadingState} from "../common/util";
import {startSendCommandToDevice} from "../common/commonAsyncThunks";
import {addToast} from "../common/toast";
import {doUpdateNetworkDevicesLoadingState} from "../network/_networkSlice";

const logger = createLogger('ui:fwUpdate:fwUpdateDialog');

const STEP = Object.freeze({
  CONFIRM: 0,
  DOWNLOADING: 1
});

export function FirmwareUpdateDialog({networkId, downloadUrl, showing, setShowing}) {
  const dispatch = useDispatch();
  const [step, setStep] = useState(STEP.CONFIRM);

  const doOnShow = (evt) => {
    logger('doOnShow');
    setStep(STEP.CONFIRM);
  }

  const onClickHandler = () => {
    dispatch(startSendCommandToDevice({networkId, cmd: 'fwUpdate', data: {url: downloadUrl}}))
      .then(unwrapResult)
      .then(() => {
        setStep(STEP.DOWNLOADING);
      })
      .catch((e) => {
        addToast(e.message, 'error');
      });
  };

  return (
    <>
      <Modal className="modal-backdrop" animation={false} size="lg" centered backdrop="static"
             backdropClassName="sf-modal-backdrop-light"
             keyboard={false}
             show={showing} onHide={setShowing} onShow={doOnShow}>
        <Modal.Header closeButton/>
        <Modal.Body className="mx-auto">
          <div className='mt-n6'>
            {step === STEP.CONFIRM ?
              <ContentConfirm onClickHandler={onClickHandler}/>
              :
              <ContentDownload networkId={networkId} setShowing={setShowing}/>}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

const ContentConfirm = ({onClickHandler}) => {
  return (
    <div className='visible-animated d-flex flex-column justify-content-center align-items-center'>
      <div className='sf-font-h3'>
        Firmware updates take a few minutes, after which your nodes will restart.
      </div>
      <div className='sf-font-body1 sf-text-secondary mt-6'>
        Would you like to continue?
      </div>
      <div className='mt-6'>
        <Button variant='primary' className='sf-btn-w-xlg' type='button' onClick={onClickHandler}>
          Let's Go
        </Button>
      </div>
    </div>
  );
};

const ContentDownload = ({setShowing, networkId}) => {
  const [dots, setDots] = useState(1);
  const dispatch = useDispatch();
  useEffect(() => {
    const intervalId = setInterval(() => {
      setDots((p) => {
        return p < 3 ? p + 1 : 1;
      });
    }, 1000)

    const timer = setTimeout(() => {
      setShowing(false);
      dispatch(doUpdateNetworkDevicesLoadingState(LoadingState.NONE))
      dispatch(startFetchFwUpdateInfo({networkId}));
    }, 480 * 1000);

    return () => {
      clearInterval(intervalId);
      clearTimeout(timer);
    };

  }, [])
  return (
    <div className='visible-animated d-flex flex-column justify-content-center align-items-center'>
      <div className='sf-font-h3'>
        Downloading and installing new firmware{generateDots(dots)}
      </div>
      <div className='mt-6 d-flex justify-content-center'>
        <img src={FwDownloadImage} alt=''/>
      </div>
    </div>
  );
};

function generateDots(length) {
  let s = '';
  for (let i = 0; i < length; i++) {
    s += ".";
  }
  return s;
}