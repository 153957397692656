import {createLogger} from "../../common/util";

let _axios = {};
const logger = createLogger('ui:axios-common');
/**
 * Sub portals have to assign axios instance to sf-ui-component to make Interceptor affects to all api calls.
 * @param instance
 */
export function setAxiosInstance(instance) {
  logger('Set axios instance');
  _axios = instance;
}

export function getAxiosInstance() {
  return _axios;
}
