import {createAsyncThunk} from "@reduxjs/toolkit";
import {envBaseApiUrl} from "./env";
import {getAxiosInstance as axios} from "../auth/axios/axiosCommon";
import {composeUrl, createLogger, unwrapErrorResponse} from "./util";

const logger = createLogger('ui:topology:commonAsyncThunks');

export const startSendCommandToDevice = createAsyncThunk(
  'topology/sendCommandToDevice',
  async (input) => {
    logger('[MonitorWanStats] begin: %o', input);
    const {networkId, cmd, deviceId = 'primary', data} = input;
    const url = `${envBaseApiUrl}/network-delegation-service/rest/networks/${networkId}/devices/${deviceId}/command`;
    return axios().post(url, {
      cmd: cmd,
      data: data
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw unwrapErrorResponse(error);
      });
  }
);

export const startDeleteNetwork = createAsyncThunk(
  'topology/startDeleteNetwork',
  async (input, thunkAPI) => {
    logger('[startDeleteNetwork] begin: %o', input);
    const {networkId} = input;
    const url = `${envBaseApiUrl}/network-service/rest/networks/${networkId}`;

    return axios().delete(url).then((response) => {
      logger('delete network response: %o', response.data);
      return response.data;
    }).catch((error) => {
      logger('delete network failed: %o', error);
      throw unwrapErrorResponse(error);
    });
  }
);

export const startFetchLatestSpeedTest = createAsyncThunk(
  'speeds/fetchLatestSpeedTest',
  async (input) => {
    logger('[FetchLatestSpeedTest] begin: %o', input);
    const {networkId} = input;
    const url = composeUrl(`${envBaseApiUrl}/network-service/rest/networks/${networkId}/speed-test/latest`, {});
    return axios().get(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        const errorResp = unwrapErrorResponse(error);
        throw errorResp;
      });
  }
);
