import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { startFetchCustomerConfigs, startGetProfile } from './_authSlice';
import {
  selectCustomerConfig,
  selectCustomerConfigLoadingState,
  selectProfile,
  selectProfileLoadingState,
  selectToken
} from './_authSliceSelect';
import { useHistory, useLocation } from 'react-router-dom';
import debug from 'debug';
import { LoadingState } from '../common/util';

const logger = debug('sf:auth:hooks');
const trace = debug('sf:auth:hooks:trace');

export function useCustomerConfig() {
  const cc = useSelector(selectCustomerConfig);
  const loading = useSelector(selectCustomerConfigLoadingState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (loading === LoadingState.NONE) {
      dispatch(
        startFetchCustomerConfigs({
          domain: window.location.href,
          dispatch
        })
      );
    }
  }, [loading]);
  return cc;
}

/**
 * Make sure the user already has the token before displaying a component.
 */
export const useToken = () => {
  const token = useSelector(selectToken);
  const history = useHistory();

  useEffect(() => {
    trace(`[useToken.effect] begin token:`, token);
    if (!token) {
      logger('[useToken] token not found, redirecting back to `/auth/login`');
      history.push('/auth/login');
    }
    trace(`[useToken.effect] end.`);
  });
  return token;
};

/**
 * If profile is empty, load it from server.
 */
export function useProfile() {
  const profile = useSelector(selectProfile);
  const loading = useSelector(selectProfileLoadingState);
  const token = useSelector(selectToken);
  const customerConfig = useSelector(selectCustomerConfig);
  const dispatch = useDispatch();

  useEffect(() => {
    if (loading === LoadingState.NONE && token && customerConfig) {
      trace('[useProfile.useEffect] : ', profile, loading, token, customerConfig);
      dispatch(startGetProfile());
    }
  }, [dispatch, loading, token, customerConfig]);
  return profile;
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}
