import { useCallback, useState } from "react";
import { selectNetworkPreference, startFetchNetworkPreference, startUpdateNetworkPreference } from "./_networkPreferenceSlice";
import { startFetchTimezones } from "../cloud/_cloudUtilitySlice";
import { createLogger } from "../common/util";
import { useDispatch, useSelector } from "react-redux";

const logger = createLogger("network:networkPreferenceHooks");

export function useNetworkPreference({ networkId }) {
  const dispatch = useDispatch();
  const preferences = useSelector(selectNetworkPreference);

  const reload = useCallback(() => {
    if (networkId != null) {
      dispatch(startFetchNetworkPreference({ networkId }));
    }
  }, [dispatch, networkId]);

  const prepareTimezone = useCallback(() => {
    if (networkId != null) {
      dispatch(startFetchTimezones());
    }
  }, [networkId]);

  const submitTimezone = useCallback(async (formData) => {
    logger("Submit timezone", formData);
    const combined = Object.assign({}, preferences, formData);
    logger("Update network preference", combined);

    return await dispatch(startUpdateNetworkPreference({ networkId, data: combined }));
  }, [preferences]);

  return {
    preferences,
    reload,
    prepareTimezone,
    submitTimezone,
  }
}