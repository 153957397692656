import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {getAxiosInstance as axios} from '../auth/axios/axiosCommon';
import debug from 'debug';
import _ from "lodash";
import { envBaseApiUrl } from "../common/env";
import { composeUrl, isEmpty, LoadingState, unwrapErrorResponse } from "../common/util";

const logger = debug('sfc:security:slice');


export const startFetchFortinetAntiVirus = createAsyncThunk('security/fetchFortinetAntiVirus', async (options, thunkAPI) => {
  const { orgId, ...rest } = options;

  const url = composeUrl(`${envBaseApiUrl}/fortinet-service/rest/organizations/${orgId}/fortinet-security/ANTIVIRUS`, rest);
  return axios()
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw unwrapErrorResponse(error);
    });
});

export const startEditFortinetAntiVirus = createAsyncThunk('security/fetchFortinetAntiVirus', async (options, thunkAPI) => {
  const { orgId, enabled, version, ...rest } = options;

  const url = composeUrl(`${envBaseApiUrl}/fortinet-service/rest/organizations/${orgId}/fortinet-security/ANTIVIRUS`, rest);
  return axios()
    .patch(url, {
      version: version,
      data: {
        enabled: enabled
      }
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw unwrapErrorResponse(error);
    });
});

export const startGetDefaultCategories = createAsyncThunk(
  'security/getDefaultCategories',
  async (input, thunkAPI) => {
    logger('[getDefaultCategories] begin:');

    const url = `${envBaseApiUrl}/asset-service/rest/organizations/webcontentfiltercategories`;

    return axios().get(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw unwrapErrorResponse(error);
      })
  }
);

export const startGetWebFilter = createAsyncThunk(
  'security/getWebFilter',
  async (input, thunkAPI) => {
    logger('[getWebFilter] begin: %o', input);
    const { customerId, ...rest } = input;
    const url = composeUrl(`${envBaseApiUrl}/fortinet-service/rest/organizations/${customerId}/fortinet-security/WEBFILTER`, rest);

    return axios().get(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw unwrapErrorResponse(error);
      })
  }
);

export const startEnabledWebFilter = createAsyncThunk(
  'security/enabledCategoryFilter',
  async (input, thunkAPI) => {
    logger('[enabledCategoryFilter] begin: %o', input);
    const { customerId, enabled, version, ...rest } = input;
    const url = composeUrl(`${envBaseApiUrl}/fortinet-service/rest/organizations/${customerId}/fortinet-security/WEBFILTER/enable`, rest);

    return axios().patch(url, {
      version: version,
      data: {
        enabled: enabled
      }
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw unwrapErrorResponse(error);
      })
  }
);

export const startPatchCategoryFilter = createAsyncThunk(
  'security/patchCategoryFilter',
  async (input, thunkAPI) => {
    logger('[patchCategoryFilter] begin: %o', input);
    const { customerId, domain, networkId, ...rest } = input;
    const url = composeUrl(`${envBaseApiUrl}/fortinet-service/rest/organizations/${customerId}/fortinet-security/WEBFILTER/category`, {domain, networkId});

    return axios().patch(url, rest)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw unwrapErrorResponse(error);
      })
  }
);

export const startPatchURLFilter = createAsyncThunk(
  'security/enabledURLFilter',
  async (input, thunkAPI) => {
    logger('[enabledURLFilter] begin: %o', input);
    const { customerId, domain, networkId, ...rest } = input;
    const url = composeUrl(`${envBaseApiUrl}/fortinet-service/rest/organizations/${customerId}/fortinet-security/WEBFILTER/url`, {domain, networkId});

    return axios().patch(url, rest)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw unwrapErrorResponse(error);
      })
  }
);

export const startFetchSecurityOverview = createAsyncThunk(
  'security/FetchSecurityOverview',
  async ({ customerId, domain, networkId }, { rejectWithValue, getState, dispatch }) => {
    logger('[FetchSecurityOverview] begin: ', customerId, domain, networkId);
    const url = composeUrl(`${envBaseApiUrl}/fortinet-service/rest/organizations/${customerId}/fortinet-security`, {
      domain, networkId
    });

    return axios().get(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw unwrapErrorResponse(error);
      });
  }
);

export const securitySlice = createSlice({
  name: 'security',
  initialState: {
    antiVirus: {},
    webFilter: {
      source: {
        categories: undefined,
        categoryFilters: undefined,
        urlFilters: undefined,
      }
    },
  },
  reducers: {
    updateAntivirusLoadingState: (state, action) => {
      state.antiVirus.loadingState = action.payload;
    },
    doInitCategoryData: (state, action) => {
      logger("Init category data: %o", action?.payload);
      state.webFilter.source.categories = action?.payload?.categories;
      state.webFilter.source.categoryFilters = action?.payload?.categoryFilters;

      // trigger post process
      return securitySlice.caseReducers.doPostProcessCategoryData(state, {});
    },
    doInitUrlData: (state, action) => {
      if (state.webFilter.url == null) {
        state.webFilter.url = {};
      }
      state.webFilter.url.data = action?.payload;
    },
    /**
     * After loading category and filter data from remote, process them so the UI can use them.
     *
     * @param state
     * @param action
     */
    doPostProcessCategoryData: (state, action) => {
      const {categories, categoryFilters} = state.webFilter.source;
      if (categories == null || categoryFilters == null) {
        logger("category data not ready, categories: {}, categoryFilters: {}", categories, categoryFilters);
        return state;
      }
      logger("Post process category: %o, %o", categories, categoryFilters);
      // reset category
      state.webFilter.category = {
        data: {},
        flatData: {},
        modifiedData: {}
      };

      // Process category into flat and grouped
      categories.forEach((c) => {
        if (c.id != null) {
          const category = Object.assign({}, c);
          state.webFilter.category.flatData[category.id] = category;

          if (category.group != null && category.group.length > 0) {
            if (category.active === true) {
              if (state.webFilter.category.data[category.group] == null) {
                state.webFilter.category.data[category.group] = {};
              }
              state.webFilter.category.data[category.group][category.id] = category;
              state.webFilter.category.data[category.group][category.id].action = "allow";
            }
          }
        }
      });

      if (categoryFilters && categoryFilters.length > 0) {
        categoryFilters.forEach(category => {

          // state.webFilter.category.flatData[category.id].action = category.action;
          const categoryInfo = state.webFilter.category.flatData[category.id];

          if (categoryInfo != null && state.webFilter.category.data[categoryInfo.group] != null) {
            state.webFilter.category.modifiedData[category.id] = category;
            state.webFilter.category.data[categoryInfo.group][category.id].action = category.action;
          }
        })
      }
      logger("Category data processed: %o", state.webFilter.category);
      state.webFilter.source = {};
      return state;
    },
    resetCategoryFilter: (state, action) => {
      state.webFilter.category.modifiedData = {};

      Object.keys(state.webFilter.category.flatData).forEach(id => {
        state.webFilter.category.flatData[id].action = "allow";
      });

      Object.keys(state.webFilter.category.data).forEach(type => {
        Object.keys(state.webFilter.category.data[type]).forEach(id => {
          state.webFilter.category.data[type][id].action = "allow";
        })
      });
    },
    updateCategoryFilter: (state, action) => {
      logger('updateCategoryFilter action=%o', action);
      if (action.payload != null && action.payload.action != null && action.payload.id != null) {

        if (!isEmpty(state.webFilter.category.modifiedData)) {
          const modifiedCategory = state.webFilter.category.modifiedData[action.payload.id];
          if (modifiedCategory != null) {
            if (action.payload.action === 'allow') { // not need to save action='allow'
              delete state.webFilter.category.modifiedData[action.payload.id];
            } else {
              state.webFilter.category.modifiedData[action.payload.id] = action.payload;
            }
          } else {
            state.webFilter.category.modifiedData[action.payload.id] = action.payload;
          }
        } else {
          state.webFilter.category.modifiedData[action.payload.id] = action.payload;
        }

        const categoryInfo = state.webFilter.category.flatData[action.payload.id];
        if (categoryInfo != null && state.webFilter.category.data[categoryInfo.group] != null) {
          state.webFilter.category.data[categoryInfo.group][categoryInfo.id].action = action.payload.action;
        }
      }
    },
    addURLFilter: (state, action) => {
      logger('addURLFilter action=%o', action);
      if (action.payload != null && action.payload.url != null && action.payload.action != null) {
        if (state.webFilter.url.data != null) {
          const index = state.webFilter.url.data.findIndex(x => x.url === action.payload.url);
          if (index < 0) {
            state.webFilter.url.data.push({
              url: action.payload.url,
              action: action.payload.action,
              type: "simple",
              status: "enable"
            });
          } else {
            state.webFilter.url.data[index].action = action.payload.action;
          }
        } else {
          state.webFilter.url.data = [];
          state.webFilter.url.data.push({
            url: action.payload.url,
            action: action.payload.action,
            type: "simple",
            status: "enable"
          });
        }
      }
    },
    patchURLFilterAction: (state, action) => {
      logger('patchURLFilterAction action=%o', action);
      if (action.payload != null && action.payload.url != null && action.payload.action != null) {
        if (state.webFilter.url.data != null && state.webFilter.url.data.length > 0) {
          const index = state.webFilter.url.data.findIndex(x => x.url === action.payload.url);
          if (index >= 0) {
            state.webFilter.url.data[index].action = action.payload.action;
          }
        }
      }
    },
    deleteURLFilter: (state, action) => {
      logger('deleteURLFilter action=%o', action);
      if (action.payload && action.payload.url != null &&
          state.webFilter.url.data != null && state.webFilter.url.data.length > 0) {
        const index = state.webFilter.url.data.findIndex(item => item.url === action.payload.url);
        if (index >= 0) {
          state.webFilter.url.data.splice(index, 1);
        }
      }
    },
    changeURLFilterURL: (state, action) => {
      logger('changeURLFilterURL action=%o', action);

      if (action.payload && action.payload.url != null && action.payload.oldUrl != null) {
        const index = state.webFilter.url.data.findIndex(item => item.url === action.payload.oldUrl);
        if (index >= 0) {
          state.webFilter.url.data[index].url = action.payload.url;
        }
      }
    }
  },
  extraReducers: {
    [startFetchFortinetAntiVirus().pending]: (state, action) => {
      logger("startFetchFortinetAntiVirus.pending: %o, %o", state, action);
      state.antiVirus.loadingState = LoadingState.LOADING;
    },
    [startFetchFortinetAntiVirus.fulfilled]: (state, action) => {
      logger("startFetchFortinetAntiVirus.fulfilled: %o, %o", state, action);
      state.antiVirus.data = action.payload;
      state.antiVirus.loadingState = LoadingState.LOADED;
    },
    [startFetchFortinetAntiVirus.rejected]: (state, action) => {
      logger("startFetchFortinetAntiVirus.rejected: %o, %o", state, action);
      state.antiVirus.loadingState = LoadingState.LOADED;
    },
    [startGetDefaultCategories().pending]: (state, action) => {
      logger("startGetDefaultCategories.pending: %o, %o", state, action);
    },
    /**
     * Keep categories data to source.categories
     */
    [startGetDefaultCategories.fulfilled]: (state, action) => {
      logger("startGetDefaultCategories.fulfilled: %o, %o", state, action);

      state.webFilter.source.categories = action.payload;
      // trigger post process.
      return securitySlice.caseReducers.doPostProcessCategoryData(state, {});
    },
    [startGetDefaultCategories.rejected]: (state, action) => {
      logger("startGetDefaultCategories.rejected: %o, %o", state, action);
    },
    [startGetWebFilter.pending]: (state, action) => {
      logger("startGetWebFilter.pending: %o, %o", state, action);
    },
    /**
     * Keep web filter data to source.categoryFilters and source.urlFilters
     */
    [startGetWebFilter.fulfilled]: (state, action) => {
      logger("startGetWebFilter.fulfilled: %o, %o", state, action);

      state.webFilter.version = action.payload.version;
      if (action.payload.data != null) {

        state.webFilter.enabled = action.payload.data.enabled;
        state.webFilter.source.categoryFilters = action.payload.data.categoryFilters;
        state.webFilter.source.urlFilters = action.payload.data.urlFilters;

        // trigger category post process
        state = securitySlice.caseReducers.doPostProcessCategoryData(state, {});

        if (state.webFilter.url == null) {
          state.webFilter.url = {};
        }

        // reset url filter
        state.webFilter.url.data = [];

        if (action.payload.data.urlFilters != null) {

          if (action.payload.data.urlFilters && action.payload.data.urlFilters.length > 0) {
            const tempUrlFilters = action.payload.data.urlFilters.sort((a, b) => {
              const aHost = a.url != null ? a.url : '';
              const bHost = b.url != null ? b.url : '';
              return (aHost > bHost) ? 1 : -1
            })

            state.webFilter.url.data = tempUrlFilters;
          }
        }
      }
    },
    [startGetWebFilter.rejected]: (state, action) => {
      logger("startGetWebFilter.rejected: %o, %o", state, action);
    },
    [startEnabledWebFilter.pending]: (state, action) => {
      logger("startEnabledWebFilter.pending: %o, %o", state, action);
    },
    [startEnabledWebFilter.fulfilled]: (state, action) => {
      logger("startEnabledWebFilter.fulfilled: %o, %o", state, action);
      state.webFilter.enabled = action.meta.arg.enabled;
      state.webFilter.version = action.payload.version;
    },
    [startEnabledWebFilter.rejected]: (state, action) => {
      logger("startEnabledWebFilter.rejected: %o, %o", state, action);
    },
    [startPatchCategoryFilter.pending]: (state, action) => {
      logger("startPatchCategoryFilter.pending: %o, %o", state, action);
    },
    [startPatchCategoryFilter.fulfilled]: (state, action) => {
      logger("startPatchCategoryFilter.fulfilled: %o, %o", state, action);
      state.webFilter.version = action.payload.version;
    },
    [startPatchCategoryFilter.rejected]: (state, action) => {
      logger("startPatchCategoryFilter.rejected: %o, %o", state, action);
    },
    [startPatchURLFilter.pending]: (state, action) => {
      logger("startPatchURLFilter.pending: %o, %o", state, action);
    },
    [startPatchURLFilter.fulfilled]: (state, action) => {
      logger("startPatchURLFilter.fulfilled: %o, %o", state, action);
      state.webFilter.version = action.payload.version;
    },
    [startPatchURLFilter.rejected]: (state, action) => {
      logger("startPatchURLFilter.rejected: %o, %o", state, action);
    },
    [startFetchSecurityOverview.pending]: (state, action) => {
      logger("startFetchSecurityOverview.pending: %o, %o", state, action);
    },
    [startFetchSecurityOverview.fulfilled]: (state, action) => {
      logger("startFetchSecurityOverview.fulfilled: %o, %o", state, action);
      state.overview = (action?.payload || []).reduce((acc, cur) => {
        acc[cur?.securityModule] = cur.enabled;
        return acc;
      }, {});
    },
    [startFetchSecurityOverview.rejected]: (state, action) => {
      logger("startFetchSecurityOverview.rejected: %o, %o", state, action);
    },
  }
});

export const { updateAntivirusLoadingState, doInitCategoryData, resetCategoryFilter, updateCategoryFilter,
  doInitUrlData, addURLFilter, patchURLFilterAction, deleteURLFilter, changeURLFilterURL } = securitySlice.actions;

export const selectAntiVirusLoadingState = state => _.get(state, 'security.antiVirus.loadingState', LoadingState.NONE);
export const selectAntiVirusData = state => _.get(state, 'security.antiVirus.data');
export const selectAntiVirusVersion = state => _.get(state, 'security.antiVirus.data.version', 1);
export const selectWebFilterVersion = state => _.get(state, 'security.webFilter.version', 1);
export const selectCategoryFilterData = state => _.get(state, 'security.webFilter.category.data');
export const selectCategoryFilterSetData = state =>  _.get(state, 'security.webFilter.category.modifiedData');
export const selectWebFilterEnabled = state => _.get(state, 'security.webFilter.enabled');
export const selectURLFilterData = state => _.get(state, 'security.webFilter.url.data');
export const selectSecurityOverview = state => state?.security?.overview;

export default securitySlice.reducer;
