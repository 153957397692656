import React, { useEffect, useState } from "react";

let counter = 1;
const dummy = () => {
  return {};
};

/**
 * A checkbox component
 *
 * @param className
 * @param children Used as the label text, will be wrapped within `<label></label>`
 * @param form The outer form
 * @param path The path in the form where the value is validated.
 * @param rest Other parameters
 * @return {JSX.Element}
 * @constructor
 */
export function SfCheckbox({ className, children, form, path, ...rest }) {
  const [id, setId] = useState(counter);
  const { register } = (form != null) ? form : {
    register: dummy
  };
  useEffect(() => {
    counter = counter + 1;
    setId(counter);
  }, []);

  return (
    <div className={`sf-form-check d-flex align-items-center mb-2 ${className || ''}`}>
      <input type="checkbox" id={`sfcheckbox_${id}`} className="sf-form-check-input"
             {...register(path)}
             {...rest}
      />
      <label className="sf-form-check-label mb-0" htmlFor={`sfcheckbox_${id}`}>{children}</label>
    </div>
  );
}