export const envBaseApiUrl = process.env.REACT_APP_SF_API_URL;

export const envClientTypeId = process.env.REACT_APP_SF_CLIENT_TYPE_ID;
export const envClientTypeSecret = process.env.REACT_APP_SF_CLIENT_TYPE_SECRET;
export const envGoogleMapApiKey = process.env.REACT_APP_SF_GOOGLE_MAP_API_KEY;
export const envDomainOverride = process.env.REACT_APP_SF_DOMAIN_OVERRIDE;
export const envAwsRegion = process.env.REACT_APP_SF_AWS_REGION;
export const envAwsIotHostUrl = process.env.REACT_APP_SF_AWS_IOT_HOST_URL;

export function domainOrOverride(input) {
  if (envDomainOverride != null) {
    return envDomainOverride;
  }
  return input;
}

export const envCmvUiUrl = process.env.REACT_APP_SF_CMV_UI_URL;
export const portal = process.env.REACT_APP_SF_PORTAL;
