import {useLayoutEffect} from "react";
import {useDispatch} from "react-redux";
import {createLogger} from "../common/util";
import {
  doClearViewHomeData,
  doSetViewNetworkId,
  startCancelMonitorWanStats,
  startFetchTopology,
  startMonitorWanStats
} from "./_topologySlice";
import {startFetchNetwork, startFetchNetworkClientsCount, startFetchNetworkDevices} from "../network/_networkSlice";

const logger = createLogger('ui:topology:dataProvider')

export const TopologyDataProvider = ({children, networkId}) => {
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    let interval;
    if (networkId != null) {
      dispatch(doSetViewNetworkId(networkId));
      dispatch(startFetchNetwork({
        networkId: networkId,
        fields: 'Stat,GROUP'
      }))
        .then(dispatch(startFetchNetworkDevices({
          networkId: networkId
        })))
        .then(dispatch(startFetchTopology({
          networkId: networkId
        })))
        .then(dispatch(startFetchNetworkClientsCount({
          networkId: networkId
        })))
        .then(dispatch(startMonitorWanStats({networkId})))
        .then(() => {
          interval = setInterval(() => {
            dispatch(startMonitorWanStats({networkId}));
          }, 50000);
        })
        .catch((e) => {
            logger('Fetch view data error', e);
          }
        );
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
      if (networkId != null) {
        dispatch(startCancelMonitorWanStats({networkId}));
      }
      dispatch(doClearViewHomeData());
    }

  }, [networkId]);

  return (children);
};
