import React, { useEffect, useState } from "react";
import { createLogger } from "../common/util";
import { SfCheckbox } from "./SfCheckbox";

const logger = createLogger("form:SfTimezoneView");

export function SfTimezoneView({className, data, selected, toggle, title = "Time Zone", readonly = false}) {
  const [currentTimezone, setCurrentTimezone] = useState();

  useEffect(() => {
    logger("Find timezone: %o, %s", data, selected?.timezoneId);
    const found = (data || []).find(it => it.timezoneId === selected?.timezoneId);
    setCurrentTimezone(found);
  }, [data, selected?.timezoneId]);

  return (
    <>
      <h3 className="mb-4">{title}</h3>
      <div className="d-flex justify-content-between mb-4">
        <span className="sf-font-body1">{currentTimezone?.description}</span>
        {!readonly && <a className="sf-font-body1 cursor-pointer" onClick={toggle}>Edit</a>}
      </div>
      <SfCheckbox disabled checked={selected?.autoAdjustDst}>Automatically adjust for daylight saving time</SfCheckbox>
    </>
  );
}