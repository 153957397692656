import React from "react";
import {SpeedTrendsCard} from "./SpeedTrendsCard";
import {useSelector} from "react-redux";
import {selectSpeedTestStats} from "./_speedsSlice";

export const SpeedTrends = () => {
  const speedTestStats = useSelector(selectSpeedTestStats);
  return (
    <div>
      <div className='d-flex align-items-center'>
        <h3>
          Speed Trends
        </h3>
        <div className='ml-5 sf-font-body1 sf-text-secondary'>
          Last 30 Days
        </div>
      </div>
      <div className='mt-5'>
        <div className='row'>
          <div className='col-md-12 col-lg-4'>
            <SpeedTrendsCard speed='Fastest' dateTime={speedTestStats?.best?.dateTime} upload={speedTestStats?.best?.upload}
                             download={speedTestStats?.best?.download} latency={speedTestStats?.best?.latency}/>
          </div>
          <div className='col-md-12 col-lg-4'>
            <SpeedTrendsCard speed='Slowest' dateTime={speedTestStats?.worst?.dateTime} upload={speedTestStats?.worst?.upload}
                             download={speedTestStats?.worst?.download} latency={speedTestStats?.worst?.latency}/>
          </div>
          <div className='col-md-12 col-lg-4'>
            <SpeedTrendsCard speed='Average' upload={speedTestStats?.avg?.upload}
                             download={speedTestStats?.avg?.download} latency={speedTestStats?.avg?.latency}/>
          </div>
        </div>
      </div>
    </div>
  );
}
