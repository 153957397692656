import React, {useEffect, useState} from 'react';
import {CenterContent} from '../CenterContent';
import {useCustomerConfig, useQuery} from '../_authHooks';
import {useHistory} from 'react-router-dom';
import {addToast} from '../../common/toast';
import {useDispatch, useSelector} from 'react-redux';
import {useForm, useWatch} from 'react-hook-form';
import * as s from 'superstruct';
import {size, string} from 'superstruct';
import {superstructResolver} from '@hookform/resolvers/superstruct';
import {startCreatePassword} from '../_authSlice';
import {unwrapResult} from '@reduxjs/toolkit';
import _ from 'lodash';
import {createLogger} from '../../common/util';
import {Button, Form} from 'react-bootstrap';
import {hasError} from '../../common/validation';
import {PasswordSecurityRequirements} from './PasswordSecurityRequirements';
import {selectIsPasswordMatchRequirements} from '../_authSliceSelect';
import {SfPassword} from "../../form/SfPassword";

const logger = createLogger('ui:createPassword');

export const CreatePasswordPage = () => {
  const query = useQuery();
  const token = query.get('token');
  const email = query.get('email');
  const history = useHistory();
  useEffect(() => {
    if (!token || !email) {
      history.push('/auth/login');
      addToast('Token or Email is missing', 'error');
    }
  }, [token, email]);
  return (
    <CenterContent>
      <CreatePasswordForm token={token} email={email} />
    </CenterContent>
  );
};

const CreatePasswordForm = ({ token, email }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const customerConfig = useCustomerConfig();
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); // Used to display API error message.
  const schema = s.object({
    newPassword: size(string(), 10, 60),
  });
  const isValid = useSelector(selectIsPasswordMatchRequirements);
  const onSubmit = (data) => {
    setErrorMessage('');
    setPasswordErrorMessage('');
    if (!customerConfig || !customerConfig.realmId) {
      addToast('customer config not ready', 'error');
      return;
    }
    if (!isValid) {
      setError('newPassword', {
        type: 'manual',
        message: 'Password format is invalid'
      });
      return;
    }
    dispatch(
      startCreatePassword({
        token: token,
        email: email,
        password: data.newPassword
      })
    )
      .then(unwrapResult)
      .then((result) => {
        logger(result);
        addToast('Your password has been created', 'success');
        history.push('/auth/login');
      })
      .catch((error) => {
        addToast(error.message, 'error');
      });
  };

  const onError = (error) => {
    const pwErrMsg = _.get(error, 'newPassword.message', '');
    setPasswordErrorMessage(pwErrMsg);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    control
  } = useForm({
    resolver: superstructResolver(schema, {
      coerce: true
    })
  });
  const watchPassword = useWatch({
    name: 'newPassword',
    control
  });
  return (
    <div className='create-password'>
      <section>
        <h2>Create your new password to activate your account:</h2>
      </section>
      <section className='mt-6'>
        <Form onSubmit={handleSubmit(onSubmit, onError)}>
          <Form.Group controlId='formBasicPassword'>
            <Form.Label>Password</Form.Label>
            <SfPassword
              placeholder='Enter Password'
              name='newPassword'
              className={'icon-muted ' + (hasError(errors, `newPassword`) ? 'is-invalid shake' : '')}
              {...register('newPassword')}
            />
            <div className='invalid-feedback' style={{ display: 'block' }}>
              {passwordErrorMessage}
            </div>
          </Form.Group>
          <PasswordSecurityRequirements className='mt-6' value={watchPassword} />
          <div className='row mt-6'>
            <div className='col-12'>
              <Button variant='primary' className='sf-btn-w-xlg' type='submit'>
                Activate Account
              </Button>
            </div>
          </div>
        </Form>
      </section>
    </div>
  );
};
