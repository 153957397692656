import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {getAxiosInstance as axios} from '../auth/axios/axiosCommon';
import { composeUrl, createLogger, LoadingState, unwrapErrorResponse } from "../common/util";
import { envBaseApiUrl } from "../common/env";

const logger = createLogger('ui:devices:slice');

export const startFetchDevices = createAsyncThunk(
  'devices/startFetchDevices',
  async (input) => {
    logger('[FetchDevices] begin: %o', input);

    const { networkId, portal, deviceId } = input;
    const apiPortal = portal === 'employee' ? 'emp' : 'corp';
    const url = composeUrl(`${envBaseApiUrl}/network-delegation-service/rest/networks/${networkId}/${apiPortal}/clients`, {
      deviceId
    });

    try {
      let response = await axios().get(url);
      logger('[FetchDevices] response: %o', response.status);
      if (response.status !== 200) {
        throw response.status;
      }
      return response.data;
    } catch (e) {
      logger('[FetchDevices] failed: %o', e);
      throw unwrapErrorResponse(e);
    }
  }
);

export const devicesSlice = createSlice({
  name: 'devices',
  initialState: {
    loadingState: LoadingState.NONE,
    clients: [],
    devices: [],
    onlineDevices: [],
    offlineDevices: []
  },
  reducers: {
    resetDevicesLoadingState: (state, action) => {
      state.loadingState = LoadingState.NONE;
    }
  },
  extraReducers: {
    [startFetchDevices.pending]: (state, action) => {
      logger('startFetchDevices.pending: %o, %o', state, action);
      state.loadingState = LoadingState.LOADING;
    },
    [startFetchDevices.fulfilled]: (state, action) => {
      logger('startFetchDevices.fulfilled: %o, %o', state, action);
      state.clients = action?.payload;
      // devices object = add node deviceId to client object and flatten payload
      state.devices = action?.payload?.map(node => {
        node.clients = node.clients.map(client => {
          client.node = node.deviceId;
          return client;
        });
        return node;
      }).flatMap(device => device.clients);
      state.onlineDevices = state.devices?.filter(device => device.online === true);
      state.offlineDevices = state.devices?.filter(device => device.online === false);
      state.loadingState = LoadingState.LOADED;
    },
    [startFetchDevices.rejected]: (state, action) => {
      logger('startFetchDevices.rejected: %o, %o', state, action);
      state.loadingState = LoadingState.LOADED;
    }
  }
});

export const {
  resetDevicesLoadingState: doResetDevicesLoadingState
} = devicesSlice.actions;

export const selectDevicesLoadingState = state => state?.devices?.loadingState;
export const selectDevicesCount = state => ({
  onlineCount: state?.devices?.onlineDevices?.length,
  offlineCount: state?.devices?.offlineDevices?.length
});
export const selectOnlineDevices = state => state?.devices?.onlineDevices;
export const selectOfflineDevices = state => state?.devices?.offlineDevices;

export const devicesReducer = devicesSlice.reducer;
