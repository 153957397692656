import React from "react";
import GreenLight from '../../assets/topology/green-light.svg';
import RedLight from '../../assets/topology/red-light.svg';

export const HomeSsidBoard = ({hasCorpNetIssue = false, hasHomeWiFiIssue = false}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='mr-5'>
        <span className='mr-3'>
          {hasCorpNetIssue ? <img src={RedLight} alt="" /> : <img src={GreenLight} alt="" />}
        </span>
        <span className='sf-text-f-body1'>CorpNet</span>
      </div>
      <div>
        <span className='mr-3'>
          {hasHomeWiFiIssue ? <img src={RedLight} alt="" /> : <img src={GreenLight} alt="" />}
        </span>
        <span className='sf-text-f-body1'>Home WiFi</span>
      </div>
    </div>
  );
}