import React from "react";
import { createLogger } from "./util";

const logger = createLogger('sf:components:InvisibleFileUpload');

export function InvisibleFileUpload({onFileSelected, setFileRef}) {
  const fileChanged = (event) => {
    logger("file changed, files: ", event.target.files);
    if (onFileSelected != null) {
      onFileSelected(event.target.files);
    }
  }

  const setFile = it => {
    if (it != null) {
      logger("Receiving file ref now, updating it to .d-none:", it);
      setFileRef(it);
      it.className = "d-none";
    }
  }

  return (
    <input type="file" id="DevicesList_file" name="file"
           onChange={fileChanged}
           ref={setFile}/>
  );
}