import React, {useEffect} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {addToast} from '../../common/toast';

export function AccessDenied() {
  const { url } = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    addToast(`Not authorized to access ${url}. Redirecting...`, 'error');
    history.replace('/');
  }, [history, url]);

  return <></>;
}
