import React, { useEffect } from "react";
import { hasError } from "../common/validation";
import { SfFormSelect } from "./SfFormSelect";
import Dropdown from 'react-bootstrap/Dropdown';
import { Button, Form } from "react-bootstrap";
import * as s from 'superstruct';
import { superstructResolver } from "@hookform/resolvers/superstruct";
import { useForm } from "react-hook-form";
import { SfCheckbox } from "./SfCheckbox";

const schema = s.object({
  timezoneId: s.string(),
  autoAdjustDst: s.boolean(),
});

/**
 * A form for user to select timezone
 *
 * @param className
 * @param data All timezones
 * @param selected The currently selected timezone
 * @param reload Reload timezone data
 * @param submit Submit timezone data
 * @param cancel
 * @param title The block title
 * @constructor
 */
export function SfTimezoneEditor({ className, data, selected, reload, submit, cancel, title = "Time Zone" }) {
  const form = useForm({
    resolver: superstructResolver(schema, {
      coerce: true
    }),
    defaultValues: {
      timezoneId: selected?.timezoneId,
      autoAdjustDst: selected?.autoAdjustDst,
    }
  });
  const { control, handleSubmit, formState: { errors }, setValue } = form;

  useEffect(() => {
    if (selected != null) {
      setValue('timezoneId', selected?.timezoneId);
      setValue('autoAdjustDst', selected?.autoAdjustDst);
    }
  }, [selected]);

  return (
    <>
      <Form onSubmit={handleSubmit(submit)}>
        <Form.Group>
          <h3 className="mb-4">{title}</h3>
          <SfFormSelect name="timezoneId" defaultValue="-- Select a timezone --" control={control}
                        className={`mb-4 ${hasError(errors, "timezoneId") ? "is-invalid" : ""}`}
          >
            {
              data?.map(
                (detail, i) => <Dropdown.Item key={detail.timezoneId} eventKey={detail.timezoneId}>{detail.description}</Dropdown.Item>)
            }
          </SfFormSelect>
        </Form.Group>
        <Form.Group>
          <SfCheckbox className="mb-4" form={form} path="autoAdjustDst">
            Automatically adjust for daylight saving time
          </SfCheckbox>
        </Form.Group>
        <div className="w-100 text-right">
          <Button className="sf-btn-w-default mr-4" variant="secondary" onClick={cancel}>Cancel</Button>
          <Button className="sf-btn-w-default" type="submit">Save</Button>
        </div>
      </Form>
    </>
  );
}

