import {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {createLogger} from "../common/util";
import {selectViewNetworkId} from "../topology/_topologySlice";
import {selectCustomerId} from "../auth/_authSliceSelect";
import {COMMAND_STATUS, startFetchAllSpeedTestResults, startFetchSpeedTestStats, updateSpeedTestCommandStatus} from "./_speedsSlice";
import {addToast} from "../common/toast";
import {useIoT, useIotMessageCallback} from "../iot/_iotHook";
import {startFetchLatestSpeedTest} from "../common/commonAsyncThunks";

const logger = createLogger('ui:speeds:speedTest:iot');

export const SpeedTestIoT = () => {
  const dispatch = useDispatch();
  const networkId = useSelector(selectViewNetworkId);
  const customerId = useSelector(selectCustomerId);

  // IoT
  const iotParam = useMemo(() => {
    const topic = getTopic({customerId, networkId});
    logger(`useIotMessageCallback, topic: ${topic}`);
    return {
      topic: topic,
      orgId: customerId,
      filter: (message) => {
        const event = _.get(message, 'payload.event');
        return event === 'SPEED_TEST';
      },
      callback: (message) => {
        const event = _.get(message, '[0].payload.event');
        if (event === 'SPEED_TEST') {
          dispatch(updateSpeedTestCommandStatus(COMMAND_STATUS.NONE));
          dispatch(startFetchLatestSpeedTest({networkId}));
          dispatch(startFetchAllSpeedTestResults({networkId}));
          dispatch(startFetchSpeedTestStats({networkId}));
          addToast('Speed Test is completed', 'info');
        }
        logger('IoT message received: ', message);
      }
    };
  }, [dispatch, customerId, networkId]);
  useIoT(iotParam);
  useIotMessageCallback(iotParam);
  return null;
}

const getTopic = ({customerId, networkId}) => {
  if (customerId == null || networkId == null) {
    return undefined;
  }
  return `sf/org/${customerId}/net/${networkId}/status`;
};