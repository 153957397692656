import { store } from '../common/app';
import * as _ from 'lodash';
import { addToast } from '../common/toast';
import { clearIotData, subscribeTopic, unsubscribeTopic } from './_iotSlice';
import { createLogger, isEmpty } from '../common/util';

const logger = createLogger('ui:iot:index');

export const IotStatus = Object.freeze({
  CONNECT: 'connect',
  CLOSE: 'close',
  RECONNECT: 'reconnect'
});

/**
 * Subscribe topic.
 * Make sure IoT is connected before calling this function.
 */
export const subscribe = ({dispatch, device, topic}) => {
  if (!validateIoT({topic, type: 'subscribe', device})) {
    return;
  }
  device.subscribe(topic);
  const res = dispatch(subscribeTopic({topic}));
  logger(`Topic %s subscribed: %o`, topic, res);
  return res;
}

/**
 * Unsubscribe topic.
 * Make sure IoT is connected before calling this function.
 */
export const unsubscribe = ({dispatch, device, topic}) => {
  if (!validateIoT({topic, type: 'unsubscribe', device})) {
    return;
  }
  device.unsubscribe(topic);
  dispatch(unsubscribeTopic({topic}));
  logger(`Topic ${topic} unsubscribed.`);
}

function validateIoT({device, topic, type}) {
  if (isEmpty(topic)) {
    addToast('IoT topic is invalid', 'error');
    return false;
  }
  if (!store) {
    addToast('Redux store instance not found!', 'error');
    return false;
  }
  if (!device) {
    if (type === 'subscribe') {
      addToast('IoT device instance not found', 'error');
    }
    return false;
  }
  const status = _.get(store.getState(), 'iot.status');
  if (status !== IotStatus.CONNECT && type === 'subscribe') {
    addToast('IoT is not connected, cannot subscribe topic', 'error');
    return false;
  }
  return true;
}

export function disconnect({device, dispatch}) {
  if (device) {
    logger("IoT disconnecting");
    device.end();
    if (dispatch) {
      dispatch(clearIotData());
    }
  }
}
