import React, { useEffect, useState } from "react";

export function UserVpnView({ className, data, vpnServers, title = "VPN Details" }) {
  const [server, setServer] = useState();

  useEffect(() => {
    // find server from vpnServers list
    if (vpnServers != null) {
      setServer(vpnServers.find(it => it.hostId === data?.preferredHostId));
    }
  }, [vpnServers, data?.preferredHostId]);

  return (
    <div className={className}>
      <h3 className="mb-4">{title}</h3>
      <div className="row mb-2">
        <div className="col-4 sf-font-body1">Server</div>
        <div className="col-8 sf-font-body1">{server?.hostName || "Unknown"}</div>
      </div>
      <div className="row mb-2">
        <div className="col-4 sf-font-body1">Username</div>
        <div className="col-8 sf-font-body1">{data?.userName}</div>
      </div>
      <div className="row">
        <div className="col-4 sf-font-body1">Password</div>
        <div className="col-8 sf-font-body1">{data?.password || "****"}</div>
      </div>
    </div>
  );
}