import React, { useContext } from "react";
import { Button, OverlayTrigger } from "react-bootstrap";
import { SfTwoLevelSwitch } from "../../data/SfTwoLevelSwitch";
import { createLogger } from "../../common/util";
import { WebFilterContext } from "./_webfilterContext";
import { useDispatch, useSelector } from "react-redux";
import { selectCategoryFilterData, selectCategoryFilterSetData, updateCategoryFilter } from "../_securitySlice";
import { addToast } from "../../common/toast";

const logger = createLogger('ui:security:webfilter:FilterByCategoryView');

export function FilterByCategoryView({leadContentBuilder, modifiedState}) {

  const context = useContext(WebFilterContext);
  const dispatch = useDispatch();
  const [modified, setModified] = modifiedState;
  const categoryFilterData = useSelector(selectCategoryFilterData);
  const categorySettingData = useSelector(selectCategoryFilterSetData);
  const { doCategorySubmit, showConflictDialogAndReload } = context;

  const onSwitchChange = (e) => {
    logger("on onSwitchChange e: %o", e);
    dispatch(updateCategoryFilter({
      id: e.id,
      action: e.targetValue
    }));
    setModified(true);
  }

  const onSave = () => {
    doCategorySubmit(Object.values(categorySettingData))
      .then(resp => {
        addToast('save category filters successfully', 'success');
        setModified(false);
      })
      .catch((error) => {
        if (error.code != null && error.code === "ERR_CODE_MODEL_VERSION_CONFLICT") {
          showConflictDialogAndReload();
        } else {
          addToast('save URL filters failed:' + error.code, 'error');
        }
      });
  }

  const secondTitle = (category) => {
    const name = category.name != null ? category.name : category.group;
    const desc = category?.desc;
    // const example = category?.example;

    const popper = (
      <div className="popover">
        <div className="popover-body">
          {/* <h5>Description</h5> */}
          <p className="mb-0">{desc}</p>

          {/* <h5 className="mt-2">Examples</h5>
          <p className="mb-0">{example}</p> */}
        </div>
      </div>
    );

    return <div className="sf-switch-second-title">
      {name}
      <OverlayTrigger placement="bottom-start" overlay={popper} popperConfig={{
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 4]
            }
          }
        ]
      }}>
        <i className="sf-icon-info ml-2" />
      </OverlayTrigger>
    </div>
  };

  return (
    <>
      {leadContentBuilder && leadContentBuilder({modified, setModified, context, categoryData: categorySettingData})}
      {modified && <div className="fixed-bottom sf-banner-bottom sf-bg-white w-100 border-top shadow-up d-flex align-items-center justify-content-end">
        <Button variant='primary' className="sf-btn-w-default sf-btn-h-default mr-0" onClick={onSave}>
          Submit
        </Button>
      </div>}
      <SfTwoLevelSwitch
        data={categoryFilterData}
        secondTitle={secondTitle}
        valuePath="action"
        options={[
          { name: 'Block', value: 'block' },
          { name: 'Allow', value: 'allow' },
        ]}
        onChange={onSwitchChange} />
    </>
  );

}