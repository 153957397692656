import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { LoadingOverlay } from "../../loader/LoadingOverlay";
import NodeClientDetailsEditView from './NodeClientDetailsEditView';

const NodeClientDetailsView = ({ client, networkId, clientId, status, show, closeFunction }) => {
  const {
    deviceName,
    band,
    macAddress,
    ipAddress,
    brand,
    model,
    os,
    deviceType,
    name: clientDeviceFriendlyName,
  } = client;
  const { loading, isNodeClientDetailsEmpty } = status;
  const [ isEditing, setIsEditing ] = useState(false);

  const enableEditView = () => {
    setIsEditing(true);
  };

  return (
    <>
      <div className="node-details-popup">
        <div className="popup-overlay">
          <LoadingOverlay
            className="d-flex align-items-center justify-content-center display-4 h-75"
            initialized={!loading && !isNodeClientDetailsEmpty}
          >
            <></>
          </LoadingOverlay>
          {!isNodeClientDetailsEmpty && !loading && (
            <>
              <Modal animation={false} centered
                     className="modal-backdrop"
                     backdropClassName="sf-modal-backdrop-light"
                     contentClassName="sf-width-30rem"
                     backdrop="static"
                     show={show} onHide={closeFunction}>
                <Modal.Body className="w-100 pt-6 pb-9">
                  <div className="d-flex justify-content-end">
                    <i className="sf-icon-close cursor-pointer" onClick={closeFunction} />
                  </div>
                  {isEditing && (
                    <>
                      <div className="d-flex justify-content-start align-items-center mb-4">
                          <h3>Edit Device</h3>
                      </div>
                      <NodeClientDetailsEditView client={client} show={show} closeFunction={closeFunction} networkId={networkId} clientId={clientId}/>
                    </>
                  )}
                  {!isEditing && (
                    <>
                      <div className="d-flex justify-content-start align-items-center mb-7">
                        <div className="sf-square-md rounded-circle sf-bg-gray-1 mr-5">{null}</div>
                        <div>
                          <h3>{clientDeviceFriendlyName || deviceType}</h3>
                          <a className="sf-font-h5 edit-link cursor-pointer" onClick={enableEditView}>Edit</a>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <div className="col-6 text-nowrap sf-font-body1 sf-text-secondary">Node:</div>
                        <div className="col-6 text-break sf-font-h5 sf-text-primary-text">{deviceName}</div>
                      </div>
                      <div className="row mb-4">
                        <div className="col-6 text-nowrap sf-font-body1 sf-text-secondary">Connection:</div>
                        <div className="col-6 text-break sf-font-h5 sf-text-primary-text">{band} Wireless</div>
                      </div>
                      <div className="row mb-4">
                        <div className="col-6 text-nowrap sf-font-body1 sf-text-secondary">MAC Address:</div>
                        <div className="col-6 text-break sf-font-h5 sf-text-primary-text">{macAddress}</div>
                      </div>
                      <div className="row mb-4">
                        <div className="col-6 text-nowrap sf-font-body1 sf-text-secondary">IP Address:</div>
                        <div className="col-6 text-break sf-font-h5 sf-text-primary-text">{ipAddress}</div>
                      </div>
                      <div className="row mb-4">
                        <div className="col-6 text-nowrap sf-font-body1 sf-text-secondary">Manufacturer:</div>
                        <div className="col-6 text-break sf-font-h5 sf-text-primary-text">{brand || deviceType}</div>
                      </div>
                      <div className="row mb-4">
                        <div className="col-6 text-nowrap sf-font-body1 sf-text-secondary">Model:</div>
                        <div className="col-6 text-break sf-font-h5 sf-text-primary-text">{model || deviceType}</div>
                      </div>
                      <div className="row">
                        <div className="col-6 text-nowrap sf-font-body1 sf-text-secondary">OS:</div>
                        <div className="col-6 text-break sf-font-h5 sf-text-primary-text">{os || deviceType}</div>
                      </div>
                    </>
                  )}
                </Modal.Body>
              </Modal>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default NodeClientDetailsView;
