import React, { useEffect, useMemo, useRef } from "react";
import { createLogger } from "./util";
import { SfScrollerContext } from "./SfScrollerContext";

const logger = createLogger("ui:common:SfScroller");

/**
 * Create a scroller which allows you to add a top floating header, the floater (top floating header) will sticky to top so that it's
 * always visible.
 *
 * This element or the sub element must be added with `overflow: auto / scroll` style, so that it can be scrolled.
 *
 * Place <SfScrollerFloater/> inside to mark the floater element.
 *
 * Example:
 *
 * ```
 * // Enclosed in a overflow auto div
 * <SfScroller className="h-100 overflow-auto">
 *   <div>Some header</div>
 *   <SfScrollerFloater>
 *     <div>floater is here</div>
 *   </SfScrollerFloater>
 *   <div>content</div>
 * </SfScroller>
 * ```
 *
 * The component can be used with `react-window`. There are a few gotchas:
 *
 * 1. react-window will create an overflow block, as a result <SfScroller /> should not create an overflow. However, if you want some top
 *    headers to be floating, you'll have to add it as the first element and use <VariableSizeList/>
 * 2. React-window, as the name suggest, only display elements inside a window. The other elements will be removed from DOM if scrolled out,
 *    that means the 1st row (where the floater is) may be removed and therefore won't be able to float.
 *    To remedy this, a duplicate header element can be added. The duplicate header will only be displayed when the floater is in sticky
 *    position, and the scroll out element being hidden. This should simulate the sticky behavior of the floater.
 * 3. Some utility classes are included:
 *    - `.scroller-floater-hide`: Will not display if floater is floating (sticky top).
 *    - `.scroller-floater-display-block`: Will only display if floater is floating.
 *    - `.scroller-floater-display-flex`: Will only display if floater is floating.
 *
 * React-window example:
 *
 * ```
 * const header = ({className}) => (
 *   <h5 className={className}>This is a header</h5>
 * );
 *
 * const = list = {
 *   <VariableSizeList>
 *     <div>
 *       <SfScrollerFloater>
 *         {
 *           // this header will not display when floater is sticky
 *           header({className: "scroller-floater-display-none"})
 *         }
 *       </SfScrollerFloater>
 *     </div>
 *     <div>item 1</div>
 *     <div>item 2</div>>
 *   </div>
 * }
 *
 * return (
 *   <SfScroller>
 *     {
 *       // this header will only display when floater is sticky
 *       header({ className: "scroller-floater-display-block" })
 *     }
 *     {list}
 *   </SfScroller>
 * )
 * ```
 *
 * @param className Class name of the scroll container
 * @param children The content to be scrolled
 * @param thresholdFactor {number|undefined} The factor to calculate threshold for IntersectionObserver. Default to 10. The threshold
 * array is created between 0 - 1, divided by thresholdFactor. So thresholdFactor of 2 will make it [0, 0.5, 1]
 * @param markerRef {HTMLElement} The caller may choose to pass in a marker. Must not be used together with <SfScrollerFloater />
 * @param rest The other parameter that should be added to the scroll container
 * @return {JSX.Element}
 * @constructor
 */
export function SfScroller({ className, children, thresholdFactor = 10, markerRef= undefined, ...rest }) {
  const containerRef = useRef();

  const threshold = useMemo(() => {
    return [...Array(thresholdFactor + 1).keys()].map(n => n / thresholdFactor);
  }, [thresholdFactor]);

  const context = {
    containerRef,
    fullyVisible: observerEntry => {
      const { boundingClientRect, rootBounds } = observerEntry;
      // const shouldFloat = e[0].intersectionRatio < 1;
      return boundingClientRect.top >= rootBounds.top && boundingClientRect.bottom <= rootBounds.bottom;
    },
    threshold: threshold,
    doFloating: (shouldFloat) => {
      const { current } = containerRef;
      if (current != null) {
        if (shouldFloat) {
          current.classList.add("scroller-floater-active");
        } else {
          current.classList.remove("scroller-floater-active");
        }
      } else {
        logger("doScrollFloat, but current is null");
      }
    }
  };

  // if markerRef is provided, monitor intersection here.
  useEffect(() => {
    const marker = markerRef?.current;
    const { doFloating, fullyVisible } = context;
    if (marker != null && containerRef?.current != null) {
      logger("Initialize scroller observer: %o", marker);
      const obs = new IntersectionObserver(function (e, o) {
        // const shouldFloat = e[0].intersectionRatio < 1;
        doFloating(!fullyVisible(e[0]));
      }, {
        root: containerRef?.current,
        // threshold: [0.01, 1]
      });
      obs.observe(marker);

      return () => {
        logger("Disconnect scroller observer");
        // when floater is removed, we definitely needs to do floating
        doFloating(true);
        obs.disconnect();
      }
    }
  }, [markerRef?.current, containerRef?.current, context]);

  return (
    <div className={`scroller ${className || ""}`} ref={containerRef} {...rest}>
      <div className="scroller-content">
        <SfScrollerContext.Provider value={context}>
          {children}
        </SfScrollerContext.Provider>
      </div>
    </div>
  );
}