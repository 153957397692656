import {Slide} from 'react-toastify';

const toastConfig = {
  instance: undefined,
  options: {
    position: 'bottom-center',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    transition: Slide,
    pauseOnFocusLoss: false
  },
  errorOptions: {
    position: 'bottom-center',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    transition: Slide,
    pauseOnFocusLoss: true
  }
};

/*
 * The Toast you don't want to be popup multiple times.
 */
const previousToast = new Set();

const duplicatedToastIn5S = ({ content }) => {
  if (previousToast.has(content)) {
    return true;
  } else {
    previousToast.add(content);
    setTimeout(() => clearPreviousToast({ content }), 5000);
    return false;
  }
};

const clearPreviousToast = ({ content }) => {
  previousToast.delete(content);
};

export const addToast = (content, variant, singleton = true) => {
  const toastInstance = toastConfig.instance;
  if (!toastInstance) {
    window.alert('Toast config is needed!');
    return;
  }

  if (singleton && duplicatedToastIn5S({ content })) {
    // Toast is duplicated in this 5S, ignore it.
    return;
  }

  let func;
  if (variant && variant in toastInstance) {
    func = toastInstance[variant];
  } else {
    func = toastInstance.info;
  }

  const options = variant === 'error' ? toastConfig.errorOptions : toastConfig.options

  func(content, {
    ...options
  });
};

export function setToastConfig(config) {
  if (config.instance) {
    toastConfig.instance = config.instance;
  }
  if (config.options) {
    toastConfig.options = config.options;
  }
  if (config.errorOptions) {
    toastConfig.errorOptions = config.errorOptions;
  }
}
