import React, {useEffect, useRef, useState} from 'react';
import * as d3 from 'd3';
import {doPrepareSvgDevicePill, doPrepareSvgNodePill, doPrepareSvgTopPill} from './SvgPill';
import redAlert from "../../../assets/topology/red-alert.svg";
import _ from "lodash";
import {createLogger} from "../../common/util";

const logger = createLogger('ui:topology:index');

const nodeWidth = 208;
const nodeHeight = 88;

/**
 * Given the input data, calculate the max elements in a row and total rows into an array
 * @param data Topology source data
 * @return {{x:number, y:number}} An object to represents max elements. `x` for max elements in a row. `y` for total rows.
 */
function treeMaxElements(data) {
  // root level is 1
  const analyzed = mapTreeWithLevels(data, 1);
  const levels = traverseDepthFirst(analyzed, (it) => it.level);
  const levels2 = levels.reduce((all, level) => {
    if (level in all) {
      all[level] = all[level] + 1;
    } else {
      all[level] = 1;
    }
    return all;
  }, {});
  const maxElementsByLevel = Math.max(...Object.values(levels2));
  const maxLevels = Math.max(...Object.keys(levels2).map(parseFloat));

  return {x: maxElementsByLevel, y: maxLevels};
}

function mapTreeWithLevels(el, curLevel) {
  const x = {
    data: el,
    level: curLevel
  };
  if ('children' in el && el.children != null) {
    x.children = el.children.map((it) => mapTreeWithLevels(it, curLevel + 1));
  }
  return x;
}

/**
 * Traverse the tree depth first
 * @param node Cur node to be traversed
 * @param mapper The mapper function to transform node into result
 * @return {[*]} Mapped result of each node, flatten.
 */
function traverseDepthFirst(node, mapper) {
  let list = [mapper(node)];
  if ('children' in node && node.children != null) {
    const res = node.children.flatMap((child) => {
      return traverseDepthFirst(child, mapper);
    });
    list = list.concat(res);
  }
  return list;
}

export function SvgTopology({data, onNodeClick, className = ''}) {
  // To create top node
  const myRef = useRef(null);
  const [viewBox, setViewBox] = useState({x: 0, y: 0});

  useEffect(() => {
    const _data = {
      top: true,
      hasIssue: data?.status !== 'ONLINE',
      children: [data]
    };
    const matrix = treeMaxElements(_data);
    const margin = {x: 20, y: 20};
    const nodeSize = {x: nodeWidth, y: nodeHeight};
    const nodeGap = {x: 68, y: 64};
    const dimension = {
      x: matrix.x * nodeSize.x + (matrix.x - 1) * nodeGap.x + margin.x * 2,
      y: matrix.y * nodeSize.y + (matrix.y - 1) * nodeGap.y + margin.y * 2
    };

    const treemap = d3
      .tree()
      .size([dimension.x, dimension.y])
      .nodeSize([nodeSize.x + nodeGap.x, nodeSize.y + nodeGap.y]);
    const nodes = d3.hierarchy(_data);
    const nodes2 = treemap(nodes);
    // the computed tree will place the root element at 0,0. So we need to find the left most X and move the entire view with a transform
    const listOfX = traverseDepthFirst(nodes2, (it) => it.x);
    const leftMostX = Math.min(...listOfX);
    const rightMostX = Math.max(...listOfX);
    // const bottomMostY = Math.max(...traverseDepthFirst(nodes2, (it) => it.y));
    const computedMargin = Object.assign({}, margin);
    if (leftMostX < 0) {
      computedMargin.x = leftMostX * -1 + margin.x;
    }
    // ViewBox x = left x + right x + node width + margin
    setViewBox({x: Math.abs(leftMostX) + Math.abs(rightMostX) + nodeSize.x + margin.x * 2, y: dimension.y});

    const root = d3.select(myRef.current);
    const refs = root.append('defs');
    createMarker(refs)

    root.selectAll('g').remove();
    const base = root.append('g').attr('transform', `translate(${computedMargin.x},${computedMargin.y})`);

    // adds the links between the nodes
    const linksG = base
      .selectAll('.link')
      .data(nodes2.descendants().slice(1))
      .enter()
      .append('g');
    linksG.append('path')
      .attr('class', 'link')
      .attr('fill', 'none')
      .attr('stroke', (d) => d?.data?.status === 'ONLINE' ? '#13C59D' : '#FF3B56')
      .attr('stroke-width', '1px')
      .attr('marker-start', (d) => d?.data?.status === 'ONLINE' ? 'url(#marker)' : 'url(#marker-alert)')
      .attr('d', function (d) {
        return (
          `M${d.x + nodeSize.x / 2},${d.y - 3} ` +
          `C${d.x + nodeSize.x / 2},${(d.y + d.parent.y) / 2 + 10} ` +
          `${d.parent.x + nodeSize.x / 2},${((d.y + d.parent.y + nodeSize.y) * 3) / 5} ` +
          `${d.parent.x + nodeSize.x / 2},${d.parent.y + nodeSize.y}`
        );
        // + `L${d.parent.x + nodeSize.x / 2},${d.parent.y + nodeSize.y}`
      });

    linksG.append('svg:image')
      .attr('xlink:href', (d) => d?.depth !== 1 && d?.data?.status === 'OFFLINE' ? redAlert : null)
      .attr('x', (d) => {
        return d.parent.x + (nodeSize.x / 2) - 14 + (d.x - d.parent.x) / 2;
      })
      .attr('y', (d) => d.y - (nodeGap.y / 2) - 10)
      .attr('width', 28)
      .attr('height', 28)
      .attr('class', 'topology-node-alert');

    base
      .selectAll('.node')
      .data(nodes2.descendants())
      .enter()
      .append('g')
      .attr('class', function (d) {
        return `node ${d.depth === 0 ? 'top' : 'device clickable'} ${(d.children ? 'node--internal' : 'node--leaf')}`;
      })
      .attr('transform', function (d) {
        return 'translate(' + d.x + ',' + d.y + ')';
      });

    const _topNode = base.selectAll('.node.top');
    doPrepareSvgTopPill(_topNode, {total: summaryDevice(data), nodeWidth, nodeHeight});

    const _nodes = base.selectAll('.node.device');
    // Bind click event
    _nodes.on("click", function (e, d) {
      e.preventDefault();
      logger('node is clicked', e, d);
      if (onNodeClick) {
        const id = _.get(d, 'data.id');
        onNodeClick({id});
      }
    });
    doPrepareSvgDevicePill(_nodes, {
      nodeWidth,
      nodeHeight
    });

  }, [myRef, data]);

  return (
    <div className={`text-center ${className}`}>
      <svg preserveAspectRatio='xMinYMin meet' ref={myRef} viewBox={`0 0 ${viewBox?.x} ${viewBox?.y}`}
           style={{maxWidth: hasMultipleChildren(data) ? 524 : 248}}/>
    </div>
  );
}

function createMarker(refs) {
  const marker = refs.append('marker')
    .attr('id', 'marker')
  setMarkerAttributes(marker);
  const path = marker.append('path')
    .attr('style', 'fill: #13C59D;')
  drawArrow(path);

  const markerAlert = refs.append('marker')
    .attr('id', 'marker-alert')
  setMarkerAttributes(markerAlert);
  const pathAlert = markerAlert.append('path')
    .attr('style', 'fill: #FF3B56;')
  drawArrow(pathAlert);
  logger('created refs:', refs);
}

function setMarkerAttributes(marker) {
  marker.attr('markerUnits', 'strokeWidth')
    .attr('markerWidth', 12)
    .attr('markerHeight', 12)
    .attr('viewBox', '0 0 12 12')
    .attr('orient', '90')
    .attr('refX', 9)
    .attr('refY', 6);
}

function drawArrow(path) {
  path.attr('d', 'M2,2 L10,6 L2,10 L6,6 L2,2');
}

function hasMultipleChildren(root) {
  if (root.children) {
    return root.children.length > 1;
  }
  return false;
}

function summaryDevice(root) {
  let sum = 0;
  if (root == null) {
    return 0;
  }
  const rootCount = root.status === 'ONLINE' ? _.get(root, 'count', 0) : 0;
  sum += rootCount;
  if (root.children) {
    root.children.forEach(child => {
      sum += child.status === 'ONLINE' ? _.get(child, 'count', 0) : 0;
    });
  }
  return sum;
}

export function SvgNodes({data, className = ''}) {
  // To create top node
  const myRef = useRef(null);
  const [viewBox, setViewBox] = useState({x: 0, y: 0});

  useEffect(() => {
    const matrix = treeMaxElements(data);
    const margin = {x: 20, y: 20};
    const nodeSize = {x: nodeWidth, y: nodeHeight};
    const nodeGap = {x: 68, y: 30};
    const dimension = {
      x: matrix.x * nodeSize.x + (matrix.x - 1) * nodeGap.x + margin.x * 2,
      y: matrix.y * nodeSize.y + (matrix.y - 1) * nodeGap.y + margin.y * 2
    };

    const treemap = d3
      .tree()
      .size([dimension.x, dimension.y])
      .nodeSize([nodeSize.x + nodeGap.x, nodeSize.y + nodeGap.y]);
    const nodes = d3.hierarchy(data);
    const nodes2 = treemap(nodes);
    // the computed tree will place the root element at 0,0. So we need to find the left most X and move the entire view with a transform
    const listOfX = traverseDepthFirst(nodes2, (it) => it.x);
    const leftMostX = Math.min(...listOfX);
    const rightMostX = Math.max(...listOfX);
    // const bottomMostY = Math.max(...traverseDepthFirst(nodes2, (it) => it.y));
    const computedMargin = Object.assign({}, margin);
    if (leftMostX < 0) {
      computedMargin.x = leftMostX * -1 + margin.x;
    }
    // ViewBox x = left x + right x + node width + margin
    setViewBox({x: Math.abs(leftMostX) + Math.abs(rightMostX) + nodeSize.x + margin.x * 2, y: dimension.y});

    const root = d3.select(myRef.current);

    root.selectAll('g').remove();
    const base = root.append('g').attr('transform', `translate(${computedMargin.x},${computedMargin.y})`);

    const n = base
      .selectAll('.node')
      .data(nodes2.descendants())
      .enter()
      .append('g')
      .attr('class', function (d) {
        return `node device ${(d.children ? 'node--internal' : 'node--leaf')}`;
      })
      .attr('transform', function (d) {
        return 'translate(' + d.x + ',' + d.y + ')';
      })
      .on("click", function (e, d) {
        e.preventDefault();
        logger('node is clicked', e, d);
      });

    doPrepareSvgNodePill(n, {
      nodeWidth,
      nodeHeight
    });

  }, [myRef, data]);

  return (
    <div className={`${className}`}>
      <svg preserveAspectRatio='xMinYMin meet' ref={myRef} viewBox={`0 0 ${viewBox?.x} ${viewBox?.y}`}
           style={{maxWidth: 248}}/>
    </div>
  );
}