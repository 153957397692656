import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createLogger, unwrapErrorResponse } from "../common/util";
import { envBaseApiUrl } from "../common/env";
import { getAxiosInstance as axios } from "../auth/axios/axiosCommon";

const logger = createLogger("cloud:utility:slice");

export const startFetchTimezones = createAsyncThunk(
  'cloudUtility/FetchTimezones',
  async (x, {rejectWithValue, getState, dispatch}) => {
    logger('[FetchTimezones] begin');
    const url = `${envBaseApiUrl}/utility-service/rest/timezones`;

    return axios().get(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw unwrapErrorResponse(error);
      });
  }
);

export const cloudUtilitySlice = createSlice({
  name: 'cloudUtility',
  initialState: {
    timezones: undefined,
  },
  reducers: {
    // doSomething: (state, action) => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.value += 1;
    // },
  },
  extraReducers: {
    [startFetchTimezones.pending]: (state, action) => {
      logger("startFetchTimezones.pending: %o, %o", state, action);
    },
    [startFetchTimezones.fulfilled]: (state, action) => {
      logger("startFetchTimezones.fulfilled: %o, %o", state, action);
      if (action?.payload != null) {
        state.timezones = action?.payload;
      }
    },
    [startFetchTimezones.rejected]: (state, action) => {
      logger("startFetchTimezones.rejected: %o, %o", state, action);
    },
  }
});

// export const { doSomething } = slice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
// export const startSomethingAsync = amount => dispatch => {
//   setTimeout(() => {
//     dispatch(doSomething());
//   }, 1000);
// };

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
// export const selectSomething = state => state.cloudUtility.value;
export const selectTimezones = state => state?.cloudUtility?.timezones;

export default cloudUtilitySlice.reducer;
