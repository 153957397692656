import React, { useEffect, useState } from "react";
import { SfTimezoneView } from "./SfTimezoneView";
import { SfTimezoneEditor } from "./SfTimezoneEditor";
import { createLogger } from "../common/util";

const logger = createLogger("form:SfTimezone");

export function SfTimezone({ className, data, selected, editing, reload, submit, title = "Time Zone", readonly = false }) {
  // external editing value is the default of internal editing
  const [editing2, setEditing2] = useState(editing && !readonly);

  const toggleEditing = (override) => {
    setEditing2(x => {
      logger("Toggle editing from %s, override: %s, readonly: %s", x, override, readonly);
      if (readonly) {
        return false;
      }
      if (override) {
        return override;
      }
      return !x;
    });
  };

  // if external editing value changed, use it to update internal editing
  useEffect(() => {
    if (editing !== undefined) {
      toggleEditing(editing);
    }
  }, [editing]);

  return (
    <>
      {!editing2 && <SfTimezoneView className={className} data={data} selected={selected} readonly={readonly}
                                    toggle={toggleEditing}
                                    title={title}/>}
      {editing2 && <SfTimezoneEditor className={className} data={data} selected={selected}
                                     reload={reload} submit={submit} cancel={() => toggleEditing(false)}
                                     title={title}/>}
    </>
  )
}