import React, {useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {createLogger} from "../common/util";
import {COMMAND_STATUS, updateSpeedTestCommandStatus} from "./_speedsSlice";
import {addToast} from "../common/toast";
import {selectViewNetworkId, selectViewTopology} from "../topology/index";
import {startSendCommandToDevice} from "../common/commonAsyncThunks";

const logger = createLogger('ui:speeds:speedTestButton');

export const SpeedTestButton = ({text}) => {
  const dispatch = useDispatch();
  const networkId = useSelector(selectViewNetworkId);
  const topology = useSelector(selectViewTopology);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    setDisabled(() => {
      const isOnline = _.get(topology, 'root.status') === 'ONLINE';
      setDisabled(!isOnline);
    });
  }, [topology]);

  const SpeedTestClickHandler = ({networkId}) => {
    dispatch(updateSpeedTestCommandStatus(COMMAND_STATUS.RUNNING));
    dispatch(startSendCommandToDevice({networkId, cmd: 'speedTest'}))
      .then(resp => {
        if (resp?.payload == null || resp?.payload === '') {
          // nothing is loaded
        } else {
          logger("Speed test command sent", resp?.payload);
          addToast('Command successfully sent', 'success');
        }
      })
      .finally(() => {
      });
  };

  return (
    <Button variant='primary' type='button' className='sf-btn-w-xlg'
            disabled={disabled}
            onClick={() => {
              SpeedTestClickHandler({networkId})
            }}>
      {text}
    </Button>
  );
};