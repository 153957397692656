import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectCustomerConfig, selectIsPasswordMatchRequirements} from '../_authSliceSelect';
import {useForm, useWatch} from 'react-hook-form';
import {addToast} from '../../common/toast';
import {startChangePassword} from '../_authSlice';
import {unwrapResult} from '@reduxjs/toolkit';
import {Button, Form} from 'react-bootstrap';
import {hasError} from '../../common/validation';
import {LinkBasicLogin} from './LinkBasicLogin';
import {SfPassword} from '../../form/SfPassword';
import {createLogger} from '../../common/util';
import {useHistory} from 'react-router-dom';
import {PasswordSecurityRequirements} from './PasswordSecurityRequirements';
import {useQuery} from '../_authHooks';

const logger = createLogger('ui:reset-password');

export const ResetPasswordForm = () => {
  const query = useQuery();
  const token = query.get('verificationToken');
  const email = query.get('username');
  const customerConfig = useSelector(selectCustomerConfig);
  const history = useHistory();
  const dispatch = useDispatch();
  const isValid = useSelector(selectIsPasswordMatchRequirements);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    control
  } = useForm({});

  const watchPassword = useWatch({
    name: 'newPassword',
    control
  });

  const onSubmit = (data) => {
    if (!customerConfig || !customerConfig.realmId) {
      addToast('customer config not ready', 'error');
      return;
    }
    if (!isValid) {
      setError('newPassword', {
        type: 'manual',
        message: 'Password format is invalid'
      });
      return;
    }
    dispatch(startChangePassword({ newPassword: data.newPassword, email, token }))
      .then(unwrapResult)
      .then(() => {
        addToast('Your password has been updated', 'success');
        history.push('/auth/login');
      })
      .catch((error) => {
        addToast(error.message, 'error');
      });
  };

  const onError = (e) => {
    logger('Form field error:', e);
  };

  return (
    <div>
      <section>
        <div className='sf-font-h5 sf-text-c-secondary mt-5'>Enter a new password.</div>
      </section>
      <section className='mt-6'>
        <Form onSubmit={handleSubmit(onSubmit, onError)}>
          <Form.Group controlId='formResetPassword'>
            <Form.Label>New Password</Form.Label>
            <SfPassword
              placeholder='Password'
              name='newPassword'
              {...register('newPassword')}
              className={'icon-muted ' + (hasError(errors, `newPassword`) ? 'is-invalid shake' : '')}
            />
          </Form.Group>
          <PasswordSecurityRequirements className='mt-6' value={watchPassword} />
          <div className='row mt-6'>
            <div className='col-12 col-md-6'>
              <Button variant='primary' className='sf-btn-w-xlg' type='submit'>
                Reset Password
              </Button>
            </div>
            <div className='col-12 col-md-6 d-flex align-items-center'>
              <LinkBasicLogin />
            </div>
          </div>
        </Form>
      </section>
    </div>
  );
};
