import {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {createLogger} from "../common/util";
import {selectCustomerId} from "../auth/_authSliceSelect";
import {useIoT, useIotMessageCallback} from "../iot/_iotHook";
import {doUpdateVpnBandwidth, doUpdateWanBandwidth, selectViewNetworkId} from "./_topologySlice";
import {getBandwidthUsageTopic} from "./topologyIoT";

const logger = createLogger('ui:topology:bandwidth:iot');

export const HomeBandwidthIoT = () => {
  const dispatch = useDispatch();
  const networkId = useSelector(selectViewNetworkId);
  const customerId = useSelector(selectCustomerId);

  // IoT
  const iotParam = useMemo(() => {
    const topic = getBandwidthUsageTopic({customerId, networkId});
    logger(`useIotMessageCallback, topic: ${topic}`);
    return {
      topic: topic,
      orgId: customerId,
      filter: (message) => {
        const event = _.get(message, 'payload.event');
        return event === 'WAN_STATS' || event === 'VPN_STATS';
      },
      callback: (message) => {
        const data = _.get(message, '[0].payload.data', []);
        const ts = _.get(message, '[0].ts', 0);

        data.forEach((d) => {
          const {wan} = d;
          // 1:wan, 2:vpn
          if (wan === 1) {
            dispatch(doUpdateWanBandwidth({ts, ...d}));
          } else if (wan === 2) {
            dispatch(doUpdateVpnBandwidth({ts, ...d}));
          }
        });

        logger('IoT message received: ', message);
      }
    };
  }, [dispatch, customerId, networkId]);
  useIoT(iotParam);
  useIotMessageCallback(iotParam);
  return null;
}
