import React from "react";
import {Avatar} from "./Avatar";

export const PeopleAvatar = (
  {
    imageUrl = '',
    text,
    letters,
    size = 'md',
    bgColor = ''
  }) => {
  if (imageUrl !== '') {
    return (<img src={imageUrl} className={`avatar-circle-${size}`}/>);
  } else {
    return <Avatar className={'peopleAvatar'}
                   text={text}
                   letters={letters}
                   size={size}
                   bgColor={bgColor}/>;
  }
};
