import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import _ from "lodash";
import moment from "moment";
import {composeUrl, createLogger, LoadingState, unwrapErrorResponse} from "../common/util";
import {envBaseApiUrl} from "../common/env";
import {getAxiosInstance as axios} from '../auth/axios/axiosCommon';
import {startFetchLatestSpeedTest} from "../common/commonAsyncThunks";

const logger = createLogger('ui:speeds:slice');

export const startFetchSpeedTestStats = createAsyncThunk(
  'speeds/fetchSpeedTestStats',
  async (input) => {
    logger('[FetchSpeedTestStats] begin: %o', input);
    const {networkId} = input;
    const url = composeUrl(`${envBaseApiUrl}/network-service/rest/networks/${networkId}/speed-test/stats`, {});
    return axios().get(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw unwrapErrorResponse(error);
      });
  }
);

export const startFetchAllSpeedTestResults = createAsyncThunk(
  'speeds/fetchAllSpeedTestResults',
  async (input) => {
    logger('[FetchAllSpeedTestResults] begin: %o', input);
    const {networkId} = input;
    const url = `${envBaseApiUrl}/network-service/rest/networks/${networkId}/speed-test`;
    return axios().get(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw unwrapErrorResponse(error);
      });
  }
);

export const startFetchIspInfo = createAsyncThunk(
  'speeds/fetchIspInfo',
  async (input) => {
    logger('[FetchIspInfo] begin: %o', input);
    const {networkId} = input;
    const url = `${envBaseApiUrl}/network-service/rest/networks/${networkId}/preferences`;
    return axios().get(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw unwrapErrorResponse(error);
      });
  }
);

export const startEditIspInfo = createAsyncThunk(
  'speeds/editIspInfo',
  async (input) => {
    logger('[EditIspInfo] begin: %o', input);
    const {networkId, ispId, ispName, ispMaxSpeed} = input;
    const url = `${envBaseApiUrl}/network-service/rest/networks/${networkId}/preferences`;
    return axios().put(url, {
      ispId, ispName, ispMaxSpeed
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw unwrapErrorResponse(error);
      });
  }
);

export const startFetchIspList = createAsyncThunk(
  'speeds/fetchIspList',
  async () => {
    const url = `${envBaseApiUrl}/utility-service/rest/isp`;
    return axios().get(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw unwrapErrorResponse(error);
      });
  }
);

export const COMMAND_STATUS = Object.freeze({
  NONE: 0,
  RUNNING: 1,
});

export const ISP_ACTIONS = Object.freeze({
  VIEW: 0,
  EDIT: 1,
});

export const slice = createSlice({
  name: 'speeds',
  initialState: {},
  reducers: {
    updateSpeedTestCommandStatus: (state, action) => {
      state.commandStatus = action.payload;
    },
    updateIspAction: (state, action) => {
      state.ispAction = action.payload;
    }
  },
  extraReducers: {
    [startFetchSpeedTestStats.pending]: (state, action) => {
      state.speedTestStatsLoadingState = LoadingState.LOADING;
      logger("startFetchSpeedTestStats.pending: %o, %o", state, action);
    },
    [startFetchSpeedTestStats.fulfilled]: (state, action) => {
      logger("startFetchSpeedTestStats.fulfilled: %o, %o", state, action);
      if (action.payload) {
        state.speedTestStats = {
          avg: processSpeedTestResult(action.payload?.avg),
          best: processSpeedTestResult(action.payload?.best),
          worst: processSpeedTestResult(action.payload?.worst)
        };
      }
      state.speedTestStatsLoadingState = LoadingState.LOADED;
    },
    [startFetchSpeedTestStats.rejected]: (state, action) => {
      logger("startFetchSpeedTestStats.rejected: %o, %o", state, action);
      state.speedTestStatsLoadingState = LoadingState.LOADED;
    },
    [startFetchAllSpeedTestResults.pending]: (state, action) => {
      state.allSpeedTestResultsLoadingState = LoadingState.LOADING;
      logger("startFetchAllSpeedTestResults.pending: %o, %o", state, action);
    },
    [startFetchAllSpeedTestResults.fulfilled]: (state, action) => {
      logger("startFetchAllSpeedTestResults.fulfilled: %o, %o", state, action);
      const data = action.payload?.data;
      if (data) {
        state.allSpeedTestResults = data.map((r) => processSpeedTestResult(r));
      }
      state.allSpeedTestResultsLoadingState = LoadingState.LOADED;
    },
    [startFetchAllSpeedTestResults.rejected]: (state, action) => {
      logger("startFetchAllSpeedTestResults.rejected: %o, %o", state, action);
      state.allSpeedTestResultsLoadingState = LoadingState.LOADED;
    },
    [startFetchLatestSpeedTest.pending]: (state, action) => {
      logger("startFetchLatestSpeedTest.pending: %o, %o", state, action);
    },
    [startFetchLatestSpeedTest.fulfilled]: (state, action) => {
      logger("startFetchLatestSpeedTest.fulfilled: %o, %o", state, action);
      if (action.payload) {
        state.latestSpeedTestResult = processSpeedTestResult(action.payload);
      }
    },
    [startFetchLatestSpeedTest.rejected]: (state, action) => {
      logger("startFetchLatestSpeedTest.rejected: %o, %o", state, action);
    },
    [startFetchIspInfo.pending]: (state, action) => {
      logger("startFetchIspInfo.pending: %o, %o", state, action);
    },
    [startFetchIspInfo.fulfilled]: (state, action) => {
      logger("startFetchIspInfo.fulfilled: %o, %o", state, action);
      if (action.payload) {
        state.ispInfo = action.payload;
      }
    },
    [startFetchIspInfo.rejected]: (state, action) => {
      logger("startFetchIspInfo.rejected: %o, %o", state, action);
    },
    [startFetchIspList.pending]: (state, action) => {
      logger("startFetchIspList.pending: %o, %o", state, action);
    },
    [startFetchIspList.fulfilled]: (state, action) => {
      logger("startFetchIspList.fulfilled: %o, %o", state, action);
      if (action.payload) {
        state.ispList = action.payload;
      }
    },
    [startFetchIspList.rejected]: (state, action) => {
      logger("startFetchIspList.rejected: %o, %o", state, action);
    }
  }
});

export const {updateSpeedTestCommandStatus, updateIspAction} = slice.actions;

export default slice.reducer;

export const selectSpeedTestCommandStatus = state => _.get(state, 'speeds.commandStatus');
export const selectLatestSpeedTestResult = state => _.get(state, 'speeds.latestSpeedTestResult');
export const selectSpeedTestStats = state => _.get(state, 'speeds.speedTestStats');
export const selectSpeedTestStatsLoadingState = state => _.get(state, 'speeds.speedTestStatsLoadingState');
export const selectAllSpeedTestResults = state => _.get(state, 'speeds.allSpeedTestResults', []);
export const selectAllSpeedTestResultsLoadingState = state => _.get(state, 'speeds.allSpeedTestResultsLoadingState');
export const selectIspInfo = state => _.get(state, 'speeds.ispInfo');
export const selectIspList = state => _.get(state, 'speeds.ispList', []);
export const selectIspAction = state => _.get(state, 'speeds.ispAction', ISP_ACTIONS.VIEW);

function processSpeedTestResult(payload) {
  const r = payload;
  const d = _.get(r, 'download', 0);
  const download = d > 0 ? (d / 1000).toFixed() : 0;
  const u = _.get(r, 'upload', 0);
  const upload = u > 0 ? (u / 1000).toFixed() : 0;
  const t = _.get(r, 'timestamp', undefined);
  let dateTime = '';
  if (t) {
    dateTime = moment(t).format('MMM D, YYYY hh:mm A');
  }
  return {
    download: download,
    upload: upload,
    dateTime: dateTime,
    latency: payload?.latency === 0 ? 0 : Math.round(payload?.latency * 10) / 10,
    ip: payload?.ip
  };
}
