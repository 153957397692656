import React, {useState} from 'react';
import microsoft from '../../../assets/images/img-oauth-login-icon-microsoft.png';
import {useSelector} from 'react-redux';
import {selectCustomerConfig} from '../_authSliceSelect';
import {addToast} from '../../common/toast';
import {doOauthLogin, OAuthType} from '../_oauth';

export const OAuthAzure = () => {
  const customerConfig = useSelector(selectCustomerConfig);
  const [loading, setLoading] = useState(false);
  function login() {
    if (!customerConfig || !customerConfig.realmId) {
      addToast('customer config not ready', 'info');
      return;
    }
    setLoading(true);
    doOauthLogin(OAuthType.AZURE, customerConfig.realmId, customerConfig);
  }
  return (
    <div>
      <div className='oauth' style={{ cursor: loading ? 'wait' : 'pointer' }}>
        <img alt='' src={microsoft} style={{ maxWidth: '100%' }} onClick={login} />
      </div>
    </div>
  );
};
