import React from "react";
import Modal from 'react-bootstrap/Modal'
import { Button } from "react-bootstrap";

export const ConfirmDialogVariants = Object.freeze({
  DELETE_CONFIRM: {
    buttons: [
      {
        variant: "secondary",
        text: "Cancel",
        result: false
      },
      {
        variant: "danger",
        text: "Yes",
        result: true
      }
    ]
  },
  OK_CONFIRM: {
    buttons: [
      {
        variant: "secondary",
        text: "Cancel",
        result: false
      },
      {
        variant: "primary",
        text: "OK",
        result: true
      }
    ]
  },
  CLOSE_ONLY: {
    buttons: [
      {
        variant: "primary",
        text: "Close",
        result: true
      }
    ]
  },
  OK_ONLY: {
    buttons: [
      {
        variant: "primary",
        text: "OK",
        result: true
      }
    ]
  }
});

export function ConfirmDialog({
  children,
  show,
  setShow,
  variant = "OK_CONFIRM",
  size = '',
  backdropClassName = 'modal-backdrop',
  contentClassName = '',
  bodyClassName = '',
  footerClassName = '',
  buttonClassName = '',
  callback
}) {

  const handleResult = res => evt => {
    evt.stopPropagation();
    evt.preventDefault();
    if (callback != null) {
      callback(res);
    }
  }

  let variantObj = variant;
  if (typeof (variant) === 'string') {
    variantObj = ConfirmDialogVariants[variant];
  }
  const { buttons: buttonsDef } = variantObj;

  const buttons = buttonsDef.map((btn, i) => (
    <Button key={i} variant={btn.variant} className={buttonClassName}
            onClick={handleResult(btn.result)}>{btn.text}</Button>
  ))

  return (
    <Modal contentClassName={contentClassName}
           backdropClassName={backdropClassName}
           show={show} onHide={() => {}} size={size} centered>
      <Modal.Body className={bodyClassName}>
        {children}
      </Modal.Body>

      <Modal.Footer className={(footerClassName == null || footerClassName === '') ? "justify-content-start" : footerClassName}>
        {buttons}
      </Modal.Footer>
    </Modal>
  );
}
