import { useState } from "react";

const delta = amount => key => state => {
  if (state[key] == null) {
    state[key] = 0;
  }
  state[key] = state[key] + amount;
  return state;
}

const increase = delta(1);
const decrease = delta(-1);

export function useRemoteDataAdapter({loader}) {
  const [pageIndex, setPageIndex] = useState(-1);
  const [loaded, setLoaded] = useState({});
  const [pageCount, setPageCount] = useState(1);

  const loadPage = async n => {
    if (!(n in loaded) || loaded[n] <= 0) {
      setLoaded(increase(n));
      const success = await loader(n);
      if (!success) {
        setLoaded(decrease(n));
      }
      return success;
    }
    return true;
  }

  const next = async () => {
    if (pageIndex >= pageCount) {
      // already reach the end, no need to load anything.
      return;
    }
    setPageIndex(it => {
      if (loadPage(it + 1)) {
        return it + 1;
      }
      return it;
    });
  }

  const hasMore = () => {
    return pageIndex + 1 < pageCount;
  }

  const resetPage = () => {
    setPageIndex(0);
    setLoaded({});
    // setPageCount(1);
  };

  return {
    next, setPageCount, hasMore, resetPage
  };
}