import React from "react";
import {useSelector} from "react-redux";
import SpeedTestLogo from "../../assets/speeds/speedtest-logo.png";
import SpeedTestGlobe from "../../assets/speeds/speedtest-globe.png";
import SpeedTestDownload from "../../assets/speeds/speedtest-upload.svg";
import SpeedTestUpload from "../../assets/speeds/speedtest-download.svg";
import SpeedTestLatency from "../../assets/speeds/speedtest-latency.svg";
import {SpeedTestButton} from "./SpeedTestButton";
import {selectLatestSpeedTestResult} from "./_speedsSlice";
import {SfFrame} from "../common/SfFrame";

export const SpeedTestResult = () => {
  const result = useSelector(selectLatestSpeedTestResult);
  return (
    <div>
      <div className='d-flex align-items-center'>
        <h3>
          Speed Check Results
        </h3>
        <div className='ml-5'>
          <img alt='' src={SpeedTestLogo}/>
        </div>
      </div>
      <div className='mt-4'>
        <SfFrame>
          <div className='p-8'>
            <div className='d-flex sf-text-secondary sf-font-body1 align-items-center'>
              <span>
                {result?.dateTime}
              </span>
              <img alt='' src={SpeedTestGlobe} className='ml-5'/>
              <span className='ml-3'>{result?.ip}</span>
            </div>
            <div className='mt-7'>
              <Result latency={result?.latency} download={result?.download} upload={result?.upload}/>
            </div>
            <div className='mt-7'>
              <h4>
                Your internet speed is fast.
              </h4>
              <div className='sf-text-secondary sf-font-body1 mt-4'>
                At this speed, you should be able to stream HD videos, hold video conferences, and play online games on multiple devices at
                the same time.
              </div>
            </div>
            <div className='mt-7'>
              <SpeedTestButton text='Start New Speedcheck'/>
            </div>
          </div>
        </SfFrame>
      </div>
    </div>
  );
}

const Result = ({latency, download, upload}) => {
  return (
    <div className='d-flex'>
      <div className='d-flex align-items-center'>
        <div className='mr-4'>
          <img alt='' src={SpeedTestLatency}/>
        </div>
        <h1>{latency}</h1>
        <div className='sf-text-f-body1 sf-text-f-body2 sf-text-c-secondary ml-4 mt-2'>Ping<br/>ms</div>
      </div>
      <div className='d-flex align-items-center ml-5'>
        <div className='mr-4'>
          <img alt='' src={SpeedTestDownload}/>
        </div>
        <h1>{download}</h1>
        <div className='sf-text-f-body1 sf-text-f-body2 sf-text-c-secondary ml-4 mt-2'>Download<br/>mbps</div>
      </div>
      <div className='d-flex align-items-center ml-5'>
        <div className='mr-4'>
          <img alt='' src={SpeedTestUpload}/>
        </div>
        <h1>{upload}</h1>
        <div className='sf-text-f-body1 sf-text-f-body2 sf-text-c-secondary ml-4 mt-2'>Upload<br/>mbps</div>
      </div>
    </div>
  );
}