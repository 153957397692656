import React from 'react';
import Button from 'react-bootstrap/Button';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowDown, faArrowUp} from '@fortawesome/free-solid-svg-icons';

export const InternetSpeed = ({
                                height = 288,
                                onClick = () => {
                                },
                                upload = 0,
                                download = 0,
                                time = '',
                                disabled
                              }) => {
  return (
    <div className='sf-home-card w-100 rounded d-flex flex-column justify-content-between p-6' style={{height: height}}>
      <div>
        <h4>Internet Speed</h4>
        {time && <div className='sf-text-f-body2 sf-text-c-secondary mt-4'>as of {time}</div>}
      </div>
      <div className='d-flex'>
        <div className='w-50 d-flex align-items-center'>
          <span className='mr-2' style={{color: '#557DE4', fontSize: '1rem'}}>
            <FontAwesomeIcon icon={faArrowDown}/>
          </span>
          <h1>{download}</h1>
          <div className='sf-text-f-body1 sf-text-f-body2 sf-text-c-secondary ml-2 mt-2'>Mbps</div>
        </div>
        <div className='w-50 d-flex align-items-center'>
          <span className='mr-2' style={{color: '#557DE4', fontSize: '1rem'}}>
            <FontAwesomeIcon icon={faArrowUp}/>
          </span>
          <h1>{upload}</h1>
          <div className='sf-text-f-body1 sf-text-f-body2 sf-text-c-secondary ml-2 mt-2'>Mbps</div>
        </div>
      </div>
      <div>
        <Button variant='primary' type='button' className='w-100' onClick={onClick} disabled={disabled}>
          Run Speed Test
        </Button>
      </div>
    </div>
  );
};
