import React, {useEffect, useState} from "react";
import {Button, Form} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import {useForm} from "react-hook-form";
import {superstructResolver} from "@hookform/resolvers/superstruct";
import _ from "lodash";
import {useDispatch, useSelector} from "react-redux";
import * as s from 'superstruct';
import {size, string} from 'superstruct';
import {unwrapResult} from "@reduxjs/toolkit";
import {createLogger} from "../common/util";
import {ISP_ACTIONS, selectIspInfo, selectIspList, startEditIspInfo, startFetchIspInfo, updateIspAction} from "./_speedsSlice";
import {selectViewNetworkId} from "../topology/_topologySlice";
import {coerceFormNumber, hasError} from "../common/validation";
import {addToast} from "../common/toast";
import {SfFormSelect} from "../form/SfFormSelect";

const logger = createLogger('ui:speeds:ispForm');

export const IspForm = () => {
  const ispInfo = useSelector(selectIspInfo);
  const dispatch = useDispatch();
  const ispList = useSelector(selectIspList);
  const networkId = useSelector(selectViewNetworkId);
  const [otherIspExternalId, setOtherIspExternalId] = useState();
  useEffect(() => {
    if (ispList.length > 0) {
      const otherIsp = ispList.find(i => i.type === 'Other');
      if (otherIsp) {
        setOtherIspExternalId(otherIsp.externalId);
      }
    }
  }, [ispList]);

  const schemaRegular = s.object({
    ispId: s.optional(s.string()),
    ispName: s.optional(s.string()),
    ispMaxSpeed: coerceFormNumber(s.number()),
  });

  const schemaOther = s.object({
    ispId: s.optional(s.string()),
    ispName: size(string(), 1, 100),
    ispMaxSpeed: coerceFormNumber(s.number()),
  });

  const dynamicSchema = s.dynamic(it => {
    if (otherIspExternalId === it.ispId) {
      return schemaOther;
    } else {
      return schemaRegular;
    }
  });

  const form = useForm({
    resolver: superstructResolver(dynamicSchema, {
      coerce: true
    }),
    defaultValues: {
      ispId: _.get(ispInfo, 'ispId'),
      ispName: _.get(ispInfo, 'ispName'),
      ispMaxSpeed: _.get(ispInfo, 'ispMaxSpeed'),
    }
  });

  const {register, handleSubmit, formState: {errors}, control, getValues, watch} = form;

  const watchIspId = watch('ispId');

  const formSubmitHandler = (data) => {
    logger('ISP form submit:', data);
    dispatch(startEditIspInfo({
      networkId,
      ispId: data.ispId,
      ispName: data.ispId === otherIspExternalId ? data.ispName : null,
      ispMaxSpeed: data.ispMaxSpeed
    }))
      .then(unwrapResult)
      .then(() => {
        dispatch(startFetchIspInfo({networkId}))
        addToast('Saved', 'success');
      })
      .catch((e) => {
        addToast(e.message, 'error');
      });

    dispatch(updateIspAction(ISP_ACTIONS.VIEW));
  }

  const onError = (error) => {
    logger(error, getValues());
  };
  return (
    <Form className="form-sf w-auto" onSubmit={handleSubmit(formSubmitHandler, onError)}>

      <Form.Group className="mt-5">
        <Form.Label>ISP</Form.Label>
        <SfFormSelect name="ispId" defaultValue="-- select an option --" control={control}
                      className={hasError(errors, `ISP`) ? "is-invalid" : ""}>
          {ispList.map((o, i) =>
            <Dropdown.Item key={i} eventKey={o.externalId}>{o.name}</Dropdown.Item>
          )}
        </SfFormSelect>
      </Form.Group>
      {
        watchIspId === otherIspExternalId &&
        <Form.Group className='mt-5'>
          <Form.Label>Other ISP</Form.Label>
          <Form.Control name="ispName" type="text" className={hasError(errors, `ispName`) ? "is-invalid" : ""}
                        {...register("ispName")}/>
        </Form.Group>
      }
      <div className='sf-font-body1 mt-5'>
        Enter the maximum speed you chose when you signed up for your internet plan. If we know what you’re paying for, we can help you get
        the most from your WiFi.
      </div>

      <Form.Group className="mt-5 position-relative">
        <Form.Label>Self-reported ISP download plan:</Form.Label>
        <Form.Control name="ispMaxSpeed" type="text" className={hasError(errors, `ispMaxSpeed`) ? "is-invalid" : ""}
                      {...register("ispMaxSpeed")}/>
        <div className='position-absolute' style={{right: '-50px', top: '41px'}}>
          Mbps
        </div>
      </Form.Group>

      <div className='mt-5 row'>
        <div className="col-6">
          <Button className="w-100"
                  variant="secondary"
                  onClick={e => {
                    e.preventDefault();
                    dispatch(updateIspAction(ISP_ACTIONS.VIEW));
                  }}> Cancel </Button>
        </div>
        <div className="col-6">
          <Button className="w-100" variant="primary" type="submit" size="xlg">Save</Button>
        </div>
      </div>
    </Form>
  );
};