const wordBeginRegex = /(^[a-z]| [a-z])/;

export function capitalize(s) {
  let pos = s.search(wordBeginRegex);
  let res = s;
  while (pos !== -1) {
    const ch = res[pos];
    if (ch === ' ') {
      pos = pos + 1
    }
    res = res.substring(0, pos) + res[pos].toUpperCase() + res.substring(pos + 1);

    pos = res.search(wordBeginRegex);
  }
  return res;
}

/**
 * Return the Initial of name.
 * For example: Hello World -> HW
 *              Hello World Marcus -> HWM
 * @param letters The maximal number of letters to return.
 * @param fullName
 * @returns {string}
 */
export const getInitials = function (letters, fullName) {
  const names = fullName.replace(/[^\d a-zA-Z]/g, '').split(' ');
  if (!(names instanceof Array) || names.length === 0) return '';

  let index = 0;
  let initials = '';
  while(index < names.length && index < letters) {
    initials = initials.concat(getInitial(names[index]));
    index++;
  }
  return initials;
};

const getInitial = function (name) {
  try {
    return name.substring(0, 1).toUpperCase();
  } catch (err) {
    return '';
  }
};

/**
 * Return hasCode by input string.
 * @param s
 */
export const hashCode = s => s.split('').reduce((a,b)=>{a=((a<<5)-a)+b.charCodeAt(0);return a&a},0)
