import {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {doUpdateFwUpdateProgress, startFetchFwUpdateInfo} from "./_firmwareUpdateSlice";
import {selectCustomerId} from "../auth/_authSliceSelect";
import {createLogger, LoadingState} from "../common/util";
import {useIoT, useIotMessageCallback} from "../iot/_iotHook";
import {addToast} from "../common/toast";
import { doUpdateNetworkDevicesLoadingState } from "../network/_networkSlice";

const logger = createLogger('ui:fwUpdate:iot');

export const FirmwareUpdateIoT = ({networkId}) => {
  const dispatch = useDispatch();
  const customerId = useSelector(selectCustomerId);
  // IoT
  const iotParam = useMemo(() => {
    const topic = `sf/org/${customerId}/net/${networkId}/status`;
    logger(`useIotMessageCallback, topic: ${topic}`);
    return {
      topic: topic,
      orgId: customerId,
      filter: (message) => {
        const event = _.get(message, 'payload.event');
        return event === 'FW_CHANGED';
      },
      callback: (message) => {
        const event = _.get(message, '[0].payload.event');
        if (event === 'FW_CHANGED') {
          const data = _.get(message, '[0].payload.data', {});
          const {step, percent} = data;
          dispatch(doUpdateFwUpdateProgress(data));
          // addToast(`Firmware Update: ${step} ${percent}`, 'info');
          logger(`Firmware Update: ${step} ${percent}`);
          if (percent === 100) {
            dispatch(doUpdateNetworkDevicesLoadingState(LoadingState.NONE))
            dispatch(startFetchFwUpdateInfo({networkId}));
            addToast(`Firmware Update is completed`, 'info');
          }
        }
        logger('IoT message received: ', message);
      }
    };
  }, [dispatch, customerId, networkId]);
  useIoT(iotParam);
  useIotMessageCallback(iotParam);
  return null;
}
