import React, {useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {FW_UPDATE_STATUS, selectFwUpdateStatus, startFetchFwUpdateInfo} from "./_firmwareUpdateSlice";
import {unwrapResult} from "@reduxjs/toolkit";
import {addToast} from "../common/toast";

export const ButtonCheckForUpdate = ({networkId}) => {
  const dispatch = useDispatch();
  const [checking, setChecking] = useState(true);
  const fwUpdateStatus = useSelector(selectFwUpdateStatus);

  useEffect(() => {
    setChecking(fwUpdateStatus === FW_UPDATE_STATUS.CHECKING);
  }, [fwUpdateStatus]);

  const onClickHandler = () => {
    dispatch(startFetchFwUpdateInfo({networkId: networkId}))
      .then(unwrapResult)
      .then(() => {
      })
      .catch((e) => {
        addToast(e.message, 'error');
      });
  };
  return (
    <>
      <Button variant='primary' className='sf-btn-w-xlg' type='button' onClick={onClickHandler}>
        {checking ?
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
          : 'Check For Updates'}
      </Button>
    </>
  );
};
