import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useConfirmDialog } from "../../common/hooks";
import { createLogger, LoadingState } from "../../common/util";
import { WebFilterContext } from "./_webfilterContext";
import {
  selectWebFilterEnabled,
  selectWebFilterVersion,
  startEnabledWebFilter,
  startGetDefaultCategories,
  startGetWebFilter,
  startPatchCategoryFilter,
  startPatchURLFilter
} from "../_securitySlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { addToast } from "../../common/toast";

const logger = createLogger("ui:security:webfilter:WebFilterDataProvider");

/**
 * The Data provider prepares the WebFilterContext for underlying view.
 * It also handles the data loading of both portals, so long as the domain and networkId are provided.
 *
 * Example:
 * ```
 *  <WebFilterDataProvider customerId={customerId} domain={domain} networkId={networkId}>
 *    <WebFilterView backLink={<GoBack className='mb-5'/>} appBodyHeight={`calc(${styles.appContentHeightBase} - 13rem)`}/>
 *  </WebFilterDataProvider>
 * ```
 *
 * @param children
 * @param customerId
 * @param domain
 * @param networkId
 * @param supportRating Should the component support rating group
 * @return {JSX.Element}
 * @constructor
 */
export function WebFilterDataProvider({
  children,
  customerId,
  domain = 'CORP',
  networkId = undefined,
  supportRating = false,
}) {
  const webFilterEnabled = useSelector(selectWebFilterEnabled);
  const version = useSelector(selectWebFilterVersion);
  const dispatch = useDispatch();
  const [initialized, setInitialized] = useState({
    state: LoadingState.NONE
  });

  const { dialog: ConflictDialog, showDialog: showConflictDialog } = useConfirmDialog({
    variant: {
      buttons: [{
        variant: "primary",
        text: "OK",
        result: true
      }]
    },
    dialogClassName: 'border shadow',
    dialogBodyClassName: 'pb-6',
    buttonClassName: 'sf-btn-w-default mr-4',
    size: ''
  });

  const doPrepare = useCallback(() => {
    return dispatch(startGetDefaultCategories())
      .then(unwrapResult)
      .catch((error) => {
        addToast('Error loading filter categories: ' + error.message, 'error');
      });
  }, []);

  const doFetch = useCallback(() => {
    return dispatch(startGetWebFilter({ customerId, domain, networkId }))
      .catch((error) => {
        addToast('Error loading web filter: ' + error.message, 'error');
      });
  }, [customerId, domain, networkId]);

  const showConflictDialogAndReload = useCallback(() => {
    showConflictDialog(() => (
      <>
        <h2 className="mb-4">Operation Conflict</h2>
        <p>There may be other users modify Web content filtering at the same time.</p>
      </>
    ))
      .then((res) => {
        logger("showVersionConflictDialogg response", res);
        if (res) {
          return doFetch()
            .then(() => {
              addToast('Data reloaded', 'success');
            })
            .catch((e) => {
              logger('error e=%o', e);
              addToast(e.message, 'error');
            });
        }
      });
  }, []);

  // Initialize the data
  useEffect(() => {
    setInitialized((x) => {
      if (x.state === LoadingState.NONE) {
        x.state = LoadingState.LOADING;
        Promise.all([
          doPrepare(), doFetch()
        ]).then(() => {
          x.state = LoadingState.LOADED;
        });
      }
      return x;
    })
  }, [customerId, dispatch, initialized]);

  const doUpdateStatus = useCallback((enabled) => {
    logger('changeEnableFilterByCategory: event.target.checked=%o', enabled);
    return dispatch(startEnabledWebFilter({
      customerId, domain, networkId, version, enabled
    })).then(unwrapResult);
  }, [customerId, domain, networkId, version]);

  const doUrlSubmit = useCallback(({ data }) => {
    return dispatch(startPatchURLFilter({
      customerId, domain, networkId, version,
      data,
    }))
      .then(unwrapResult);
  }, [customerId, domain, networkId, version]);

  const doCategorySubmit = useCallback((data) => {
    return dispatch(startPatchCategoryFilter({
      customerId, domain, networkId,
      version, data,
    })).then(unwrapResult);
  }, [customerId, domain, networkId, version]);

  return (
    <WebFilterContext.Provider value={{
      status: webFilterEnabled,
      version,
      // actions
      doPrepare, doFetch, doUpdateStatus,
      doCategorySubmit,
      doUrlSubmit,
      // component actions
      showConflictDialog,
      showConflictDialogAndReload,
      components: {
        ConflictDialog
      }
    }}>
      {children}
      {ConflictDialog}
    </WebFilterContext.Provider>
  );
}