import React from 'react';
import {useHistory} from 'react-router-dom';

export const LinkBasicLogin = () => {
  const history = useHistory();
  return (
    <div className='basic-login-link'>
      <a
        className='sf-text-primary sf-font-h5'
        href='#'
        onClick={(e) => {
          e.preventDefault();
          history.push('/auth/login');
        }}
      >
        Back to Login
      </a>
    </div>
  );
};
