import {slice} from "./_firmwareUpdateSlice";

export {ButtonFwUpdate} from "./ButtonFwUpdate";
export {ButtonCheckForUpdate} from "./ButtonCheckForUpdate";
export {FirmwareMessage} from "./FirmwareMessage";
export {FirmwareUpdateDialog} from "./FirmwareUpdateDialog";
export {FirmwareUpdateIoT} from "./FirmwareUpdateIoT";
export {NodeList} from "./NodeList";
export {FirmwareUpdateView} from "./FirmwareUpdateView";
export {
  startFetchFwUpdateInfo,
  startFetchSingleFwUpdateInfo,
  selectFwUpdateDownloadUrl,
  selectFwUpdateStatus,
  doUpdateFwUpdateProgress,
  doClearSystemSettingsData,
  FW_UPDATE_STATUS
} from "./_firmwareUpdateSlice";

export const fwUpdateReducer = slice.reducer;