import React, { useContext, useState } from "react";
import { Form, Tab, Tabs } from 'react-bootstrap';
import { createLogger } from "../../common/util";
import { addToast } from "../../common/toast";
import { WebFilterContext } from "./_webfilterContext";
import { FilterByCategoryView } from "./FilterByCategoryView";
import { FilterByURLView } from "./FilterByURLView";

const logger = createLogger('ui:security:webfilter:WebFilterView');

/**
 * @callback WebFilterView~actionBuilder
 * @param obj {object}
 * @param obj.feature {category|url} Current feature
 * @param obj.setModified {function} Manipulate modified flag
 * @param obj.context The entire context data
 * @param obj.categoryData
 * @param obj.urlData
 */
/**
 * @callback WebFilterView~contentBuilder
 * @param obj {object}
 * @param obj.context {WebFilterContext} The entire context data
 * @param obj.categoryData
 */
/**
 *
 * @param className
 * @param backLink
 * @param appBodyHeight
 * @param categoryLeadContentBuilder {WebFilterView~contentBuilder} The function to build the lead content
 * @param actionBuilder {WebFilterView~actionBuilder} The function to build action block when data is modified. Not supported yet
 * @return {JSX.Element}
 * @constructor
 */
export function WebFilterView({className, backLink, appBodyHeight, categoryLeadContentBuilder, actionBuilder}) {
  const {
    status: webFilterEnabled,
    // actions
    doUpdateStatus,
    // component actions
    showConflictDialogAndReload,
  } = useContext(WebFilterContext);
  const [tabKey, setTabKey] = useState('category');
  const modifiedState = useState(false);

  const changeEnableWebFilter = (event) => {
    logger('changeEnableFilterByCategory: event.target.checked=%o', event.target.checked);
    doUpdateStatus(event.target.checked)
      .then(resp => {
        addToast('change enabled state successfully', 'success');
      })
      .catch((error) => {
        if (error.code != null && error.code === "ERR_CODE_MODEL_VERSION_CONFLICT") {
          showConflictDialogAndReload();
        } else {
          if (error.code != null) {
            addToast('change enabled state failed: ' + error.code, 'error');
          } else {
            addToast('change enabled state failed: ' + error.message, 'error');
          }
        }
      });
  };

  return (
    <>
      <div className={`app-header ${className || ""}`}>
        {backLink}
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h1>Web Content Filtering</h1>
          <Form.Check type="switch" id="category-switch" onChange={changeEnableWebFilter} checked={webFilterEnabled || false} />
        </div>

        <div className='sf-text-f-body1 sf-text-c-secondary'>
          Restrict or control user access to the internet with broad (category) or precise (URL) filters.
        </div>
      </div>
      <div className={`app-body ${className || ""}`}
           style={{height: appBodyHeight}}
           // style={{ height: `calc(${styles.appContentHeightBase} - 13rem)` }}
      >

        <Tabs id="webFiltering-tab"
              defaultActiveKey="category" variant="pills"
              activeKey={tabKey} style={{ minWidth: 704, width: 704 }}
              onSelect={(k) => setTabKey(k)}>

          <Tab eventKey="category" title="By Category">
            {tabKey === "category" &&
              <div className="mt-6">
                <FilterByCategoryView leadContentBuilder={categoryLeadContentBuilder} modifiedState={modifiedState}/>
              </div>
            }
          </Tab>
          <Tab eventKey="url" title="By URL">
            {tabKey === "url" &&
              <div className="mt-6">
                 <FilterByURLView modifiedState={modifiedState}/>
              </div>
            }
          </Tab>
        </Tabs>
      </div>
    </>
  );
}