import React, {useEffect, useState} from 'react';
import {Redirect, Route} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {selectToken, selectUserRoles} from '../_authSliceSelect';
import {AccessDenied} from './AccessDenied';
import {hasPermission} from './PermissionUtils';
import {debug} from 'debug';

const logger = debug('sf:ui:GuardedRoute:trace');

/**
 * Guard the route by checking privileges.
 *
 * - If the user has no credentials stored, go to login page.
 * - If the user do not have the specified privilege, go to home page.
 * - Otherwise display the requested component.
 */
export function GuardedRoute({ component: Component, roles: reqRoles, ...rest }) {
  const userRoles = useSelector(selectUserRoles);
  const [isAllowed, setIsAllowed] = useState(reqRoles === undefined || hasPermission(reqRoles));
  const token = useSelector(selectToken);
  useEffect(() => {
    setIsAllowed(reqRoles === undefined || hasPermission(reqRoles));
  }, [userRoles]);
  logger(`Watch GuardedRoute, component: ${Component?.name} hasToken: ${token != null} isAllowed: ${isAllowed}`);
  return (
    <Route
      {...rest}
      render={(props) => (token == null ? <Redirect to='/auth/login' /> : isAllowed ? <Component {...props} /> : <AccessDenied />)}
    />
  );
}
