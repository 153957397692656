import React from 'react';
import {Redirect, Route, Switch, useRouteMatch} from 'react-router-dom';
import {LoginPage} from './login/LoginPage';
import {MfaValidatePage} from './login/MfaValidatePage';
import {CreatePasswordPage} from './password/CreatePasswordPage';
import {RedirectPage} from './login/RedirectPage';
import {ResetPasswordPage} from './password/ResetPasswordPage';
import {useDispatch} from 'react-redux';
import debug from 'debug';
import {setDisplayCompanyLogo} from "./_authSlice";

const logger = debug('sf:ui:authContainer');

export const AuthContainer = ({ className, displayCompanyLogo = false }) => {
  initialize({ displayCompanyLogo });
  const match = useRouteMatch();
  return (
    <div className={className + ' auth'}>
      <Switch>
        <Route exact path={`${match.path}/mfa`} component={MfaValidatePage} />
        <Route exact path={`${match.path}/login`} component={LoginPage} />
        <Route exact path={`${match.path}/create-password`} component={CreatePasswordPage} />
        <Route exact path={`${match.path}/redirect`} component={RedirectPage} />
        <Route exact path={`${match.path}/reset-password`} component={ResetPasswordPage} />
        <Route>
          <Redirect to={`${match.path}/login`} />
        </Route>
      </Switch>
    </div>
  );
};

export const initialize = ({ displayCompanyLogo }) => {
  logger('displayCompanyLogo: %o', displayCompanyLogo);
  const dispatch = useDispatch();
  dispatch(setDisplayCompanyLogo(displayCompanyLogo));
};
