import {envClientTypeId} from './env';
import debug from 'debug';
import {selectCustomerConfig, selectToken} from '../auth/_authSliceSelect';
import {useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import _ from 'lodash';

const logger = debug('sf:ui:util');

export const LoadingState = Object.freeze({
  NONE: 0,
  LOADING: 1,
  LOADED: 2
});

/**
 * Compose url from base and an object represent query parameters.
 * The function will handle the uri encode.
 *
 * @param base {string} The base url without any query parameters.
 * @param params {object<string,string>} The query parameters, key as the parameter name and value as the value.
 * @returns {string}
 */
export function composeUrl(base, params) {
  if (params == null) {
    return base;
  }
  const qs = Object.keys(params)
    .filter((k) => {
      // filter out those has no value
      return params[k] != null;
    })
    .map((k) => {
      const v = params[k];
      return `${encodeURIComponent(k)}=${encodeURIComponent(v)}`;
    })
    .join('&');

  if (qs == null || qs.length <= 0) {
    return base;
  } else {
    return `${base}?${qs}`;
  }
}

export function composeCommonHeaders(options) {
  if (options == null) {
    return {};
  }
  const {realmId, token, contentType} = options;
  const base = {
    'X-SMB-Client-Type-Id': envClientTypeId
  };
  if (realmId != null) {
    Object.assign(base, {
      'X-SMB-Realm-Id': realmId
    });
  }
  if (token != null) {
    Object.assign(base, {
      Authorization: `Bearer ${token}`
    });
  }

  if (contentType != null) {
    Object.assign(base, {
      'Content-Type': contentType
    });
  } else {
    Object.assign(base, {
      'Content-Type': 'application/json'
    });
  }
  return base;
}

export const removeBlank = (data) => {
  const toRemove = Object.keys(data).filter((k) => {
    return data[k] == null || data[k] === '';
  });
  toRemove.forEach((k) => delete data[k]);
  return data;
};

/**
 * Return the security options common to all API call.
 * This includes the token.
 */
export const composeCommonApiOptions = (state) => {
  const token = selectToken(state);
  const {realmId} = selectCustomerConfig(state);
  logger('[composeCommonApiOptions] token, realmId:', token, realmId);
  return {
    token: token && token.access_token,
    realmId
  };
};

export const unwrapWithPayload = (data) => {
  logger('[unwrapWithError]', data.payload, data.error);
  if (data && data.type && data.type.endsWith('/rejected')) {
    throw data.payload || data.error;
  } else {
    return data.payload;
  }
};

export async function handleAjaxWithoutBody(resp) {
  logger('Handle ajax resp without body: %o', resp);
  const {ok, status} = resp;
  return {
    ok,
    status
  };
}

/**
 * Compose 2 predicates into 1
 * @param p1 Predicate 1
 * @param p2 Predicate 2
 */
export function and(p1, p2) {
  return function (it) {
    return p1(it) && p2(it);
  };
}

debug.format = '%{H:M-Z} %n %m %+';

export function createLogger(name) {
  return debug(`sf:${name}`);
}

export function evaluateByPath(it, path) {
  return path
    .split(/[,[\].]+?/)
    .filter(Boolean)
    .reduce((result, key) => (result == null ? result : result[key]), it);
}

/**
 * Create a promise that simply wait for millis and return the specified value.
 *
 * @param millis
 * @param value
 * @returns {Promise<unknown>}
 */
export function delayed(millis, value) {
  return new Promise(function (resolve) {
    setTimeout(resolve.bind(null, value), millis);
  });
}

export function useConstructor(cb = () => {
}) {
  const [called, setCalled] = useState(false);
  if (!called) {
    cb();
    setCalled(true);
  }
}

export function isEmpty(it) {
  return it == null || Object.keys(it).length <= 0;
}

/**
 * Use router to match the path and extract everything after the given segments.
 *
 * @param segments Array of path segments. Ex: ["lan", "info"] will translate to /lan/info
 * @returns {string|undefined} The extracted segment or undefined.
 */
export function useRouteSection(segments) {
  const pathSegments = (segments || []).join('/');
  const match = useRouteMatch(`/${pathSegments}/:section`);
  let section;
  if (match != null) {
    section = match?.params?.section;
  }
  return section;
}

/**
 * This allows us to use the below syntax to quickly format values
 *
 * ```
 * const toBar = value => {
 *   return value * 10;
 * };
 *
 * pipe(foo, toBar)
 * ```
 */
export const pipe = (value, ...functions) => {
  return (functions || []).reduce((all, func) => {
    return func(all);
  }, value);
};

export const passThrough = (it) => it;

/**
 * Get breakdown information of current URL
 *
 * Browser must support URL class, otherwise we need proper polyfill
 *
 * @return {{protocol: string, hostname: string, port: string, pathname: string}}
 */
export function getCurrentUrl() {
  const {protocol, hostname, port, pathname} = new URL(window.location.href);
  return {protocol, hostname, port, pathname};
}

export function wait(millis) {
  return new Promise((resolve) => {
    setTimeout(resolve, millis);
  });
}

export function timeout(millis) {
  return new Promise((resolve, reject) => {
    setTimeout(reject, millis);
  });
}

/**
 * Extract error code and error message from error
 * @param error
 * @returns {{errCode: string, errMsg: string}}
 */
export function extractErrorCodeMessage(error) {
  const errCode = _.get(error, 'response.data.errors[0].code', '');
  const errMsg = _.get(error, 'response.data.errors[0].message', '');
  return {errCode, errMsg};
}

export function unwrapErrorResponse(error) {
  const e = _.get(error, 'response.data.errors[0]', {});
  if (e) {
    e.status = _.get(error, 'response.status');
  }
  return e;
}

export function isValidUrl(s) {
  let url;
  try {
    url = new URL(s);
  } catch (e) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
}

const decoder = new TextDecoder('utf-8');

/**
 * Decode unit 8 array to string.
 * @param string
 * @returns {string}
 */
export function unit8ArrayDecoder(string) {
  return decoder.decode(string);
}

/**
 * Parse json string to Json object.
 * @param string
 * @returns {any}
 */
export function parseJson(string) {
  try {
    return JSON.parse(string);
  } catch (e) {
    return JSON.parse(removeEscapeSlashes(string));
  }
}

function removeEscapeSlashes(string) {
  let parsedString = string.replace(/(^|[^\\])(\\\\)*\\$/, '$&\\');
  parsedString = parsedString.replace(/(^|[^\\])((\\\\)*")/g, '$1\\$2');
  try {
    parsedString = JSON.parse(`"${parsedString}"`);
  } catch (e) {
    return string;
  }
  return parsedString;
}

/**
 * Convert topic to Regular expression
 * @param topic
 * @returns {RegExp}
 */
export function topicToRegex(topic) {
  // sf\/org\/.*\/import\/device\/.*
  const expression = topic.replaceAll('+', '.*').replaceAll('/', '\\/');
  return new RegExp(expression);
}

export const pathMatching = ({matchType = 'prefix', paths, currentPath}) => {
  if (paths == null) {
    return false;
  }
  switch (matchType) {
    case 'full':
      return (paths || []).includes(currentPath);
    case 'prefix':
      return (paths || []).find(it => currentPath.startsWith(it)) != null;
    case 'regex':
      return (paths || []).find(it => new RegExp(it).test(currentPath)) != null;
  }
  return false;
}

/**
 * A function to build a key using current timestamp
 *
 * @param prefix
 * @return {function(): string}
 */
export const keyBuilder = (prefix) => () => `${prefix}_${new Date().getTime()}`;

/**
 * A place holder function that does nothing.
 */
export const dummyFunc = () => {
};