import {composeUrl, createLogger, LoadingState, unwrapErrorResponse} from "../common/util";
import {envBaseApiUrl} from "../common/env";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getAxiosInstance as axios} from '../auth/axios/axiosCommon';
import _ from "lodash";

const logger = createLogger('ui:topology:reducer');

export const startFetchTopology = createAsyncThunk(
  'topology/fetchTopology',
  async (input, {rejectWithValue, getState, dispatch}) => {
    logger('[FetchTopology] begin: %o', input);
    // https://sf-dev-api.velopcloud.com/network-delegation-service/rest/networks/F2BAE800-DAFC-4BC2-8527-797950B8249A/topology
    const {networkId} = input;
    const url = `${envBaseApiUrl}/network-delegation-service/rest/networks/${networkId}/topology`;

    return axios().get(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        const errorResp = unwrapErrorResponse(error);
        throw errorResp;
      });
  }
);

export const startMonitorWanStats = createAsyncThunk(
  'topology/monitorWanStats',
  async (input, {rejectWithValue, getState, dispatch}) => {
    logger('[MonitorWanStats] begin: %o', input);
    const {networkId} = input;
    const callbackId = getState().topology.callbackId;
    const url = composeUrl(`${envBaseApiUrl}/network-service/rest/networks/${networkId}/wan-stats`, {
      callbackId: callbackId
    });
    return axios().post(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        const errorResp = unwrapErrorResponse(error);
        throw errorResp;
      });
  }
);

export const startCancelMonitorWanStats = createAsyncThunk(
  'topology/cancelMonitorWanStats',
  async (input, {rejectWithValue, getState, dispatch}) => {
    const callbackId = getState().topology.callbackId;
    if (callbackId === '') {
      return null;
    }
    logger('[cancelMonitorWanStats] begin: %o', input);
    const {networkId} = input;

    const url = `${envBaseApiUrl}/network-service/rest/networks/${networkId}/wan-stats/${callbackId}`;
    return axios().delete(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        const errorResp = unwrapErrorResponse(error);
        throw errorResp;
      });
  }
);

const startMonitorVpnStats = createAsyncThunk(
  'topology/monitorVpnStats',
  async (input, {rejectWithValue, getState, dispatch}) => {
    logger('[MonitorVpnStats] begin: %o', input);
    const {networkId} = input;
    const vpnCallbackId = getState().topology.vpnCallbackId;
    const url = composeUrl(`${envBaseApiUrl}/network-service/rest/networks/${networkId}/vpn-stats`, {
      callbackId: vpnCallbackId
    });
    return axios().post(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        const errorResp = unwrapErrorResponse(error);
        throw errorResp;
      });
  }
);

const startCancelMonitorVpnStats = createAsyncThunk(
  'topology/cancelMonitorVpnStats',
  async (input, {rejectWithValue, getState, dispatch}) => {
    const vpnCallbackId = getState().topology.vpnCallbackId;
    if (vpnCallbackId === '') {
      return null;
    }
    logger('[cancelMonitorVpnStats] begin: %o', input);
    const {networkId} = input;

    const url = `${envBaseApiUrl}/network-service/rest/networks/${networkId}/vpn-stats/${vpnCallbackId}`;
    return axios.delete(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        const errorResp = unwrapErrorResponse(error);
        throw errorResp;
      });
  }
);

export const slice = createSlice({
  name: 'topology',
  initialState: {
    networkId: null,
    bandwidth: {
      elementsCount: 13,
      wan: [],
      vpn: []
    },
    callbackId: '',
    vpnCallbackId: '',
    speedTestResult: {
      download: 0,
      upload: 0,
      time: ''
    }
  },
  reducers: {
    setViewNetworkId: (state, action) => {
      state.networkId = action.payload;
    },
    updateTopology: (state, action) => {
      logger("Update topology: %o", action?.payload);
      state.topology = action?.payload;
    },
    clearViewHomeData: (state, action) => {
      state.topology = undefined;
      state.bandwidth.wan = [];
      state.bandwidth.vpn = [];
      state.callbackId = '';
      state.vpnCallbackId = '';
    },
    updateVpnBandwidth: (state, action) => {
      const array = [...state.bandwidth.vpn];
      if (action.payload) {
        array.push(action.payload);
      }
      // keep x elements
      const elementsCount = state.bandwidth.elementsCount + 1;
      state.bandwidth.vpn = array.slice(-1 * elementsCount);
    },
    updateWanBandwidth: (state, action) => {
      const array = [...state.bandwidth.wan];
      if (action.payload) {
        array.push(action.payload);
      }
      // keep x elements
      const elementsCount = state.bandwidth.elementsCount + 1;
      state.bandwidth.wan = array.slice(-1 * elementsCount);
    },
    syncTopologyClientsCount: (state, action) => {
      const clientsCount = action.payload;
      setTopologyClientsCount(state.topology.root, clientsCount);
    },
  },
  extraReducers: {
    [startFetchTopology.pending]: (state, action) => {
      state.topologyLoadingState = LoadingState.LOADING;
      logger("startFetchTopology.pending: %o, %o", state, action);
    },
    [startFetchTopology.fulfilled]: (state, action) => {
      logger("startFetchTopology.fulfilled: %o, %o", state, action);
      if (action.payload) {
        state.topology = action.payload;
      }
      state.topologyLoadingState = LoadingState.LOADED;
    },
    [startFetchTopology.rejected]: (state, action) => {
      logger("startFetchTopology.rejected: %o, %o", state, action);
      state.topologyLoadingState = LoadingState.LOADED;
    },
    [startMonitorWanStats.fulfilled]: (state, action) => {
      logger("startMonitorWanStats.fulfilled: %o, %o", state, action);
      if (action.payload) {
        state.callbackId = action.payload.callbackId;
      }
    },
    [startMonitorWanStats.rejected]: (state, action) => {
      logger("startMonitorWanStats.rejected: %o, %o", state, action);
      state.callbackId = '';
    },
    [startMonitorVpnStats.fulfilled]: (state, action) => {
      logger("startMonitorVpnStats.fulfilled: %o, %o", state, action);
      if (action.payload) {
        state.vpnCallbackId = action.payload.callbackId;
      }
    },
    [startMonitorVpnStats.rejected]: (state, action) => {
      logger("startMonitorVpnStats.rejected: %o, %o", state, action);
      state.vpnCallbackId = '';
    },
    [startCancelMonitorWanStats.fulfilled]: (state, action) => {
      logger("startCancelMonitorWanStats.fulfilled: %o, %o", state, action);
      state.callbackId = '';
    },
    [startCancelMonitorWanStats.rejected]: (state, action) => {
      logger("startCancelMonitorWanStats.rejected: %o, %o", state, action);
      state.callbackId = '';
    },
    [startCancelMonitorVpnStats.fulfilled]: (state, action) => {
      logger("startCancelMonitorVpnStats.fulfilled: %o, %o", state, action);
      state.vpnCallbackId = '';
    },
    [startCancelMonitorVpnStats.rejected]: (state, action) => {
      logger("startCancelMonitorVpnStats.rejected: %o, %o", state, action);
      state.vpnCallbackId = '';
    }
  }
});

export const {
  clearViewHomeData: doClearViewHomeData,
  updateVpnBandwidth: doUpdateVpnBandwidth,
  updateWanBandwidth: doUpdateWanBandwidth,
  updateTopology: doUpdateTopology,
  setViewNetworkId: doSetViewNetworkId,
  syncTopologyClientsCount: doSyncTopologyClientsCount
} = slice.actions;

export const selectViewTopologyLoadingState = state => _.get(state, 'topology.topologyLoadingState', LoadingState.NONE);
export const selectViewNetworkId = state => _.get(state, 'topology.networkId');
export const selectViewTopology = state => _.get(state, 'topology.topology');
export const selectViewVpnBandwidth = state => _.get(state, 'topology.bandwidth.vpn');
export const selectViewWanBandwidth = state => _.get(state, 'topology.bandwidth.wan');
export const selectViewSpeedTestResult = state => _.get(state, 'topology.speedTestResult');

function setTopologyClientsCount(element, clientsCount) {
  const {id} = element;
  element.count = 0;
  const device = clientsCount.find(d => d.deviceId === id);
  if (device) {
    element.count = device.count;
  }
  if (element.children != null) {
    let i;
    let result = null;
    for (i = 0; result == null && i < element.children.length; i++) {
      result = setTopologyClientsCount(element.children[i], clientsCount);
    }
  }
}