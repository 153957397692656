import React from 'react';
import { useSelector } from 'react-redux';
import { Tab, Tabs } from 'react-bootstrap';
import { selectDevicesCount, selectDevicesLoadingState } from './_devicesSlice';
import { DeviceList } from './DeviceList';
import { LoadingState } from "../common/util";
import { LoadingOverlay } from "../loader/LoadingOverlay";

export function DevicesTabs({ network, onlineClients, offlineClients}) {
  const deviceCounts = useSelector(selectDevicesCount);
  const devicesLoadingState = useSelector(selectDevicesLoadingState);

  return (
    <>
      {
        devicesLoadingState !== LoadingState.LOADED ?
        <LoadingOverlay />
        :
        <Tabs defaultActiveKey="online" variant="pills" id="devices-tab" className="sf-tab-2-full-width mt-2">
          <Tab eventKey="online" title={`Online (${deviceCounts?.onlineCount})`}>
            <DeviceList status="online" networkId={network?.id} devices={onlineClients}/>
          </Tab>
          <Tab eventKey="offline" title={`Offline (${deviceCounts?.offlineCount})`}>
            <DeviceList status="offline" networkId={network?.id} devices={offlineClients}/>
          </Tab>
        </Tabs>
      }
    </>
  );
}
