import React, {useState} from 'react';
import {CenterContent} from '../CenterContent';
import {useProfile} from '../_authHooks';
import {addToast} from '../../common/toast';
import {useDispatch} from 'react-redux';
import {useForm} from 'react-hook-form';
import * as s from 'superstruct';
import {size, string} from 'superstruct';
import {superstructResolver} from '@hookform/resolvers/superstruct';
import {startChangePassword, startLogout, startValidatePassword} from '../_authSlice';
import {unwrapResult} from '@reduxjs/toolkit';
import _ from 'lodash';
import {createLogger} from '../../common/util';
import {Button, Form, FormControl} from 'react-bootstrap';
import {hasError} from '../../common/validation';
import {LinkForgotPassword} from "./LinkForgotPassword";
import {useHistory} from "react-router-dom";

const logger = createLogger('ui:changePassword');

export const ChangePasswordPage = ({ callBack, contentClassName }) => {
  return (
    <CenterContent className={contentClassName}>
      <ChangePasswordForm callBack={callBack} />
    </CenterContent>
  );
};

const ChangePasswordForm = ({ callBack }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentPasswordErrorMessage, setCurrentPasswordErrorMessage] = useState('');
  const [newPasswordErrorMessage, setNewPasswordErrorMessage] = useState('');
  const profile = useProfile();
  const email = _.get(profile, 'username');
  const schema = s.object({
    current_password: size(string(), 10, 60),
    new_password: size(string(), 10, 60),
    password_confirm: size(string(), 10, 60)
  });
  const onSubmit = (data) => {
    setCurrentPasswordErrorMessage('');
    setNewPasswordErrorMessage('');
    if (data.new_password !== data.password_confirm) {
      setNewPasswordErrorMessage('New passwords don’t match');
      return;
    }
    logger('Validate current password');
    dispatch(
      startValidatePassword({
        currentPassword: data.current_password
      })
    )
      .then(unwrapResult)
      .then((resp) => {
        doChangePassword({ token: resp.token, newPassword: data.new_password, email });
      })
      .catch((error) => {
        if (error.code === 'ERR_CODE_INVALID_CREDENTIALS') {
          setCurrentPasswordErrorMessage('Current password incorrect');
        } else {
          // Unknown code
          addToast(error.message, 'error');
        }
      });
  };

  const doChangePassword = ({ token, newPassword, email }) => {
    logger('Change password:', token, email);
    dispatch(
      startChangePassword({
        token,
        newPassword,
        email
      })
    )
      .then(unwrapResult)
      .then(() => {
        // execute callback function;
        callBack();
      })
      .then(() => startLogout({dispatch, history}))
      .catch((error) => {
        addToast(error.message, 'error');
      });
  };

  const onError = () => {};

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: superstructResolver(schema, {
      coerce: true
    })
  });

  return (
    <div className='change-password'>
      <section className='mb-9'>
        <h1>Change Password</h1>
      </section>

      <Form onSubmit={handleSubmit(onSubmit, onError)}>
        <section className='mb-8'>
          <div className='mb-3 sf-font-h5 sf-text-c-red'>{currentPasswordErrorMessage}</div>
          <Form.Group controlId='formCurrentPassword'>
            <Form.Label>Current Password</Form.Label>
            <FormControl
              type='password'
              placeholder=''
              name='current_password'
              {...register('current_password')}
              className={hasError(errors, `current_password`) ? 'is-invalid shake' : ''}
            />
          </Form.Group>
          <LinkForgotPassword />
        </section>
        <section className='mb-6'>
          <div className='mb-3 sf-font-h5 sf-text-c-red'>{newPasswordErrorMessage}</div>
          <Form.Group controlId='formNewPassword'>
            <Form.Label>New Password</Form.Label>
            <FormControl
              type='password'
              placeholder=''
              name='new_password'
              {...register('new_password')}
              className={hasError(errors, `new_password`) ? 'is-invalid shake' : ''}
            />
          </Form.Group>
          <Form.Group controlId='formPasswordConfirm'>
            <Form.Label>Confirm New Password</Form.Label>
            <FormControl
              type='password'
              placeholder=''
              name='password_confirm'
              {...register('password_confirm')}
              className={hasError(errors, `password_confirm`) ? 'is-invalid shake' : ''}
            />
          </Form.Group>
        </section>
        <section>
          <div className='row mt-6'>
            <div className='col-12'>
              <Button variant='primary' className='sf-btn-w-xlg' type='submit'>
                Update Password
              </Button>
            </div>
          </div>
        </section>
      </Form>
    </div>
  );
};
