import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getAxiosInstance as axios} from '../auth/axios/axiosCommon';
import _ from "lodash";
import {composeUrl, createLogger, LoadingState, unwrapErrorResponse} from "../common/util";
import {envBaseApiUrl} from "../common/env";

const logger = createLogger('ui:fwUpdate:slice');

export const FW_UPDATE_STATUS = Object.freeze({
  NONE: 0,
  CHECKING: 1,
  UPDATE_WAS_FOUND: 2,
  UP_TO_DATE: 3
});


export const startFetchFwUpdateInfo = createAsyncThunk(
  'fwUpdate/fetchFetchNetworkFwUpdateInfo',
  async (input, thunkAPI) => {
    logger('[fetchNetworkDevices] begin: %o', input);
    const {networkId} = input;
    const url = composeUrl(`${envBaseApiUrl}/network-service/rest/networks/${networkId}/fwupdates`);

    return axios().get(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw unwrapErrorResponse(error);
      })
  }
);

export const startFetchSingleFwUpdateInfo = createAsyncThunk(
  'fwUpdate/startFetchSingleFwUpdateInfo',
  async (input, thunkAPI) => {
    logger('[startFetchSingleFwUpdateInfo] begin: %o', input);
    const {networkId, deviceId = 'ROOT'} = input;
    const url = composeUrl(`${envBaseApiUrl}/network-service/rest/networks/${networkId}/fwupdates/nodes/${deviceId}`);

    return axios().get(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw unwrapErrorResponse(error);
      })
  }
);

export const slice = createSlice({
  name: 'fwUpdate',
  initialState: {
    devicesLoadingState: LoadingState.NONE,
    fwUpdate: {
      status: FW_UPDATE_STATUS.NONE
    }
  },
  reducers: {
    clearSystemSettingsData: (state) => {
      state.devicesLoadingState = LoadingState.NONE;
      state.fwUpdate = {status: FW_UPDATE_STATUS.NONE};
      state.devices = []
    },
    updateFwUpdateStatus: (state, action) => {
      state.fwUpdate.status = action.payload;
    },
    checkFwVersion: (state, action) => {
      const versionArray = action.payload || [];
      const devices = state.devices;
      let isAllNew = true;
      devices.forEach((d, index) => {
        const {macAddress, currentFwVersion} = d;
        const v = versionArray.find(v => v.macAddress === macAddress);
        const {availableFwVersion, downloadUrl} = v;
        if (currentFwVersion !== availableFwVersion) {
          isAllNew = false;
          state.devices[index].availableFwVersion = availableFwVersion;
          state.fwUpdate.status = FW_UPDATE_STATUS.UPDATE_WAS_FOUND;
          state.fwUpdate.downloadUrl = downloadUrl;
        }
      });

      if (isAllNew) {
        state.fwUpdate.status = FW_UPDATE_STATUS.UP_TO_DATE;
      }
    },
    updateFwUpdateProgress: (state, action) => {
      state.fwUpdate.progress = action.payload;
    },
  },
  extraReducers: {
    [startFetchFwUpdateInfo.pending]: (state, action) => {
      logger("startFetchFwUpdateInfo.pending: %o, %o", state, action);
      state.fwUpdate.status = FW_UPDATE_STATUS.CHECKING;
    },
    [startFetchFwUpdateInfo.fulfilled]: (state, action) => {
      logger("startFetchFwUpdateInfo.fulfilled: %o, %o", state, action);
      return slice.caseReducers.checkFwVersion(state, action);
    },
    [startFetchFwUpdateInfo.rejected]: (state, action) => {
      logger("startFetchFwUpdateInfo.rejected: %o, %o", state, action);
      state.fwUpdate.status = FW_UPDATE_STATUS.NONE;
    },
  }
});

export const {
  clearSystemSettingsData: doClearSystemSettingsData,
  updateFwUpdateProgress: doUpdateFwUpdateProgress,
  updateFwUpdateStatus: doUpdateFwUpdateStatus
} = slice.actions;

export const selectFwUpdateStatus = state => _.get(state, 'fwUpdate.fwUpdate.status', FW_UPDATE_STATUS.NONE);
export const selectFwUpdateDownloadUrl = state => _.get(state, 'fwUpdate.fwUpdate.downloadUrl', FW_UPDATE_STATUS.NONE);
