import React from 'react';
import {OAuthAzure} from './OAuthAzure';
import {doUpdateLoginPolicy} from '../_authSlice';
import {useDispatch, useSelector} from 'react-redux';
import {selectHasSmbProvider} from '../_authSliceSelect';

export const OAuthLoginPage = () => {
  return (
    <div>
      <OAuthLogin />
    </div>
  );
};

const OAuthLogin = () => {
  return (
    <div className='d-flex flex-column mb-2'>
      <h2>Business Log in</h2>
      <div className='mt-5'>
        <OAuthAzure />
      </div>
      <div className='mt-5 forgot-password-div d-flex justify-content-between'>
        <LinkSMB />
      </div>
    </div>
  );
};

const LinkSMB = () => {
  const hasSmbProvider = useSelector(selectHasSmbProvider);
  const dispatch = useDispatch();
  if (hasSmbProvider) {
    return (
      <div>
        <a
          href='#'
          className='sf-text-primary sf-font-h5'
          onClick={(e) => {
            e.preventDefault();
            dispatch(doUpdateLoginPolicy('SMB'));
          }}
        >
          Login via Email
        </a>
      </div>
    );
  }
  return <></>;
};
