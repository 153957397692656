import React, { useContext, useState } from "react";
import { Button } from "react-bootstrap";
import { AddUrlDialog } from './AddUrlDialog';
import { SfUrlFilterList } from './SfUrlFilterList';
import { createLogger } from "../../common/util";
import { ConfirmDialogVariants } from "../../common/ConfirmDialog";
import { useConfirmDialog } from "../../common/hooks";
import { addToast } from "../../common/toast";
import { WebFilterContext } from "./_webfilterContext";
import { useDispatch, useSelector } from "react-redux";
import { changeURLFilterURL, deleteURLFilter, patchURLFilterAction, selectURLFilterData } from "../_securitySlice";

const logger = createLogger('ui:security:webfilter:FilterByURLView');

export function FilterByURLView({modifiedState}) {
  const {
    doUrlSubmit,
    showConflictDialogAndReload,
  } = useContext(WebFilterContext);
  const dispatch = useDispatch();
  const urlFilterData = useSelector(selectURLFilterData);

  const [dialogShowing, setDialogShowing] = useState(false);
  const [modified, setModified] = modifiedState;

  const { dialog, showDialog } = useConfirmDialog({
    variant: ConfirmDialogVariants.DELETE_CONFIRM,
    dialogClassName: 'border shadow',
    dialogBodyClassName: 'pb-6',
    buttonClassName: 'sf-btn-w-default mr-4',
    size: ''
  });

  const onClickAddURL = () => {
    setDialogShowing(true);
  }

  const handleCloseDialog = (successfully) => {
    if (successfully) {
      setModified(true);
    }
    setDialogShowing(false);
  }

  const onSwitchChange = (data) => {
    logger("onSwitchChange data: %o", data);
    dispatch(patchURLFilterAction({
      url: data.url,
      action: data.targetValue,
    }));
    setModified(true);
  }

  const onDeleteFilter = (data) => {
    logger('on Delete URL Filter');

    showDialog(() => (
      <>
        <h2>Delete this URL</h2>
      </>
    ))
      .then((res) => {
        logger("showDialog response", res);
        if (res) {
          dispatch(deleteURLFilter({
            url: data.url
          }));
          setModified(true);
        }
      });
  }

  const onChangeURL = (data) => {
    logger("onChangeURL data: %o", data);
    dispatch(changeURLFilterURL({
      url: data.targetValue,
      oldUrl: data.url,
      action: data.action
    }));
    setModified(true);
  }

  const onSave = () => {
    doUrlSubmit({
      data: urlFilterData,
    })
      .then(resp => {
        addToast('save URL filters successfully', 'success');
        setModified(false);
      })
      .catch((error) => {
        if (error.code != null && error.code === "ERR_CODE_MODEL_VERSION_CONFLICT") {
          showConflictDialogAndReload();
        } else {
          addToast('save URL filters failed', 'error');
        }
      });
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-5">
        <div className="d-flex align-items-center">
          <h4 className="mr-1">Filter By URL </h4>
          <i className='sf-icon-info mr-4' />
          {modified && <div className="fixed-bottom sf-banner-bottom sf-bg-white w-100 border-top shadow-up d-flex align-items-center justify-content-end">
            <Button variant='primary' className="sf-btn-w-default sf-btn-h-default mr-0" onClick={onSave}>
              Submit
            </Button>
          </div>}
        </div>
        <div className="d-flex align-items-center">

          <Button variant="link" className="ml-4 p-0 d-flex align-items-center function-action no-focus-outline" onClick={onClickAddURL}>
            Add URL
            <i className='sf-icon-add-cta ml-4' />
          </Button>
        </div>
        {dialog}
        <AddUrlDialog show={dialogShowing} handleClose={handleCloseDialog} />
      </div>
      <SfUrlFilterList data={urlFilterData} onSwitchChange={onSwitchChange} onDelete={onDeleteFilter} onValueChange={onChangeURL} />
    </>
  );
}