import React, {useState} from 'react';
import {Button, Form} from 'react-bootstrap';
import {hasError} from '../../common/validation';
import * as s from 'superstruct';
import {define} from 'superstruct';
import isEmail from 'is-email';
import {useForm} from 'react-hook-form';
import {superstructResolver} from '@hookform/resolvers/superstruct';
import {useDispatch, useSelector} from 'react-redux';
import {addToast} from '../../common/toast';
import {startSendResetPasswordEmail} from '../_authSlice';
import {unwrapResult} from '@reduxjs/toolkit';
import {selectCustomerConfig} from '../_authSliceSelect';
import {createLogger} from '../../common/util';
import {LinkBasicLogin} from './LinkBasicLogin';
import {portal} from "../../common/env";

const logger = createLogger('ui:reset-password-send-email');

export const SendEmailContent = () => {
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [email, setEmail] = useState('');
  if (isEmailSent) {
    return <SendEmailSuccess email={email} />;
  } else {
    return <SendEmailForm setIsEmailSent={setIsEmailSent} setEmail={setEmail} />;
  }
};

const SendEmailForm = ({ setIsEmailSent, setEmail }) => {
  const customerConfig = useSelector(selectCustomerConfig);
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState('');
  const Email = define('Email', isEmail);
  const schema = s.object({
    email: Email
  });
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: superstructResolver(schema, {
      coerce: true
    })
  });

  const onSubmit = (data) => {
    if (!customerConfig || !customerConfig.realmId) {
      addToast('customer config not ready', 'error');
      return;
    }
    logger('Send email:', data.email);
    const p = portal || '';
    dispatch(startSendResetPasswordEmail({ email: data.email, portal: p.toUpperCase() }))
      .then(unwrapResult)
      .then(() => {
        setIsEmailSent(true);
        setEmail(data.email);
      })
      .catch((error) => {
        addToast(error.message, 'error');
      });
  };

  const onError = () => {
    setErrorMessage('Please enter a valid email address.');
  };

  return (
    <div>
      <section>
        <div className='sf-font-h5 sf-text-c-secondary mt-5'>Enter your email address and we’ll send you a link to reset it.</div>
      </section>
      <section className='mt-6'>
        <Form onSubmit={handleSubmit(onSubmit, onError)}>
          <Form.Group controlId='formResetPasswordEmail'>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type='text'
              placeholder='Email Address'
              name='email'
              {...register('email')}
              className={hasError(errors, `email`) ? 'is-invalid shake' : ''}
            />
          </Form.Group>
          {errorMessage && <div className='sf-text-c-red sf-text-f-body3 mt-2'>{errorMessage}</div>}
          <div className='row mt-6'>
            <div className='col-12 col-md-6'>
              <Button variant='primary' className='sf-btn-w-xlg' type='submit'>
                Send Email
              </Button>
            </div>
            <div className='col-12 col-md-6 d-flex align-items-center'>
              <LinkBasicLogin />
            </div>
          </div>
        </Form>
      </section>
    </div>
  );
};

const SendEmailSuccess = ({ email }) => {
  return (
    <div className='visible-animated mt-6'>
      <section>
        If there is an account associated with
        <span className='sf-font-h5'> {email} </span>
        you will receive an email with instructions to reset your password.
      </section>
      <section className='mt-6'>
        <LinkBasicLogin />
      </section>
    </div>
  );
};
