import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

/**
 * An overlay that will show spinner when not initialized.
 * The other content are displayed underneath, it is usually used with absolute and semi transparent background.
 *
 * @param initialized Whether the content is initialized or not
 */
export function LoadingOverlay({ initialized, children, className, spinner }) {
  if (className == null) {
    className = 'display-1 bg-transparent position-absolute';
  }
  if (spinner == null) {
    spinner = <FontAwesomeIcon icon={faSpinner} className="fa-spin"/>;
  }
  if (!initialized) {
    return (
      <>
        <div className={className}>
          {spinner}
        </div>
        {children}
      </>
    );
  } else {
    return children;
  }
}
