import React from 'react';
import {Dropdown} from 'react-bootstrap';
import {Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {useForm} from "react-hook-form";
import VpnLight from "../../../assets/topology/vpn-light.svg";
import WanLight from "../../../assets/topology/wan-light.svg";
import {SfFormSelect} from "../../form/SfFormSelect";

// const logger = createLogger('ui:bandwidthUsage');

export const BandwidthUsage = ({height = 288, bandwidth = {download: [], upload: []}}) => {
  const form = useForm({
    defaultValues: {
      type: 'download'
    }
  });
  const {control, watch} = form;
  const watchType = watch('type');

  return (
    <div className='sf-home-card sf-chart w-100 rounded' style={{height: height}}>
      <div className='px-6 pt-6 pb-5 d-flex justify-content-between'>
        <div>
          <div className='d-flex align-items-center'>
            <h4>Bandwidth Usage</h4>
            <div className='sf-text-f-body1 ml-4'>(Mbps)</div>
          </div>
          <div className='mt-3'>
            <BandwidthType/>
          </div>
        </div>

        <div>
          <SfFormSelect name="type" defaultValue="download" control={control}>
            <Dropdown.Item eventKey="download">Download</Dropdown.Item>
            <Dropdown.Item eventKey="upload">Upload</Dropdown.Item>
          </SfFormSelect>
        </div>
      </div>
      <BandwidthUsageChart data={watchType === 'download' ? bandwidth.download : bandwidth.upload}/>
    </div>
  );
};

const BandwidthType = () => {
  return (
    <div className='d-flex align-items-center'>
      <div className='mr-5'>
        <span className='mr-3'>
          <img src={VpnLight} alt="" />
        </span>
        <span className='sf-text-f-body1'>CorpNet</span>
      </div>
      <div>
        <span className='mr-3'>
          <img src={WanLight} alt="" />
        </span>
        <span className='sf-text-f-body1'>Home WiFi</span>
      </div>
    </div>
  );
}

const BandwidthUsageChart = ({data}) => {
  return (
    <ResponsiveContainer width='100%' height='60%'>
      <AreaChart
        width={500}
        height={200}
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0
        }}
      >
        <defs>
          <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
            <stop offset='5%' stopColor='#F8F0FC' stopOpacity={0.8}/>
            <stop offset='95%' stopColor='#FFFFFF' stopOpacity={0}/>
          </linearGradient>
          <linearGradient id='colorPv' x1='0' y1='0' x2='0' y2='1'>
            <stop offset='5%' stopColor='#F0EDFE' stopOpacity={0.8}/>
            <stop offset='95%' stopColor='#FFFFFF' stopOpacity={0}/>
          </linearGradient>
        </defs>
        <XAxis type="number" tickCount={5} unit='s' dataKey='name'/>
        <YAxis interval="preserveStartEnd" domain={["dataMin", dataMax => (dataMax)]}/>
        <CartesianGrid strokeDasharray='3 3'/>
        <Tooltip/>
        <Area isAnimationActive={false} type='monotone' label='CorpNet' dataKey='CorpNet' stroke='#3864B7' fillOpacity={1} fill='url(#colorUv)'/>
        <Area isAnimationActive={false} type='monotone' label='HomeWiFi' dataKey='HomeWiFi' stroke='#4AA3BD' fillOpacity={1} fill='url(#colorPv)'/>
      </AreaChart>
    </ResponsiveContainer>
  );
};
