import _ from 'lodash';

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectToken = (state) => _.get(state, 'auth.token');
export const selectCustomerConfig = (state) => _.get(state, 'auth.customerConfig');
export const selectAuthProvider = (state) => _.get(state, 'auth.customerConfig.authProvider', []);
export const selectHasSmbProvider = (state) => _.includes(_.get(state, 'auth.customerConfig.authProvider', []), 'SMB');
export const selectHasAzureProvider = (state) => _.includes(_.get(state, 'auth.customerConfig.authProvider', []), 'AZURE');
export const selectCustomerConfigLoadingState = (state) => _.get(state, 'auth.customerConfig_state');
export const selectCustomerId = (state) => _.get(state, 'auth.customerConfig.customerId');
export const selectUserAgentId = (state) => _.get(state, 'auth.userAgentId');
export const selectProfile = (state) => _.get(state, 'auth.profile');
export const selectProfileLoadingState = (state) => _.get(state, 'auth.profile_state');
export const selectProfileSessions = (state) => _.get(state, 'auth.profileSessions');
export const selectProfileSessionsState = (state) => _.get(state, 'auth.profileSessions_state');
export const selectLoginPolicy = (state) => _.get(state, 'auth.loginPolicy');
export const selectUserRoles = (state) => _.get(state, 'auth.profile.roles', []);
export const selectMfaVerifyToken = (state) => _.get(state, 'auth.mfaToken');
export const selectMfaTarget = (state) => _.get(state, 'auth.mfaTarget');
export const selectIsPasswordMatchRequirements = (state) => _.get(state, 'auth.isPasswordMatchRequirements', false);
export const selectDisplayCompanyLogo = (state) => _.get(state, 'auth.displayCompanyLogo', false);
