import React, { useState } from "react";
import { ConfirmDialog } from "./ConfirmDialog";

const domainNameRegex = /(https?:\/\/[^/]+)/;

/**
 * This hook will extract baseUrl from current browser location.
 */
export function useUrl() {
  const url = window.location.href;
  const m = domainNameRegex.exec(url);
  if (m) {
    return {
      baseUrl: m[1]
    };
  } else {
    throw new Error(`Cannot extract baseUrl from ${url}`);
  }
}

/**
 * Create a confirm dialog.
 *
 * Example:
 * ```
 * const {dialog, showDialog} = useConfirmDialog({
 *   variant: ConfirmDialogVariants.CONFIRM_ONLY,
 *   dialogClassName: 'border shadow',
 *   dialogBodyClassName: 'pb-6',
 *   buttonClassName: 'sf-btn-w-default mr-4',
 *   size: ''
 * });
 *
 * const someHandler = (e) => {
 *   showDialog((
 *     <div>Some content</div>
 *   ));
 * }
 * ```
 *
 * @param variant Dialog variant, see `ConfirmDialogVariants`
 * @param backdropClassName Classname for modal backdrop
 * @param dialogClassName Classname to be applied on the dialog itself.
 * @param buttonClassName The classname to apply to button on top of the variant
 * @param dialogFooterClassName The classname used in footer (buttons) area
 * @param size modal size
 * @returns {{dialog: JSX.Element, showDialog: showDialog}}
 * - dialog: This is the dialog element, please add it to your page.
 * - showDialog: This is how you display the dialog, it return a promise with true/false as parameter.
 */
export function useConfirmDialog({
  variant,
  backdropClassName,
  dialogClassName,
  dialogBodyClassName,
  dialogFooterClassName,
  buttonClassName,
  size
}) {
  const [show, setShow] = useState(false);
  const [content, setContent] = useState();
  const [callback, setCallback] = useState();
  const dialog = (
    <ConfirmDialog show={show} setShow={setShow} variant={variant}
                   backdropClassName={backdropClassName}
                   contentClassName={dialogClassName}
                   bodyClassName={dialogBodyClassName}
                   buttonClassName={buttonClassName}
                   footerClassName={dialogFooterClassName}
                   size={size}
                   callback={callback}>
      {content}
    </ConfirmDialog>
  );
  const showDialog = (children) => {
    return new Promise(resolve => {
      setContent(children);
      setShow(true);
      // If the argument is a function, react state reducer will invoke it and take the result
      // This is why the below has to be a curried function.
      const wrapped = () => res => {
        setShow(false);
        resolve(res);
      };
      setCallback(wrapped);
    });
  }
  return {
    dialog, showDialog
  };
}

/**
 * Create a SF confirm dialog with light backdrop.
 *
 * Example:
 *
 * ```
 * const { dialog, showDialog } = useSfConfirmDialog({
 *   variant: ConfirmDialogVariants.CLOSE_ONLY,
 *   size: 'md'
 * });
 * ```
 *
 * The dialog content is specified by calling showDialog.
 * According to our component design, use <h3/> for title, <div class="sf-text-c-secondary"/> for body.
 *
 * Example:
 *
 * ```
 * await showDialog((
 *   <>
 *     <h3 className="mb-5">To request inventory, contact your administrator or sales representative.</h3>
 *     <div className="sf-text-c-secondary">Description goes here.</div>
 *   </>
 * ));
 * ```
 *
 * @param variant
 * @param size
 * @return {{dialog: JSX.Element, showDialog: showDialog}}
 */
export function useSfConfirmDialog({
  variant,
  size
}) {
  return useConfirmDialog({
    backdropClassName: 'sf-modal-backdrop-light',
    dialogClassName: 'border shadow-sm',
    dialogBodyClassName: 'pb-6',
    dialogFooterClassName: 'justify-content-center',
    buttonClassName: 'sf-btn-w-default mr-4',
    variant, size
  });
}