import React, { useEffect, useState } from "react";
import * as s from "superstruct";
import { useForm } from "react-hook-form";
import { superstructResolver } from "@hookform/resolvers/superstruct";
import { Button, Form, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { hasError } from "../../common/validation";
import { createLogger } from "../../common/util";
import _ from "lodash";

const logger = createLogger("ui:security:sebfilter:SfUrlFilterList");

function UrlItemAction({ item, onChange, setOnEditing }) {

  const [switchValue, setSwitchValue] = useState();

  const value = _.get(item, 'action');

  useEffect(() => {
    if (value != null) {
      setSwitchValue(value);
    }
  }, [value]);

  const options = [
    { name: 'Block', value: 'block' },
    { name: 'Allow', value: 'allow' },
  ];

  const handleChange = (val) => {
    logger('in handleChange val=%o', val);
    setSwitchValue(val);
    const changeElement = Object.assign({ targetValue: val }, item)
    logger('changeElement = %o', changeElement);
    if (onChange != null) {
      onChange(changeElement);
      // .catch((error) => {
      //   setSwitchValue(item.action);
      // });
    }
  };

  return (
    <div className="d-flex align-items-center">
      <i className="sf-icon-edit invisible clickable mr-2" onClick={() => setOnEditing(true)}/>
      <div className="sf-switch-btn-group">
        <ToggleButtonGroup type="radio" name="sf-switch" value={switchValue} onChange={handleChange}>
          {
            options.map((option, idx) => (
              <ToggleButton key={idx} id={`tbg-radio-${idx}`} value={option.value} className={`sf-btn-${option.value}`}>
                {
                  option.value === switchValue ?
                    <i className={`sf-icon-${option.value}-selected mr-2`}/>
                    :
                    <i className={`sf-icon-${option.value} mr-2`}/>
                }
                {option.name}
              </ToggleButton>
            ))
          }
        </ToggleButtonGroup>
      </div>
    </div>
  );
}

function SfUrlFilterItem({ item, onSwitchChange, onDelete, onValueChange }) {

  const [onEditing, setOnEditing] = useState(false);
  // const hostAndPath = item.url != null ? item.url.replace(/^https?:\/\//i, '') : "";

  const isHost = (string) => {
    // eslint-disable-next-line no-useless-escape
    const hostMatcher = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)+([A-Za-z]|[A-Za-z][A-Za-z0-9\-]*[A-Za-z0-9])$/;
    return hostMatcher.test(string);
  };

  const HOST = s.define('URL', isHost);

  const schema = s.object({
    url: HOST,
  });

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: superstructResolver(schema),
  });

  const onSubmit = async data => {
    logger("submit: data=%o", data);
    const respObj = Object.assign({}, item, { targetValue: data.url });
    logger('resp Obj = %o', respObj);

    if (onValueChange != null) {
      setOnEditing(false);
      onValueChange(respObj);
    } else {
      setOnEditing(false);
    }
  }

  const onError = (error) => {
    logger('Form error:', error);
  };

  const onHandleDelete = () => {
    if (onDelete != null) {
      onDelete(item);
      setOnEditing(false);
    }
  }

  const onHandleClose = () => {
    setOnEditing(false);
  }

  if (onEditing) {
    return (
      <Form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="d-flex justify-content-between  align-items-center my-4">
          <Form.Control name="url" type="text" defaultValue={item.url}
                        {...register("url")} className={hasError(errors, `url`) ? "is-invalid mr-4" : "mr-4"}/>
          <div className="d-flex justify-content-end align-items-center">
            <Button className="sf-btn-w-default sf-btn-h-default mr-4" variant="primary" size="xlg" type="submit">Save</Button>
            <i className='sf-icon-delete-cta clickable mr-4' onClick={onHandleDelete}/>
            <i className='sf-icon-close-cta clickable' onClick={onHandleClose}/>
          </div>
        </div>
        {
          hasError(errors, `url`) &&
          <div className="row mt-2">
            <div className="col-12 sf-text-f-body2 sf-text-c-red">must be a valid host</div>
          </div>
        }
      </Form>
    );
  } else {
    return (
      <div className="row web-filter-item">
        <div className="col-12 d-flex justify-content-between align-items-center">
          <div><h4>{item.url}</h4></div>
          <UrlItemAction item={item} onChange={onSwitchChange} setOnEditing={setOnEditing}/>
        </div>
      </div>
    );
  }
}

export function SfUrlFilterList({ data, onSwitchChange, onDelete, onValueChange }) {

  return (
    <>
      {
        data != null && data.length > 0 &&
        data.map((item, index) =>
          <SfUrlFilterItem key={item.url} item={item}
                           onSwitchChange={onSwitchChange}
                           onDelete={onDelete}
                           onValueChange={onValueChange} />
        )
      }
    </>
  );
}