import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectVpnOptions, selectVpnSelfOption, startFetchAccountVpnOption, startFetchAllVpnOptions } from "./_vpnSlice";

export function useAccountVpn({ customerId, accountId = 'self' }) {
  const dispatch = useDispatch();
  const vpnServers = useSelector(selectVpnOptions);
  const accountVpnOption = useSelector(selectVpnSelfOption);

  // find all VPN servers
  useEffect(() => {
    dispatch(startFetchAllVpnOptions({
      orgId: customerId
    }))
  }, [dispatch, customerId]);

  // find account vpn option
  useEffect(() => {
    dispatch(startFetchAccountVpnOption({
      orgId: customerId, accountId
    }));
  }, [dispatch, customerId, accountId]);

  return useMemo(() => {
    return {
      vpnServers, accountVpnOption
    };
  }, [vpnServers, accountVpnOption])
}