import * as s from 'superstruct';
import { evaluateByPath } from './util';

export const coerceFormNumber = (target) =>
  s.coerce(target, s.string(), (v) => {
    if (v == null) {
      return v;
    } else {
      const r = parseFloat(v);
      if (isNaN(r)) {
        return v;
      }
      return r;
    }
  });

export const coerceStringToBoolean = (target) =>
  s.coerce(target, s.string(), (v) => {
    return v === '1';
  });

export const coerceOptionalNumber = (target) =>
  s.coerce(target, s.string(), (v) => {
    if (v == null || v === '') {
      return undefined;
    } else {
      return parseInt(v);
    }
  });

export const hasError = (errors, ...paths) => {
  return (paths || []).find((path) => {
    return evaluateByPath(errors, path) != null;
  });
};

export const readError = (errors, ...paths) => {
  const x = (paths || [])
    .map((path) => {
      return evaluateByPath(errors, path);
    })
    .find((it) => {
      return it != null;
    });
  return x?.message;
};

const iPv4Pattern = /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/;
export const validateIPv4String = s.refine(s.string(), 'IPv4', x => {
  if (!iPv4Pattern.test(x)) {
    return false;
  }
  const parts = x.split(".");
  if (parts.length !== 4) {
    return false;
  }
  const outOfBound = parts.map(it => parseInt(it))
    .filter(it => it < 0 || it > 255);
  return outOfBound.length <= 0;
})