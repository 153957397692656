import React, { useContext, useEffect, useRef } from "react";
import { SfScrollerContext } from "./SfScrollerContext";
import { createLogger } from "./util";

const logger = createLogger("ui:common:SfScrollFloater");

export function SfScrollerFloater({ className, children }) {
  const markerRef = useRef();
  const { doFloating, containerRef, fullyVisible, threshold } = useContext(SfScrollerContext);

  useEffect(() => {
    if (markerRef?.current != null && containerRef?.current != null) {
      logger("Initialize scroller observer from marker", markerRef?.current);
      const obs = new IntersectionObserver(function (e, o) {
        // const shouldFloat = e[0].intersectionRatio < 1;
        doFloating(!fullyVisible(e[0]));
      }, {
        root: containerRef?.current,
        threshold
      });
      obs.observe(markerRef?.current);

      return () => {
        logger("Disconnect scroller observer from marker");
        obs.disconnect();
      }
    }
  }, [markerRef?.current, containerRef?.current]);

  return (
    <div className={`scroller-floater ${className || ''}`} ref={markerRef}>
      {children}
    </div>
  );
}