import React, {useEffect, useState} from "react";
import {InternetSpeed} from "./toolkits/InternetSpeed";
import {useDispatch, useSelector} from "react-redux";
import {createLogger} from "../common/util";
import {addToast} from "../common/toast";
import {selectViewNetworkId} from "./_topologySlice";
import {startFetchLatestSpeedTest, startSendCommandToDevice} from "../common/commonAsyncThunks";
import {selectLatestSpeedTestResult} from "../speeds/_speedsSlice";

const logger = createLogger('ui:topology:speedTest');

export const HomeSpeedTest = ({onClickHandler}) => {
  const dispatch = useDispatch();
  const networkId = useSelector(selectViewNetworkId);
  const [disabled, setDisabled] = useState(false);
  const result = useSelector(selectLatestSpeedTestResult);
  useEffect(() => {
    if (networkId != null) {
      dispatch(startFetchLatestSpeedTest({networkId}))
        .then(resp => {
          if (resp?.payload == null) {
            // nothing is loaded
          } else {
            logger("Latest speed test", resp?.payload);
          }
        });
    }
  }, [dispatch, networkId]);

  const SpeedTestClickHandler = () => {
    setDisabled(true);
    if (onClickHandler) {
      onClickHandler();
      return;
    }
    dispatch(startSendCommandToDevice({networkId, cmd: 'speedTest'}))
      .then(resp => {
        if (resp?.payload == null || resp?.payload === '') {
          // nothing is loaded
        } else {
          logger("Speed test command sent", resp?.payload);
          addToast('Command successfully sent', 'success');
        }
      })
      .finally(() => setDisabled(false));

  }
  return (
    <InternetSpeed upload={result?.upload} download={result?.download} time={result?.dateTime}
                   disabled={disabled}
                   onClick={SpeedTestClickHandler}/>
  );
}