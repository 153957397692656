import React from "react";
import Checkmark from "../../assets/fwUpdate/checkmark.svg";
import {useSelector} from "react-redux";
import {FW_UPDATE_STATUS, selectFwUpdateStatus} from "./_firmwareUpdateSlice";

export const FirmwareMessage = () => {
  const fwUpdateStatus = useSelector(selectFwUpdateStatus);
  switch (fwUpdateStatus) {
    case FW_UPDATE_STATUS.UP_TO_DATE:
      return <UpdateToDateMessage/>
    case FW_UPDATE_STATUS.UPDATE_WAS_FOUND:
      return <UpdateWasFoundMessage/>
    default:
      return null;
  }
}

const UpdateWasFoundMessage = () => {
  return (
    <span className='sf-text-amber sf-font-h5 mr-6'>
      An update was found.
    </span>
  );
}

const UpdateToDateMessage = () => {
  return (
    <span className='mr-6'>
      <span className='mr-4'>
        <img src={Checkmark} alt=''/>
      </span>
      <span className='sf-text-green sf-font-h5'>
        Your firmware is up to date
      </span>
    </span>
  );
}