export const getTopic = ({customerId, networkId}) => {
  if (customerId == null || networkId == null) {
    return undefined;
  }
  return `sf/org/${customerId}/net/${networkId}/status`;
}

export const getBandwidthUsageTopic = ({customerId, networkId}) => {
  if (customerId == null || networkId == null) {
    return undefined;
  }
  return `sf/org/${customerId}/net/${networkId}/usage`;
}