import React from 'react';
import moment from 'moment';
import _ from 'lodash';

export const SidebarFooter = ({ version }) => {
  const v = _.get(version, 'version', '');
  const hash = _.get(version, 'hash', '');
  let createAtLocalTime;
  try {
    const createdAt = _.get(version, 'createdAt', '');
    const utc = new Date(createdAt);
    createAtLocalTime = moment(utc).local().format('MMM D, YYYY, HH:mm:ss z');
  } catch (e) {}

  return (
    <div className='sidebar-footer'>
      <div className='build-info'>
        <p>
          {v} ({hash})
        </p>
        <p>{createAtLocalTime}</p>
      </div>
    </div>
  );
};
