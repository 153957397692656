import React, {useEffect, useState} from "react";
import {SfCollapse} from "../common/SfCollapse";
import {NodeList} from "./NodeList";
import {useDispatch, useSelector} from "react-redux";
import {LoadingState} from "../common/util";
import {ButtonCheckForUpdate} from "./ButtonCheckForUpdate";
import {ButtonFwUpdate} from "./ButtonFwUpdate";
import {FirmwareMessage} from "./FirmwareMessage";
import {FW_UPDATE_STATUS, selectFwUpdateStatus} from "./_firmwareUpdateSlice";
import { selectNetworkDevicesLoadingState, startFetchNetworkDevices } from "../network/_networkSlice";

export const FirmwareUpdateView = ({networkId}) => {
  const dispatch = useDispatch();
  const networkDeviceLoadingState = useSelector(selectNetworkDevicesLoadingState);
  const fwUpdateStatus = useSelector(selectFwUpdateStatus);
  const [displayCheckingButton, setDisplayCheckingButton] = useState(false);
  const [displayUpdateButton, setDisplayUpdateButton] = useState(false);
  useEffect(() => {
    if (networkId !== null && networkDeviceLoadingState === LoadingState.NONE) {
      dispatch(startFetchNetworkDevices({networkId}));
    }
  }, [networkId, networkDeviceLoadingState]);

  useEffect(() => {
    setDisplayCheckingButton(fwUpdateStatus === FW_UPDATE_STATUS.NONE || fwUpdateStatus === FW_UPDATE_STATUS.CHECKING);
    setDisplayUpdateButton(fwUpdateStatus === FW_UPDATE_STATUS.UPDATE_WAS_FOUND);
  }, [fwUpdateStatus]);

  return (
    <div>
      <h3>Firmware Update</h3>
      <div className='sf-text-c-secondary sf-text-f-body1 mt-2'>
        Your router automatically updates overnight whenever new firmware is available. This keeps you up to date with the latest
        features and fixes.
      </div>
      <div className='mt-5'>
        <FirmwareMessage/>
        {displayCheckingButton && <ButtonCheckForUpdate networkId={networkId}/>}
        {displayUpdateButton && <ButtonFwUpdate networkId={networkId}/>}
      </div>
      <div className='mt-6'>
        <SfCollapse text="Show details">
          <div className='w-100'>
            <NodeList/>
          </div>
        </SfCollapse>
      </div>
    </div>
  );
}