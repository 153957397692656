import debug from 'debug';
import _ from "lodash";
import React, { useEffect, useState } from "react";
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';


const logger = debug('ui:data:SfTwoLevelSwitch');

function SfToggleButton({ category, valuePath, options, onChange }) {

  const [switchValue, setSwitchValue] = useState();
  const value = _.get(category, valuePath);
  logger("Toggle, category: %o, path: %s", category, valuePath);

  useEffect(() => {
    if (value != null) {
      setSwitchValue(value);
    }
  }, [value]);

  const handleChange = (val) => {
    logger('in handleChange val=%o', val);
    setSwitchValue(val);
    const changeElement = Object.assign({ targetValue: val }, category)
    logger('changeElement = %o', changeElement);
    if (onChange != null) {
      onChange(changeElement);
    }
  };

  return (
    <div className="sf-switch-btn-group">
      <ToggleButtonGroup type="radio" name="sf-switch" value={switchValue} onChange={handleChange}>
        {
          options.map((option, idx) => (
            <ToggleButton key={idx} id={`tbg-radio-${idx}`} value={option.value} className={`sf-btn-${option.value}`}>
              {
                option.value === switchValue ?
                  <i className={`sf-icon-${option.value}-selected mr-2`} />
                  :
                  <i className={`sf-icon-${option.value} mr-2`} />
              }

              {option.name}
            </ToggleButton>
          ))
        }
      </ToggleButtonGroup>
    </div>
  );
}


function SecondLevelTilte({ secondTitle, category }) {

  if (secondTitle instanceof Function) {
    return secondTitle(category);
  } else {
    const name = _.get(category, secondTitle);
    return (
      <div className="sf-switch-second-title">{name}</div>
    );
  }
}

function SecondLevel({ items, secondTitle, valuePath, options, onChange }) {

  return (
    <>
      {items != null && items.length > 0 &&
        items.sort((a, b) => a.categoryname > b.categoryname ? 1 : -1)
          .map((category, index) => {

            // const value = _.get(category, valuePath);
            return (
              <div key={index} className="d-flex justify-content-between align-items-center mb-5">
                <SecondLevelTilte secondTitle={secondTitle} category={category} />
                <SfToggleButton category={category} valuePath={valuePath} options={options} onChange={onChange} />
              </div>
            )
          })
      }
    </>
  );
}



function FirstLevel({ firstLevelTitle, items, secondTitle, valuePath, options, onChange }) {

  const [opened, setOpened] = useState(false);
  const categories = Object.values(items);
  const categoryNum = categories.length;
  const blockNum = Object.values(items).filter(category => category.action === 'block').length;

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-5">
        <div><h4>{firstLevelTitle}</h4></div>
        <div className="d-flex align-items-center">
          <div className="sf-text-c-gray">{blockNum} of {categoryNum} Blocked</div>
          {
            opened ?
              <i className='sf-icon-accordion-collapse clickable ml-4' onClick={() => setOpened(!opened)} />
              :
              <i className='sf-icon-accordion-expand clickable ml-4' onClick={() => setOpened(!opened)} />
          }
        </div>
      </div>
      {
        opened &&
        <SecondLevel items={categories} secondTitle={secondTitle} valuePath={valuePath} options={options} onChange={onChange} />
      }
    </>
  );
}



export function SfTwoLevelSwitch({ data, secondTitle, valuePath, options, onChange }) {
  logger("SfTwoLevelSwitch, data: %o", data, valuePath);
  return (
    <>
      {
        data && Object.keys(data).length > 0 &&
        Object.keys(data).sort().map((type, index) =>
          <FirstLevel key={index}
            firstLevelTitle={type}
            items={data[type]}
            secondTitle={secondTitle} // could be element name or a function to show title
            valuePath={valuePath}
            options={options}
            onChange={onChange} />
        )
      }
    </>
  )

}