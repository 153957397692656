import React, {useState} from 'react';
import {FormControl} from 'react-bootstrap';

export const SfPassword = React.forwardRef((props, ref) => {
  const [display, setDisplay] = useState(false);
  return (
    <div style={{ position: 'relative' }}>
      <FormControl type={display ? 'text' : 'password'} ref={ref} {...props} />
      <span
        className='sf-text-f-body2'
        style={{ position: 'absolute', cursor: 'pointer', right: '18px', top: '16px' }}
        onClick={() => setDisplay(!display)}
      >
        {display ? 'Hide' : 'Show'}
      </span>
    </div>
  );
});
