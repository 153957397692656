import React, {useState} from "react";
import {Collapse} from "react-bootstrap";

export const SfCollapse = ({text = '', controlId = 'sf-ui-collapse', children}) => {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <div
        className='sf-text-primary-color sf-font-h5 clickable'
        onClick={(e) => {
          e.preventDefault();
          setOpen(!open);
        }}
        aria-controls={controlId}
        aria-expanded={open}
      >
        <div className='d-flex'>
          <div>{text}</div>
          <div className='ml-3 d-flex align-items-center'>
            {open ? <i className='sf-icon-chevron-up'/> : <i className='sf-icon-chevron-down'/>}
          </div>
        </div>
      </div>

      <Collapse in={open}>
        <div id={controlId}>
          {children}
        </div>
      </Collapse>
    </div>
  );
};