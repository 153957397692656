import React from "react";

import SpeedTestLogo from "../../assets/speeds/speedtest-logo.png";
import {SfFrame} from "../common/SfFrame";

// const logger = createLogger('ui:speedTest:process');

export const SpeedTestProcess = ({children}) => {
  return (
    <div className='row'>
      <div className='col-md-12 col-lg-10'>
        <SfFrame>
          {children}
        </SfFrame>
      </div>
      <div className='mt-5 col-12'>
        <img alt='' src={SpeedTestLogo}/>
      </div>
    </div>
  );
}
