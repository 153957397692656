import React from 'react';
import {MenuItem} from './MenuItem';

export const SidebarContent = ({ SidebarContentHeader, menuItems = [] }) => {
  return (
    <div className='sidebar-content'>
      <div className='sidebar-content-header'>
        {SidebarContentHeader && <SidebarContentHeader />}
      </div>
      <PrepareMenu menuItems={menuItems} />
    </div>
  );
};

function PrepareMenu({ menuItems }) {
  const physicalMenuItems = menuItems.map((item, index) => <MenuItem key={index} item={item} />);
  return <>{physicalMenuItems}</>;
}
