import React, {useEffect, useState} from "react";
import {BandwidthUsage} from "./toolkits/BandwidthUsage";
import {useSelector} from "react-redux";
import _ from "lodash";
import {createLogger} from "../common/util";
import {selectViewVpnBandwidth, selectViewWanBandwidth} from "./_topologySlice";

const logger = createLogger('ui:topology:bandwidth:usage');

export const HomeBandwidthUsage = () => {
  const wanBandwidthUsage = useSelector(selectViewWanBandwidth);
  const vpnBandwidthUsage = useSelector(selectViewVpnBandwidth);

  const [bandwidth, setBandwidth] = useState({download: [], upload: []});

  useEffect(() => {
    if (wanBandwidthUsage.length > 1 || vpnBandwidthUsage.length > 1) {
      setBandwidth((p) => {
        const maxLength = Math.max(wanBandwidthUsage.length, vpnBandwidthUsage.length);
        p.download = [];
        p.upload = [];
        for (let i = 1; i < maxLength; i++) {
          const vpnDownload = _.get(vpnBandwidthUsage[i], 'bytesIn', 0) - _.get(vpnBandwidthUsage[i - 1], 'bytesIn', 0);
          const _vpnDownload = vpnDownload > 0 ? vpnDownload / 1024 / 1024 * 8 / 5 : 0;

          const wanDownload = _.get(wanBandwidthUsage[i], 'bytesIn', 0) - _.get(wanBandwidthUsage[i - 1], 'bytesIn', 0);
          const _wanDownload = wanDownload > 0 ? wanDownload / 1024 / 1024 * 8 / 5 : 0;
          p.download.unshift({
            name: (maxLength - i - 1) * 5,
            CorpNet: parseFloat(_vpnDownload.toString().substr(0, 6)),
            HomeWiFi: parseFloat(_wanDownload.toString().substr(0, 6)),
            vpnTS: _.get(vpnBandwidthUsage[i], 'ts', 0),
            wanTS: _.get(wanBandwidthUsage[i], 'ts', 0),
          })

          const vpnUpload = _.get(vpnBandwidthUsage[i], 'bytesOut', 0) - _.get(vpnBandwidthUsage[i - 1], 'bytesOut', 0);
          const _vpnUpload = vpnUpload > 0 ? vpnUpload / 1024 / 1024 * 8 / 5 : 0;

          const wanUpload = _.get(wanBandwidthUsage[i], 'bytesOut', 0) - _.get(wanBandwidthUsage[i - 1], 'bytesOut', 0);
          const _wanUpload = wanUpload > 0 ? wanUpload / 1024 / 1024 * 8 / 5 : 0;
          p.upload.unshift({
            name: (maxLength - i - 1) * 5,
            CorpNet: parseFloat(_vpnUpload.toString().substr(0, 6)),
            HomeWiFi: parseFloat(_wanUpload.toString().substr(0, 6))
          })
        }
        logger('Merged data', p);
        return p;
      });
    }
  }, [wanBandwidthUsage, vpnBandwidthUsage]);

  return (
    <div>
      <BandwidthUsage bandwidth={bandwidth}/>
    </div>
  );
};
