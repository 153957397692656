import {slice} from "./_speedsSlice";

export const speedsReducer = slice.reducer;
export * from "./_speedsSlice";

export {IspForm} from "./IspForm";
export {IspInfo} from "./IspInfo";
export {SpeedTestAllResults} from "./SpeedTestAllResults";
export {SpeedTestButton} from "./SpeedTestButton";
export {SpeedTestIoT} from "./SpeedTestIoT";
export {SpeedTestNoRecord} from "./SpeedTestNoRecord";
export {SpeedTestProcess} from "./SpeedTestProcess";
export {SpeedTestResult} from "./SpeedTestResult";
export {SpeedTestRunning} from "./SpeedTestRunning";
export {SpeedTrends} from "./SpeedTrends";
export {SpeedTrendsCard} from "./SpeedTrendsCard";
export {RenderSpeedsPage} from "./RenderSpeedsPage";