import SmartWatchSvg from '../../../assets/devices/smart-watch.svg';
import SpeakerSvg from '../../../assets/devices/speaker.svg';
import GamingSvg from '../../../assets/devices/gaming.svg';
import TabletSvg from '../../../assets/devices/tablet.svg';
import LaptopSvg from '../../../assets/devices/laptop.svg';
import PhoneSvg from '../../../assets/devices/phone.svg';

const nodeClientIcons = [
  {
    'altText': 'smart-watch',
    'iconSvg': SmartWatchSvg,
    'id' : '9ac60312-2982-428f-ab35-c959fd3c7791'
  },
  {
    'altText': 'speaker',
    'iconSvg': SpeakerSvg,
    'id' : '922ae2af-4df8-4dc7-a0e3-cbd8295e28fb'
  },
  {
    'altText': 'gaming',
    'iconSvg': GamingSvg,
    'id' : '6b70252c-c643-427b-9c3e-7207032d8a4c'
  },
  {
    'altText': 'tablet',
    'iconSvg': TabletSvg,
    'id' : '79c36943-98d5-4dd8-bbc9-7f84b1169609'
  },
  {
    'altText': 'laptop',
    'iconSvg': LaptopSvg,
    'id' : 'cc3b34c8-2877-4fb1-a5d2-6cf405fc299c'
  },
  {
    'altText': 'phone',
    'iconSvg': PhoneSvg,
    'id' : '349447c3-725c-4948-8ea9-a6f1fbe03255'
  },
];

const defaultIcon = {
  'altText': 'default-device',
  'iconSvg': '',
  'id': '760ed16f-253f-4844-a8f8-ba886fcc3e58'
};

const findIconById = (iconId) => {
  const iconOb = nodeClientIcons.find( (iconToReview) =>{
    return iconId === iconToReview.id;
  });
  if (iconOb) {
    return iconOb;
  }
  return defaultIcon;
};

export default function useNodeClientAssets() {
  return {
    defaultIcon,
    nodeClientIcons,
    findIconById
  };
};
