import debug from 'debug';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {composeCommonApiOptions, composeCommonHeaders, composeUrl, LoadingState, unwrapErrorResponse} from '../common/util';
import moment from 'moment';
import {domainOrOverride, envBaseApiUrl, envClientTypeId, envClientTypeSecret} from '../common/env';
import {getAxiosInstance as axios} from './axios/axiosCommon';
import _ from 'lodash';
import {OAuthType} from './_oauth';
import {addToast} from '../common/toast';
import {disconnect} from '../iot/_iot';
import {hasPermission} from "./permission/PermissionUtils";

const logger = debug('sf:ui:auth:index');
const trace = debug('sf:ui:auth:index:trace');

export const startFetchCustomerConfigs = createAsyncThunk('auth/fetchCustomerConfigs', async (options, thunkAPI) => {
  const threadCounter = _.get(thunkAPI.getState(), 'auth.customerConfigThreadCounter');
  if (threadCounter === 1) {
    const {domain, dispatch} = options;
    const url = composeUrl(`${envBaseApiUrl}/organization-service/rest/customer-configs`, {
      domain: domainOrOverride(domain)
    });
    return axios()
      .get(url, {
        headers: composeCommonHeaders({})
      })
      .then(function (response) {
        logger('fetch customer configs response: ', response.data);
        const loginPolicy = _.get(response.data, 'authProvider[0]', OAuthType.SMB);
        dispatch(doUpdateLoginPolicy(loginPolicy));
        return response.data;
      })
      .catch(function (error) {
        logger('fetch customer failed: ', error);
        throw error;
      });
  }
});

export const startLogin = createAsyncThunk('auth/login', async (data, thunkAPI) => {
  const {gt: grantType, u: username, p: password, code, state, r: refreshToken, ug: userAgentId} = data;
  const opt = {};
  switch (grantType) {
    case GrantType.CODE:
      Object.assign(opt, {
        code: code,
        state: state,
        redirect_uri: `${envBaseApiUrl}/authorization-service/oauth/redirect`
      });
      break;
    case GrantType.REFRESH_TOKEN:
      Object.assign(opt, {
        refresh_token: refreshToken
      });
      break;
    case GrantType.PASSWORD:
    default:
      Object.assign(opt, {
        username: username,
        password: password
      });
  }
  const url = composeUrl(`${envBaseApiUrl}/authorization-service/oauth/token`, {
    grant_type: grantType,
    client_id: envClientTypeId,
    client_secret: envClientTypeSecret,
    ...opt
  });
  const headers = {
    'content-type': 'application/x-www-form-urlencoded',
    accept: 'application/json'
  };
  if (userAgentId) {
    Object.assign(headers, {'X-SMB-User-Agent-Id': userAgentId});
  }
  return axios()
    .post(url, null, {
      headers
    })
    .then(function (response) {
      logger(`${grantType} Login response: `, response);
      return response.data;
    })
    .catch(function (error) {
      logger(`${grantType} Login failed: `, error);
      return thunkAPI.rejectWithValue(unwrapErrorResponse(error));
    });
});

export const startMfaChallenge = createAsyncThunk('auth/mfaChallenge', async (options, thunkAPI) => {
  const {mfaToken, type, method} = options;
  if (!mfaToken || !mfaToken.value) {
    throw Error('MFA data is invalid');
  }

  const url = composeUrl(`${envBaseApiUrl}/authorization-service/oauth/challenge`, {
    client_id: envClientTypeId,
    client_secret: envClientTypeSecret
  });
  return axios()
    .post(url, {
      challengeType: type,
      communicationMethod: method,
      verifyToken: `${mfaToken.value}`
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw unwrapErrorResponse(error);
    });
});

export const startMfaValidate = createAsyncThunk('auth/mfaValidate', async (options, thunkAPI) => {
  const {verificationToken, otp, rememberUserAgent} = options;
  const url = composeUrl(`${envBaseApiUrl}/authorization-service/oauth/token`, {
    grant_type: GrantType.MFA,
    client_id: envClientTypeId,
    client_secret: envClientTypeSecret,
    verification_token: verificationToken,
    otp: otp,
    remember_user_agent: rememberUserAgent
  });
  const headers = {
    'content-type': 'application/x-www-form-urlencoded',
    accept: 'application/json'
  };
  return axios()
    .post(url, null, {headers})
    .then((response) => {
      logger('MFA validate response: ', response);
      return response.data;
    })
    .catch((error) => {
      throw thunkAPI.rejectWithValue(unwrapErrorResponse(error));
    });
});

export const startGetProfile = createAsyncThunk('auth/getProfile', async (options, thunkAPI) => {
  const profileThreadCounter = _.get(thunkAPI.getState(), 'auth.profileThreadCounter');
  if (profileThreadCounter === 1) {
    const url = `${envBaseApiUrl}/user-service/rest/accounts/self`;
    return axios()
      .get(url, {
        headers: composeCommonHeaders(composeCommonApiOptions(thunkAPI.getState()))
      })
      .then(function (response) {
        logger('get Profile response: ', response.data);
        return response.data;
      })
      .catch(function (error) {
        logger('get Profile failed: ', error);
        throw error;
      });
  }
});

export const startCreatePassword = createAsyncThunk('auth/createPassword', async (options) => {
  const {token, email, password} = options;
  const url = `${envBaseApiUrl}/user-service/rest/accounts/self/createpassword`;
  return axios()
    .post(
      url,
      {
        username: email,
        newPassword: password
      },
      {
        headers: {'X-SMB-Verification-Token': token}
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw unwrapErrorResponse(error);
    });
});

export const startValidatePassword = createAsyncThunk('auth/validatePassword', async (options) => {
  const {currentPassword} = options;
  const url = `${envBaseApiUrl}/user-service/rest/accounts/self/passwordvalidations?purpose=PASSWORD_RESET`;
  return axios()
    .post(url, {
      password: currentPassword
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw unwrapErrorResponse(error);
    });
});

export const startChangePassword = createAsyncThunk('auth/changePassword', async (options) => {
  const {token, email, newPassword} = options;
  const url = `${envBaseApiUrl}/user-service/rest/accounts/${email}/passwordchanges`;
  return axios()
    .post(
      url,
      {
        newPassword: newPassword
      },
      {
        headers: {'X-SMB-Verification-Token': token}
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw unwrapErrorResponse(error);
    });
});

export const startSendResetPasswordEmail = createAsyncThunk('auth/createPassword', async (options, thunkAPI) => {
  const {email, portal} = options;
  const url = composeUrl(`${envBaseApiUrl}/user-service/rest/accounts/${email}/forgotpassword`,
    {
      portal
    });
  return axios()
    .post(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw unwrapErrorResponse(error);
    });
});

// load the token from localStorage.
const _default = {token: undefined, customerConfig: undefined, profile: undefined};
const {
  token: t,
  customerConfig: cc,
  profile: p,
  loginPolicy: l,
  userAgentId: ua
} = window.localStorage.getItem('auth') ? JSON.parse(window.localStorage.getItem('auth')) : Object.assign({}, _default);
trace('[init] auth from localstorage:', window.localStorage.getItem('auth'), t, cc, ua, p, l);

export const slice = createSlice({
  name: 'auth',
  initialState: {
    token: t,
    customerConfig: _.get(cc, 'realmId') ? cc : undefined,
    customerConfig_state: LoadingState.NONE,
    customerConfigThreadCounter: 0,
    profile: p,
    profile_state: LoadingState.NONE,
    profileThreadCounter: 0,
    profileSessions: undefined,
    profileSessions_state: LoadingState.NONE,
    loginPolicy: l,
    userAgentId: ua
  },
  reducers: {
    logout: (state) => {
      logger('[logout]');
      state.token = undefined;
      state.profile = undefined;
      state.profile_state = LoadingState.NONE;
      state.profileSessions = undefined;
      state.profileSessions_state = LoadingState.NONE;
      return state;
    },
    updateToken: (state, action) => {
      state.token = processToken(action.payload);
      return state;
    },
    updateLoginPolicy: (state, data) => {
      logger('[updateLoginPolicy]', data);
      const policy = data.payload;
      state.loginPolicy = policy;
      return state;
    },
    updateMfaToken: (state, data) => {
      logger('[updateMfaToken]', data);
      const mfaToken = data.payload;
      state.mfaToken = mfaToken;
      return state;
    },
    clearMfaData: (state, data) => {
      state.mfaToken = undefined;
      state.mfaTarget = undefined;
      return state;
    },
    setProfileState: (state, data) => {
      state.profile_state = data.payload;
    },
    setCustomerConfigState: (state, data) => {
      state.customerConfig_state = data.payload;
    },
    setIsPasswordMatchRequirements: (state, data) => {
      state.isPasswordMatchRequirements = data.payload;
    },
    setDisplayCompanyLogo: (state, data) => {
      state.displayCompanyLogo = data.payload;
    }
  },
  extraReducers: {
    [startFetchCustomerConfigs.pending]: (state, action) => {
      trace('[startFetchCustomerConfigs.pending]');
      state.customerConfigThreadCounter = state.customerConfigThreadCounter + 1;
      state.customerConfig_state = LoadingState.LOADING;
      return state;
    },
    [startFetchCustomerConfigs.fulfilled]: (state, action) => {
      trace('[startFetchCustomerConfigs.fulfilled]', state, action);
      if (action.payload) {
        state.customerConfig = action.payload;
        const logo = _.get(action.payload, 'logo');
        if (logo) {
          state.customerConfig.logo = `${logo}?ts=${Date.now()}`;
        }
      }
      state.customerConfig_state = LoadingState.LOADED;
      state.customerConfigThreadCounter = state.customerConfigThreadCounter - 1;
      return state;
    },
    [startFetchCustomerConfigs.rejected]: (state, action) => {
      trace('[startFetchCustomerConfigs.rejected]');
      state.customerConfig = undefined;
      state.customerConfig_state = LoadingState.LOADED;
      state.customerConfigThreadCounter = state.customerConfigThreadCounter - 1;
      return state;
    },
    [startLogin.pending]: (state, action) => {
      trace('[startLogin.pending]');
    },
    [startLogin.fulfilled]: (state, action) => {
      trace('[loginAccount.fulfilled]', state, action);
      const {token, userAgentId} = processToken(action.payload);
      state.token = token;
      if (userAgentId) {
        // Extract userAgentId
        state.userAgentId = userAgentId;
      }
      return state;
    },
    [startLogin.rejected]: (state, action) => {
      trace('[startLogin.rejected]');
    },
    [startGetProfile.pending]: (state) => {
      trace('[startGetProfile.pending]');
      state.profile_state = LoadingState.LOADING;
      state.profileThreadCounter = state.profileThreadCounter + 1;
      return state;
    },
    [startGetProfile.fulfilled]: (state, action) => {
      trace('[startGetProfile.fulfilled]', action);
      if (action.payload) {
        state.profile = action.payload;
      }
      state.profile_state = LoadingState.LOADED;
      state.profileThreadCounter = state.profileThreadCounter - 1;
      return state;
    },
    [startGetProfile.rejected]: (state) => {
      trace('[startGetProfile.rejected]');
      state.profile_state = LoadingState.LOADED;
      state.profileThreadCounter = state.profileThreadCounter - 1;
      return state;
    },
    [startMfaChallenge.pending]: (state) => {
      trace('[startMfaChallenge.pending]');
      return state;
    },
    [startMfaChallenge.fulfilled]: (state, action) => {
      trace('[startMfaChallenge.fulfilled]', action);
      state.mfaTarget = action.payload.target;
      return state;
    },
    [startMfaChallenge.rejected]: (state) => {
      trace('[startMfaChallenge.rejected]');
      return state;
    },
    [startMfaValidate.pending]: (state) => {
      trace('[startMfaValidate.pending]');
      return state;
    },
    [startMfaValidate.fulfilled]: (state, action) => {
      trace('[startMfaValidate.fulfilled]', action);
      const {token, userAgentId} = processToken(action.payload);
      state.token = token;
      if (userAgentId) {
        // Extract userAgentId
        state.userAgentId = userAgentId;
      }
      state.mfaToken = undefined;
      state.mfaTarget = undefined;
      return state;
    },
    [startMfaValidate.rejected]: (state) => {
      trace('[startMfaValidate.rejected]');
      return state;
    },
    [startCreatePassword.pending]: (state) => {
      trace('[startCreatePassword.pending]');
      return state;
    },
    [startCreatePassword.fulfilled]: (state, action) => {
      trace('[startCreatePassword.fulfilled]', action);
      return state;
    },
    [startCreatePassword.rejected]: (state) => {
      trace('[startCreatePassword.rejected]');
      return state;
    },
    [startSendResetPasswordEmail.pending]: (state) => {
      trace('[startSendResetPasswordEmail.pending]');
      return state;
    },
    [startSendResetPasswordEmail.fulfilled]: (state, action) => {
      trace('[startSendResetPasswordEmail.fulfilled]', action);
      return state;
    },
    [startSendResetPasswordEmail.rejected]: (state) => {
      trace('[startSendResetPasswordEmail.rejected]');
      return state;
    }
  }
});

function processToken(token) {
  if (!token) return undefined;

  logger('[processToken]', token);
  const {expires_in: expiresIn} = token;
  // calculate when the token will expire.
  const expiresAt = moment().add(expiresIn, 'seconds');
  logger(`Token expires_in: ${expiresIn}, expires_at: ${expiresAt}`);
  Object.assign(token, {
    expires_at: expiresAt.valueOf()
  });
  const userAgentId = _.get(token, 'user_agent_id');
  return {token, userAgentId};
}

export const {
  logout: doLogout,
  updateToken: doUpdateToken,
  updateLoginPolicy: doUpdateLoginPolicy,
  updateMfaToken: doUpdateMfaToken,
  clearMfaData: doClearMfaData,
  setProfileState,
  setCustomerConfigState,
  setIsPasswordMatchRequirements,
  setDisplayCompanyLogo
} = slice.actions;

export const loginSuccessfully = async (options) => {
  const {dispatch, history, portal} = options;
  const result = await dispatch(startGetProfile());
  const status = _.get(result, 'meta.requestStatus');
  if (status === 'fulfilled') {
    if (portal === 'corporate') {
      if (hasPermission(['CUST_ADMIN', 'CUST_VIEWER'])) {
        history.push('/');
        addToast('You have successfully Logged in', 'success');
      } else {
        addToast('You have no permission to access corporate portal', 'error');
      }
    } else {
      history.push('/');
      addToast('You have successfully Logged in', 'success');
    }
  }
};

export const startLogout = async (options) => {
  const {dispatch, history, showToast = false} = options;
  await dispatch(doLogout());
  // Disconnect IoT
  disconnect({dispatch});
  const result = await dispatch(
    startFetchCustomerConfigs({
      domain: window.location.href,
      dispatch
    })
  );
  const status = _.get(result, 'meta.requestStatus');
  if (status === 'fulfilled') {
    history.push('/auth/login');
    if (showToast) {
      addToast('You have successfully Logged out', 'success');
    }
  }
};

export const GrantType = Object.freeze({
  PASSWORD: 'password',
  CODE: 'authorization_code',
  MFA: 'http://velopcloud.com/grant/mfa-validate',
  REFRESH_TOKEN: 'refresh_token'
});
