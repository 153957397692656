import React from "react";
import _ from "lodash";
import {
  COMMAND_STATUS,
  ISP_ACTIONS,
  selectAllSpeedTestResults,
  selectAllSpeedTestResultsLoadingState,
  selectIspAction,
  selectSpeedTestCommandStatus
} from "./_speedsSlice";
import {useSelector} from "react-redux";
import {IspInfo} from "./IspInfo";
import {SpeedTestProcess} from "./SpeedTestProcess";
import {SpeedTestRunning} from "./SpeedTestRunning";
import {SpeedTestNoRecord} from "./SpeedTestNoRecord";
import {SpeedTestResult} from "./SpeedTestResult";
import {SpeedTrends} from "./SpeedTrends";
import {SpeedTestAllResults} from "./SpeedTestAllResults";
import {LoadingOverlay} from "../loader/LoadingOverlay";
import {LoadingState} from "../common/util";
import {selectViewTopology} from "../topology/index";

export function RenderSpeedsPage({isIspEditable}) {
  const resultsLoadingState = useSelector(selectAllSpeedTestResultsLoadingState);
  const results = useSelector(selectAllSpeedTestResults);
  const topology = useSelector(selectViewTopology);
  const commandStatus = useSelector(selectSpeedTestCommandStatus);
  const ispAction = useSelector(selectIspAction);
  if (commandStatus === COMMAND_STATUS.RUNNING) {
    return (
      <>
        <section className='mt-7'>
          <IspInfo editable={isIspEditable}/>
        </section>
        <section className='mt-7'>
          <SpeedTestProcess>
            <SpeedTestRunning nodeName={topology?.root?.friendlyName}/>
          </SpeedTestProcess>
        </section>
      </>
    );
  } else if (resultsLoadingState === LoadingState.LOADED && _.isEmpty(results)) {
    return (
      <>
        <section className='mt-7'>
          <IspInfo editable={isIspEditable}/>
        </section>
        <section className='mt-7'>
          <SpeedTestProcess>
            <SpeedTestNoRecord/>
          </SpeedTestProcess>
        </section>
      </>
    );
  } else if (resultsLoadingState === LoadingState.LOADED && !_.isEmpty(results)) {
    return (
      <>
        <section className='mt-7'>
          <IspInfo editable={isIspEditable}/>
        </section>
        {
          ispAction === ISP_ACTIONS.VIEW &&
          <>
            <section className='mt-7'>
              <SpeedTestResult/>
            </section>
            <section className='mt-5'>
              <SpeedTrends/>
            </section>
            <section className='mt-5'>
              <SpeedTestAllResults/>
            </section>
          </>
        }

      </>
    );
  } else {
    return (
      <LoadingOverlay
        initialized={false}
        className='mt-6 display-1 bg-transparent d-flex justify-content-center' />
    );
  }
}