import React, {useEffect, useState} from 'react';
import {Button} from 'react-bootstrap';
import _ from 'lodash';
import {hasPermission} from '../auth/permission/PermissionUtils';

export const SfButton = React.forwardRef((props, ref) => {
  const type = _.get(props, 'type', '');
  const children = _.get(props, 'children', '');
  const disabled = _.get(props, 'disabled', undefined); // Input from parent component
  const displayWithRole = _.get(props, 'displayWithRole', []);
  const enableWithRole = _.get(props, 'enableWithRole', []);
  const [hidden, setHidden] = useState(false);
  const [_disabled, setDisabled] = useState(false); // Control Button
  useEffect(() => {
    if (disabled === undefined) {
      if (_.isEmpty(enableWithRole)) {
        setDisabled(false);
      } else {
        setDisabled(!hasPermission(enableWithRole));
      }
    } else {
      // Input disable is not empty
      setDisabled(disabled);
    }
  }, [enableWithRole, disabled]);
  useEffect(() => {
    if (!_.isEmpty(displayWithRole)) {
      setHidden(!hasPermission(displayWithRole));
    }
  }, [displayWithRole]);
  // Since props is a readonly object, we need to Clone a object and remove customized attributes.
  const _props = { ...props };
  delete _props.enableWithRole;
  delete _props.displayWithRole;
  return (
    !hidden && (
      <Button ref={ref} {..._props} type={type} disabled={_disabled}>
        {children}
      </Button>
    )
  );
});
