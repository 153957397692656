import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDevicesLoadingState } from './_devicesSlice';
import { PeopleAvatar } from 'lsui-components';
import { selectViewTopology, selectViewTopologyLoadingState, startFetchTopology } from "../topology/_topologySlice";
import { LoadingState } from "../common/util";
import { RemoteTable } from "../data/RemoteTable";
import {
  fetchNodeClientDetails,
  selectNodeClientDetails,
  selectNodeClientDetailsStatus
} from "./nodeClientDetails/_nodeClientDetailsSlice";
import NodeClientDetailsView from "./nodeClientDetails/NodeClientDetailsView";

const COLUMN_WIDTHS = [ 400, 152, 128, 192, 192 ];
const SIGNAL_THRESHOLDS_RSSI = [ -50, -60, -70, -100 ];
const SIGNAL_STRENGTH_ICONS = [
  <i className="sf-icon-wifi-signal-4" />,
  <i className="sf-icon-wifi-signal-3" />,
  <i className="sf-icon-wifi-signal-2" />,
  <i className="sf-icon-wifi-signal-1" />,
];

const rowDecorator = (row, {onItemClickHandler, commonClassName}) => {
  return {
    className: (row?.original?.id !== '' && row?.original?.id != null && onItemClickHandler != null) ?
      `${commonClassName} cursor-pointer` :
      commonClassName
  };
}

export function DeviceList({ networkId, devices, status = 'online' }) {
  const dispatch = useDispatch();
  const devicesLoadingState = useSelector(selectDevicesLoadingState);
  const topology = useSelector(selectViewTopology);
  const topologyLoadingState = useSelector(selectViewTopologyLoadingState);
  const [ hasDevices, setHasDevices ] = useState(false);

  const clientDetails = useSelector(selectNodeClientDetails);
  const clientStatus = useSelector(selectNodeClientDetailsStatus);

  // fetch topology if it is not already available
  useEffect(() => {
    if (!topology) {
      dispatch(startFetchTopology({
        networkId: networkId
      }));
    }
  }, [ networkId, topology, dispatch ]);

  // track hasDevices state
  useEffect(() => {
    if (topologyLoadingState === LoadingState.LOADED && devicesLoadingState === LoadingState.LOADED) {
      if (devices?.length > 0) {
        setHasDevices(true);
      }
    }
  }, [ topologyLoadingState, devicesLoadingState, devices ]);

  const columns = useMemo(
    () => [
      {
        Header: 'Device',
        accessor: row => (
          <div className="d-flex align-items-center">
            <div className="mr-3"><PeopleAvatar text={''} /></div>
            <div>{row.name}</div>
          </div>
        ),
        minWidth: COLUMN_WIDTHS[0],
        width: COLUMN_WIDTHS[0],
      },
      {
        Header: 'Signal',
        accessor: row => {
          // transform signal value into icons
          if (row.band === 'ethernet') {
            return status === 'online' ? <i className="sf-icon-ethernet-online" /> : <i className="sf-icon-ethernet-offline" />;
          } else {
            const signalStrength = parseInt(row.rssi, 10);
            const signalLevel = SIGNAL_THRESHOLDS_RSSI.find(val => signalStrength >= val);
            const signalIconIndex = SIGNAL_THRESHOLDS_RSSI.indexOf(signalLevel);
            return SIGNAL_STRENGTH_ICONS[signalIconIndex];
          }
        },
        minWidth: COLUMN_WIDTHS[1],
        width: COLUMN_WIDTHS[1],
      },
      {
        Header: 'Band',
        accessor: row => {
          if (row.band === '5GH' || row.band === '5GL') {
            return '5 GHz';
          } else if (row.band === '2.4G') {
            return '2.4 GHz';
          }
          return row.band;
        },
        minWidth: COLUMN_WIDTHS[2],
        width: COLUMN_WIDTHS[2],
      },
      {
        Header: 'SSID',
        accessor: row => row.ssid,
        minWidth: COLUMN_WIDTHS[3],
        width: COLUMN_WIDTHS[3],
      },
      {
        Header: 'Node',
        accessor: row => {
          if (topology?.root?.id === row.node) {
            return topology.root.friendlyName;
          }
          return topology?.root?.children?.find(node => node.id === row.node)?.friendlyName;
        },
        minWidth: COLUMN_WIDTHS[4],
        width: COLUMN_WIDTHS[4],
      }
    ],
    [ topology, status ]
  );

  //Popup deps
  const [displayPopup, setDisplayPopup] = useState(false);
  const [clientIdForLookup, setclientIdForLookup] = useState('');

  const showDevicePopup = (clientId) => {
    setclientIdForLookup(clientId);
    if (networkId) {
      dispatch(
        fetchNodeClientDetails({
          networkId,
          clientId,
        })
      );
    }

    setDisplayPopup(true);
  }

  const closeFn = () => {
    setDisplayPopup(false);
  }

  return (
    <>
      { topologyLoadingState === LoadingState.LOADED && hasDevices &&
        <RemoteTable
          scrollableTarget={'app-body'}
          columns={columns}
          data={devices}
          loader={() => {}}
          tableHeaderHeight={88}
          onItemClickHandler={row => {
            // if the client doesn't has an id, details cannot be displayed
            if (row.id !== '' && row.id != null) {
              showDevicePopup(row.id);
            }
          }}
          rowDecorator={rowDecorator}
        />
      }
      { topologyLoadingState === LoadingState.LOADED && !hasDevices && status === 'online' && <h4 className="mt-8">No online devices.</h4> }
      { topologyLoadingState === LoadingState.LOADED && !hasDevices && status === 'offline' && <h4 className="mt-8">No offline devices.</h4> }
      { displayPopup && <NodeClientDetailsView client={clientDetails} status={clientStatus} show={displayPopup} closeFunction={closeFn} networkId={networkId} clientId={clientIdForLookup}/> }
    </>
  );
}
