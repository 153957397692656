import React, {useEffect, useState} from 'react';
import {doClearMfaData, doUpdateMfaToken, loginSuccessfully, startMfaChallenge, startMfaValidate} from '../_authSlice';
import {selectMfaTarget, selectMfaVerifyToken} from '../_authSliceSelect';
import {createLogger} from '../../common/util';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Button} from 'react-bootstrap';
import ReactCodeInput from 'react-code-input';
import {CenterContent} from '../CenterContent';
import _ from 'lodash';
import {addToast} from '../../common/toast';
import {unwrapResult} from '@reduxjs/toolkit';
import {portal} from "../../common/env";

const logger = createLogger('ui:mfaValidate');
const trace = createLogger('ui:mfaValidate:trace');
export const MfaValidatePage = () => {
  const mfaVerifyToken = useSelector(selectMfaVerifyToken);
  const mfaTarget = useSelector(selectMfaTarget);
  const history = useHistory();
  useEffect(() => {
    logger('Do MFA Verify, verifyToken: ', mfaVerifyToken);
    if (!mfaVerifyToken) {
      logger('MFA verifyToken is missing, redirect to login page');
      history.push('/auth/login');
    }
  }, []);
  return (
    <CenterContent>
      <VerificationForm mfaToken={mfaVerifyToken} mfaTarget={mfaTarget} />
    </CenterContent>
  );
};

const VerificationForm = ({ mfaToken, mfaTarget }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [remember, setRemember] = useState(false);
  const [hasOtpError, setHasOtpError] = useState(false); // Control has-error on PIN input.
  const [otpErrorMsg, setOtpErrorMsg] = useState(''); // Display OTP error message on UI.
  const [isOtpExpired, setOtpExpired] = useState(false); // Display `Request a new one`
  const [otpCode, setOtpCode] = useState('');
  const [disabled, setDisabled] = useState(false);
  const handleRememberClick = () => setRemember(!remember);
  const handleCodeChange = (code) => {
    trace('Code changed: ', code);
    setOtpCode(code);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setOtpErrorMsg('');
    if (otpCode.length < 6) {
      logger('code length is less than 6:', otpCode.length);
      setHasOtpError(true);
      return;
    }
    setDisabled(true);
    dispatch(
      startMfaValidate({
        verificationToken: mfaToken.value,
        rememberUserAgent: remember,
        otp: otpCode
      })
    )
      .then(unwrapResult)
      .then(() => {
        loginSuccessfully({ dispatch, history, portal });
      })
      .catch((error) => {
        logger('Validate failed:', error);
        if (error.code === 'ERR_CODE_VERIFICATION_EXPIRED') {
          // Expired flow: extract new token from response data -> update new token to redux -> waiting for challenge again
          const newMfaToken = _.get(error, 'parameters[0]');
          dispatch(doUpdateMfaToken(newMfaToken));
          setOtpErrorMsg('Your code has expired.');
          setOtpExpired(true);
        } else if (error.code === 'ERR_CODE_INVALID_VERIFICATION_TOKEN') {
          setOtpErrorMsg('Code incorrect. Try again.');
          setHasOtpError(true);
        } else {
          // Unknown errors, redirect to login page.
          logger('Unknown MFA validate error, redirect to login page. ', error);
          addToast(error.message, 'error');
          dispatch(doClearMfaData());
          history.push('/auth/login');
        }
      })
      .finally(() => {
        setDisabled(false);
      });
  };
  const handleResendClick = (e) => {
    e.preventDefault();
    doChallenge()
      .then(() => {
        addToast('Code resent', 'success');
      })
      .catch((error) => addToast(error.message, 'error'));
  };
  const handleSendNewCode = (e) => {
    e.preventDefault();
    doChallenge()
      .then(() => {
        addToast('New code sent', 'success');
      })
      .catch((error) => addToast(error.message, 'error'));
  };
  async function doChallenge() {
    const action = await dispatch(
      startMfaChallenge({
        mfaToken,
        type: 'PUSH_CODE',
        method: 'EMAIL',
        history
      })
    );
    try {
      unwrapResult(action);
      setOtpExpired(false);
      setOtpErrorMsg('');
      logger('MFA challenge successfully');
    } catch (error) {
      // Catch known error codes
      if (error.code === 'ERR_CODE_CHALLENGE_EXCEEDS_MAX_RETRIES') {
        throw error;
      } else {
        // Unknown error
        logger('Unknown MFA challenge error, redirect to login page. ', error);
        dispatch(doClearMfaData());
        history.push('/auth/login');
      }
    }
  }
  return (
    <div className='mfa-validate'>
      <section>
        <h2>Login in from new device detected</h2>
      </section>
      <section className='mt-5'>
        <form onSubmit={handleSubmit}>
          <div className='sf-font-h5'>
            We sent a unique verification code to <br />
            <span className='sf-text-primary sf-font-h5'>{mfaTarget}</span>
          </div>
          <div className='mt-5'>
            <ReactCodeInput
              type='number'
              fields={6}
              className={`${hasOtpError ? 'input-pin has-error' : 'input-pin'} sf-input-p-0`}
              name='OTP'
              touch={(name) => {
                trace('touch is called!', name);
              }}
              untouch={(name) => {
                trace('untouch is called!', name);
                setHasOtpError(false);
              }}
              onChange={handleCodeChange}
            />
            {(otpErrorMsg || isOtpExpired) && (
              <div className='mt-4'>
                <span className='sf-text-c-red'>{otpErrorMsg}</span>{' '}
                {isOtpExpired && (
                  <a href='#' onClick={handleSendNewCode}>
                    Request a new one
                  </a>
                )}
              </div>
            )}
          </div>
          <div className='mt-6'>
            Didn’t receive a code?{' '}
            <a className='sf-text-primary sf-font-h5' href='#' onClick={handleResendClick}>
              Resend
            </a>
          </div>
          <div className='form-group form-check mt-6'>
            <input
              className='form-check-input'
              type='checkbox'
              id='remember'
              onChange={handleRememberClick}
              checked={remember}
              style={{ marginTop: '0.1rem' }}
            />
            <label className='form-check-label' htmlFor='remember'>
              Remember this device for 30 days
            </label>
          </div>
          <div className='mt-6 row'>
            <div className='col-12'>
              <Button variant='primary' type='submit' className='sf-btn-w-xlg' onClick={handleSubmit} disabled={disabled}>
                Log in
              </Button>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
};
