import React from 'react';
import {useHistory} from 'react-router-dom';

export const LinkForgotPassword = ({ text = 'Forgot Password' }) => {
  const history = useHistory();
  return (
    <div>
      <a
        className='sf-text-primary sf-font-h5'
        href='#'
        onClick={(e) => {
          e.preventDefault();
          history.push('/auth/reset-password');
        }}
      >
        {text}
      </a>
    </div>
  );
};
