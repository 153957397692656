import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { envBaseApiUrl } from "../common/env";
import { addToast } from '../common/toast';
import { composeUrl, unwrapErrorResponse } from "../common/util";
import { getAxiosInstance as axios } from '../auth/axios/axiosCommon';
import { getStorageValue } from '../common/localStorageUtils';

const STORAGE_KEY_REBOOTING = 'rebooting';
const initialState = {
  loading: false,
  hasErrors: false,
  nodeDetails: {},
  firmwareUpdateSettings: {},
  rebooting: getStorageValue(STORAGE_KEY_REBOOTING, false)
};

const nodeDetailsSlice = createSlice({
  name: 'nodeDetails',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    hideLoading: (state) => {
      state.loading = false;
    },
    getNodeDetailsSuccess: (state, { payload }) => {
      state.nodeDetails = payload;
      state.hasErrors = false;
    },
    getNodeDetailsFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    setNodeName: (state, { payload }) => {
      state.nodeDetails.name = payload.name;
    },
    checkFirmwareUpdateStatusFinished: (state, { payload }) => {
      state.firmwareUpdateSettings = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    rebootingStarted: (state) => {
      state.loading = false;
      state.rebooting = true;
    },
    rebootingFinished: (state) => {
      state.loading = false;
      state.rebooting = false;
    }
  }
});

export const fetchNodeDetails = createAsyncThunk(
  'topo/fetchNodeDetails',
  async ({ deviceId, networkId, orgId, masterMacAddress }, { dispatch, getState }) => {
    const state = getState();
    const url = composeUrl(`${envBaseApiUrl}/device-service/rest/devices/${deviceId}/details`, { orgId, networkId });

    if (state.nodeDetails.loading) {
      dispatch(showLoading());
      return;
    }

    dispatch(showLoading());
    try {
      const response = await axios().get(url);
      response.data.isMaster = response.data.macAddress === masterMacAddress;
      dispatch(getNodeDetailsSuccess(response.data));
      dispatch(
        checkFirmwareUpdateStatus({
          networkId,
          macAddress: response.data.macAddress
        })
      );
    } catch (error) {
      console.log("nodeDetailsSlice: " +error);
      dispatch(getNodeDetailsFailure(error));
      throw unwrapErrorResponse(error);
    }
  }
);

export const editNodeName = createAsyncThunk('node/editNodeName', async ({ name, deviceId, networkId, orgId }, { dispatch }) => {
  const url = composeUrl(`${envBaseApiUrl}/device-service/rest/devices/${deviceId}`, { orgId, networkId });

  dispatch(showLoading());
  try {
    await axios().put(url, { name });
    dispatch(setNodeName({ name }));
    dispatch(hideLoading());
  } catch (error) {
    dispatch(hideLoading());
    throw unwrapErrorResponse(error);
  }
});

export const checkFirmwareUpdateStatus = createAsyncThunk('node/fwUpdateStatus', async ({ networkId, macAddress }, { dispatch }) => {
  const url = `${envBaseApiUrl}/network-service/rest/networks/${networkId}/fwupdates/nodes/${macAddress}`;

  dispatch(showLoading());
  try {
    const response = await axios().get(url);
    dispatch(checkFirmwareUpdateStatusFinished(response.data));
  } catch (error) {
    // The endpoint will return error if no FW update is available
    dispatch(checkFirmwareUpdateStatusFinished({}));
    throw unwrapErrorResponse(error);
  }
});

export const rebootNode = createAsyncThunk('node/reboot', async ({ deviceId, networkId }, { dispatch }) => {
  const url = `${envBaseApiUrl}/network-delegation-service/rest/networks/${networkId}/devices/${deviceId}/command`;

  dispatch(showLoading());
  try {
    await axios().post(url, {
      cmd: 'reboot',
      data: {}
    });
    dispatch(rebootingStarted());
    addToast('Restarting node', 'info');
  } catch (error) {
    dispatch(rebootingFinished(error));
    throw unwrapErrorResponse(error);
  }
});

export const restartWiFiSignal = createAsyncThunk('node/restartWiFi', async ({ deviceId, networkId }, { dispatch }) => {
  const url = `${envBaseApiUrl}/network-delegation-service/rest/networks/${networkId}/devices/${deviceId}/command`;

  dispatch(showLoading());
  try {
    await axios().post(url, {
      cmd: 'wifiRestart',
      data: {}
    });
    dispatch(hideLoading());
    addToast('Restarting WiFi signal', 'info');
  } catch (error) {
    dispatch(hideLoading());
    throw unwrapErrorResponse(error);
  }
});

export const {
  showLoading,
  hideLoading,
  getNodeDetailsSuccess,
  getNodeDetailsFailure,
  setNodeName,
  rebootingStarted,
  rebootingFinished,
  checkFirmwareUpdateStatusFinished
} = nodeDetailsSlice.actions;
export const selectNodeDetails = (state) => state.nodeDetails.nodeDetails;
export const nodeDetailsIsMaster = (state) => state.nodeDetails.nodeDetails?.isMaster;
export const nodeDetailsSku = (state) => state.nodeDetails.nodeDetails?.sku;
export const nodeDetailsSerialNumber = (state) => state.nodeDetails.nodeDetails?.serialNumber;
export const nodeDetailsFirmwareVersion = (state) => state.nodeDetails.nodeDetails?.firmwareVersion;
export const nodeDetailsFirmwareUpdateAvailable = (state) => state.nodeDetails.firmwareUpdateSettings?.availableFwVersion;
export const nodeDetailsIsOnline = (state) => state.nodeDetails.nodeDetails?.isOnline;
export const nodeDetailsName = (state) => state.nodeDetails.nodeDetails?.name;
export const nodeDetailsWanAddress = (state) => state.nodeDetails.nodeDetails?.wan?.address;
export const nodeDetailsLanAddress = (state) => state.nodeDetails.nodeDetails?.lan?.main?.address;
export const nodeDetailsStatus = (state) => {
  return {
    loading: state.nodeDetails.loading,
    hasErrors: state.nodeDetails.hasErrors,
    isNodeDetailsEmpty: !Object.keys(state?.nodeDetails?.nodeDetails).length,
    rebooting: state.nodeDetails.rebooting
  };
};

export const nodeDetailsReducer = nodeDetailsSlice.reducer;
