import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {Button, Form} from 'react-bootstrap';
import useNodeClientAssets from './_nodeClientHooks';
import {editNameAndIcon} from './_nodeClientDetailsSlice';
import {useDispatch} from 'react-redux';

const NodeClientDetailsEditView = ({ client, clientId, networkId, status, show, closeFunction }) => {
  const {
    name: clientDeviceFriendlyName,
    logoId
  } = client;
  const { defaultIcon, nodeClientIcons } = useNodeClientAssets();
  const [ selectedIcon, setSelectedIcon ] = useState(logoId || defaultIcon.id)
  const dispatch = useDispatch();
  const save = (data) => {
    const finalLogoId = data.logoId || defaultIcon.id;
    dispatch(
      editNameAndIcon({
        name: data.name,
        logoId: finalLogoId,
        networkId,
        clientId: clientId
      })
    );
    closeFunction();
  };
  const updateIcon = (selectedIconId) => {
    setSelectedIcon(selectedIconId);
  };
  const ClientDeviceNameIconForm = ({ save, closeDialog }) => {
    const {
      register,
      handleSubmit,
      formState: { errors }
    } = useForm();

    const imageOptionsField = nodeClientIcons.map((iconDescriptor) => {
      return (
        <>
          <div key={iconDescriptor.id} id={'id-' + iconDescriptor.id} className={'sf-square-md device-icon' + (iconDescriptor.id === selectedIcon ? ' selected-client-device-icon' : '')}>
            <img src={iconDescriptor.iconSvg} alt={iconDescriptor.altText} onClick={ () => updateIcon(iconDescriptor.id) } />
          </div>
        </>
      );
    });
    return (
      <Form onSubmit={handleSubmit(save)}>
        <Form.Label className="sf-text-secondary">Name</Form.Label>
        <Form.Control
          name="name"
          type="text"
          defaultValue={clientDeviceFriendlyName}
          {...register('name', { required: true })}
          className={errors.name ? 'is-invalid shake' : ''}
        />
        {errors.name && <div className="invalid-feedback d-block">Please provide a valid name.</div>}
        <h4 className="mt-4 mb-3">Icon</h4>
        <Form.Control
          name="logoId"
          type="hidden"
          defaultValue={selectedIcon}
          {...register('logoId', {})}
          className={errors.name ? 'is-invalid shake' : ''}
        />
        <div className="image-icon-container d-inline-flex flex-row justify-content-between modal-content p-0">
          {imageOptionsField}
        </div>
        <div className="justify-content-start modal-footer p-0 mt-6">
          <Button variant="secondary" className="sf-btn-w-default mr-4" onClick={closeDialog}>
            Cancel
          </Button>
          <Button variant="primary" className="sf-btn-w-default" type="submit">
            Save
          </Button>
        </div>
      </Form>
    );
  };

  return (
    <ClientDeviceNameIconForm save={save} closeDialog={closeFunction}/>
  );
}

export default NodeClientDetailsEditView;
