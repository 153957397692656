import React from "react";
import SpeedTestConnecting from "../../assets/speeds/speedtest-connecting.gif";
import SpeedTestInternetGlobe from "../../assets/speeds/speedtest-internet-globe.png";
import SpeedTestNode from "../../assets/speeds/speedtest-node.png";

export const SpeedTestRunning = ({nodeName = 'Node'}) => {
  return (
    <div className='py-8 d-flex align-items-center justify-content-center flex-column'>
      <h4>Testing Upload Speed...</h4>
      <div className='my-5'>
        <div className='mt-4'>
          <img alt='' src={SpeedTestNode}/>
          <img alt='' src={SpeedTestConnecting} className='ml-2'/>
          <img alt='' src={SpeedTestInternetGlobe} className='ml-2'/>
          <div className='d-flex justify-content-between mt-5 mr-n2 ml-n7'>
            <div className='d-flex flex-column align-items-center'>
              <div className='sf-font-h4'>
                {nodeName}
              </div>
              <div className='sf-font-body-3'>
                Connected to Internet
              </div>
            </div>
            <div className='sf-font-h4'>Internet</div>
          </div>
        </div>
      </div>
    </div>
  );
}