import React from 'react';

export const LicenseTerms = () => {
  return (
    <div>
      <div className='sf-font-body3'>
        By continuing you agree to accept the{' '}
        <a
          href='#'
          onClick={(e) => {
            e.preventDefault();
            window.open('https://www.linksys.com/us/end-user-license-agreement', '_blank').focus();
          }}
        >
          <strong>License Terms</strong>
        </a>
      </div>
    </div>
  );
};
