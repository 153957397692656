import React, {useEffect, useMemo, useState} from "react";
import _ from "lodash";
import nodeImage from "../../assets/fwUpdate/node.png";
import {useSelector} from "react-redux";
import {selectNetworkDevices, selectNetworkDevicesLoadingState} from "../network/_networkSlice";
import {LoadingOverlay} from "../loader/LoadingOverlay";
import {LoadingState} from "../common/util";
import {RemoteTable} from "../data/RemoteTable";

const nodeColumns = () => [
  {
    Header: 'Name',
    accessor: row => {
      const name = _.get(row, 'name', '');
      return name;
    },
    Cell: (cell) => {
      const name = _.get(cell.row.original, 'name', '')
      return (
        <div className="d-flex justify-content-start align-items-center">
          <img alt='' src={nodeImage}/>
          <div className='ml-3'>{name}</div>
        </div>
      )
    },
    sortType: 'basic',
    minWidth: 150,
    width: 200,
  },
  {
    Header: 'version',
    accessor: row => {
      const version = _.get(row, 'version', '');
      return version;
    },
    sortType: 'basic',
    minWidth: 100,
    width: 180,
  },
  {
    Header: 'model',
    accessor: row => {
      const model = _.get(row, 'model', '');
      return model;
    },
    sortType: 'basic',
  },
]

export const NodeList = () => {
  const networkDeviceLoadingState = useSelector(selectNetworkDevicesLoadingState);
  const [nodes, setNodes] = useState([]);
  const load = useMemo(() => async () => {
    return null;
  }, []);

  const devices = useSelector(selectNetworkDevices);
  useEffect(() => {
    if (devices != null && devices.length > 0) {
      setNodes((p) => {
        p = [];
        devices.forEach((d) => {
          p.push({name: d.name, model: d.sku, version: d.currentFwVersion});
        });
        return p;
      });
    }
  }, [devices]);
  return (
    <div className='mt-4'>
      <LoadingOverlay initialized={networkDeviceLoadingState === LoadingState.LOADED}
                      className="display-5 d-flex rounded-lg">
        {networkDeviceLoadingState === LoadingState.LOADED &&
        <RemoteTable className={nodes?.length === 0 ? "d-none" : "sf-bg-gray-2 rounded"}
                     scrollableTarget="customer-device-app-body"
                     columns={nodeColumns()} data={nodes}
                     loader={load} tableHeaderHeight={59}/>
        }
      </LoadingOverlay>
    </div>
  );
}