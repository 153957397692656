import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { getAxiosInstance as axios } from "../auth/axios/axiosCommon";
import { composeUrl, createLogger, LoadingState, unwrapErrorResponse } from "../common/util";
import { envBaseApiUrl } from "../common/env";

const logger = createLogger('vpn:slice');

export const VPN_SETUP_STEP = Object.freeze({
  INTRODUCTION: 0,
  CONFIGURE: 1,
  CONNECTING: 2,
  CONNECTED: 3
});

export const startFetchAccountVpnOption = createAsyncThunk(
  'vpn/startFetchAccountVpnOption',
  async (input, thunkAPI) => {
    logger('[startFetchAccountVpnOption] begin: %o', input);
    const { orgId, accountId } = input;
    const url = composeUrl(`${envBaseApiUrl}/user-service/rest/accounts/${accountId}/vpn-options`, {
      orgId: orgId
    });
    return axios().get(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw unwrapErrorResponse(error);
      })
  }
);

export const startFetchAllVpnOptions = createAsyncThunk(
  'vpn/startFetchAllVpnOptions',
  async (input, thunkAPI) => {
    logger('[startFetchAllVpnOptions] begin: %o', input);
    const { orgId } = input;
    const url = composeUrl(`${envBaseApiUrl}/organization-service/rest/organizations/${orgId}/vpnoptions`);
    return axios().get(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw unwrapErrorResponse(error);
      })
  }
);

export const startEditSelfVpnOptions = createAsyncThunk(
  'vpn/editSelfVpnOptions',
  async (input, thunkAPI) => {
    logger('[editSelfVpnOptions] begin: %o', input);
    const { orgId, ...rest } = input;
    const url = composeUrl(`${envBaseApiUrl}/user-service/rest/accounts/self/vpn-options`, {
      orgId: orgId
    });
    return axios().put(url, rest)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw unwrapErrorResponse(error);
      })
  }
);

export const vpnSlice = createSlice({
  name: 'vpn',
  initialState: {
    step: VPN_SETUP_STEP.INTRODUCTION
  },
  reducers: {
    updateVpnSetupStep: (state, action) => {
      state.step = action.payload;
      return state;
    },
  },
  extraReducers: {
    [startFetchAccountVpnOption.pending]: (state, action) => {
      logger("startFetchAccountVpnOption.pending: %o, %o", state, action);
      state.selfOptionLoadingState = LoadingState.LOADING;
    },
    [startFetchAccountVpnOption.fulfilled]: (state, action) => {
      logger("startFetchAccountVpnOption.fulfilled: %o, %o", state, action);
      state.selfOption = action.payload;
      state.selfOptionLoadingState = LoadingState.LOADED;
    },
    [startFetchAccountVpnOption.rejected]: (state, action) => {
      logger("startFetchAccountVpnOption.rejected: %o, %o", state, action);
      state.selfOption = null;
      state.selfOptionLoadingState = LoadingState.LOADED;
    },
    [startFetchAllVpnOptions.pending]: (state, action) => {
      logger("startFetchAllVpnOptions.pending: %o, %o", state, action);
    },
    [startFetchAllVpnOptions.fulfilled]: (state, action) => {
      logger("startFetchAllVpnOptions.fulfilled: %o, %o", state, action);
      state.options = action.payload;
    },
    [startFetchAllVpnOptions.rejected]: (state, action) => {
      logger("startFetchAllVpnOptions.rejected: %o, %o", state, action);
    },
    [startEditSelfVpnOptions.pending]: (state, action) => {
      logger("startEditSelfVpnOptions.pending: %o, %o", state, action);
      state.selfOptionLoadingState = LoadingState.NONE;
    },
    [startEditSelfVpnOptions.fulfilled]: (state, action) => {
      logger("startEditSelfVpnOptions.fulfilled: %o, %o", state, action);
    },
    [startEditSelfVpnOptions.rejected]: (state, action) => {
      logger("startEditSelfVpnOptions.rejected: %o, %o", state, action);
    },
  }
})

export const selectVpnSetupStep = state => state?.vpn?.step;
export const selectVpnOptions = state => _.get(state, 'vpn.options', []);
export const selectVpnSelfOption = state => _.get(state, 'vpn.selfOption', []);
export const selectVpnSelfOptionLoadingState = state => _.get(state, 'vpn.selfOptionLoadingState', LoadingState.NONE);

export const { updateVpnSetupStep } = vpnSlice.actions;

export const vpnReducer = vpnSlice.reducer;
