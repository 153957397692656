import React, {useEffect} from "react";
import {SvgTopology} from "./toolkits/SvgTopology";
import {useDispatch, useSelector} from "react-redux";
import {LoadingOverlay} from "../loader/LoadingOverlay";
import {createLogger, LoadingState} from "../common/util";
import {doSyncTopologyClientsCount, selectViewTopology, selectViewTopologyLoadingState} from "./_topologySlice";
import {selectNetworkClientsCount} from "../network/_networkSlice";

const logger = createLogger("ui:topology:home:topology");

export const HomeTopology = (
  {
    onNodeClickCb = () => {
    }
  }) => {
  const dispatch = useDispatch();
  const topology = useSelector(selectViewTopology);
  const topologyLoadingState = useSelector(selectViewTopologyLoadingState);
  const networkClientsCount = useSelector(selectNetworkClientsCount);

  useEffect(() => {
    if (topology != null && networkClientsCount != null) {
      logger('Sync network clients count to topology:', topology, networkClientsCount);
      dispatch(doSyncTopologyClientsCount(networkClientsCount));
    }
  }, [networkClientsCount]);
  return (
    <>
      <LoadingOverlay
        initialized={topologyLoadingState !== LoadingState.LOADING}
        className='mt-6 display-1 bg-transparent d-flex justify-content-center'>
        {
          topology ?
            <SvgTopology
              data={topology.root}
              onNodeClick={({id}) => {
                logger('Callback function is called: ', {id});
                onNodeClickCb(id);
              }}/> : null
        }
      </LoadingOverlay>
    </>
  )
};
