import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {getAxiosInstance as axios} from '../auth/axios/axiosCommon';
import { itemNumberComparator } from "./_inventoryComparator";
import _ from "lodash";
import { envBaseApiUrl } from "../common/env";
import { composeUrl, createLogger } from "../common/util";

const logger = createLogger("sfc:inventory:slice");

export const startFetchInventory = createAsyncThunk(
  'inventory/FetchInventory',
  async (input, { rejectWithValue, getState, dispatch }) => {
    logger('[FetchInventory] begin: %o', input);
    const { customerId } = input;
    const url = composeUrl(`${envBaseApiUrl}/inventory-service/rest/organizations/${customerId}/inventory`);

    return axios().get(url)
      .then((response) => {
        logger('get inventory response: %o', response.data);
        return response.data;
      }).catch((error) => {
        logger('get inventory failed: %o', error);
        throw error;
      });
  }
);

export const startFetchUnassignedDevices = createAsyncThunk(
  'inventory/FetchUnassignedDevices',
  async (input, { rejectWithValue, getState, dispatch }) => {
    logger('[FetchUnassignedDevices] begin: %o', input);
    const { customerId } = input;
    const url = composeUrl(`${envBaseApiUrl}/organization-service/rest/organizations/${customerId}/devices`,
      Object.assign({
        assigned: 'UNASSIGNED'
      }, _.pick(input, 'pageSize', 'pageNumber')));

    return axios().get(url)
      .then((response) => {
        logger('get unassigned inventory response: %o', response.data);
        return response.data;
      }).catch((error) => {
        logger('get unassigned inventory failed: %o', error);
        throw error;
      });
  }
);

export const slice = createSlice({
  name: 'inventory',
  reducers: {
    // Manually add inventory, for storybook and testing
    doAddInventory: (state, action ) => {
      logger("Add inventory: %o", action.payload);
      if (state.details == null) {
        state.details = [];
      }
      let data;
      if (!_.isArray(action.payload)) {
        data = [action.payload];
      } else {
        data = action.payload;
      }
      data.forEach(it => {
        state.details.push(Object.assign({
          availableQuantity: 0,
          consumedQuantity: 0,
          hwFactor: 0,
          itemNumber: "default",
          itemType: "SALES_SKU",
          phantomQuantity: 0,
          reservedQuantity: 0,
          usedQuantity: 0
        }, it));
      });
    },
    doResetInventory: (state) => {
      state.details = undefined;
    }
  },
  initialState: {
    details: undefined,
    unassignedDetails: undefined,
    unassignedDetailsMaxPage: -1,
  },
  extraReducers: {
    [startFetchInventory.pending]: (state, action) => {
      logger("startFetchInventory.pending: %o, %o", state, action);
    },
    [startFetchInventory.fulfilled]: (state, action) => {
      logger("startFetchInventory.fulfilled: %o, %o", state, action);
      state.details = action?.payload?.details;
    },
    [startFetchInventory.rejected]: (state, action) => {
      logger("startFetchInventory.rejected: %o, %o", state, action);
    },
    [startFetchUnassignedDevices.pending]: (state, action) => {
      logger("startFetchUnassignedDevices.pending: %o, %o", state, action);
    },
    [startFetchUnassignedDevices.fulfilled]: (state, action) => {
      logger("startFetchUnassignedDevices.fulfilled: %o, %o", state, action);
      const pageNumber = _.get(action, "meta.arg.pageNumber") || 0;
      state.unassignedDetails = state.unassignedDetails || {};
      const page = _.get(action, "payload.data");
      state.unassignedDetails[pageNumber] = page;
      if (pageNumber > state.unassignedDetailsMaxPage) {
        state.unassignedDetailsMaxPage = pageNumber;
      }
    },
    [startFetchUnassignedDevices.rejected]: (state, action) => {
      logger("startFetchUnassignedDevices.rejected: %o, %o", state, action);
    },
  }
});

export const {doAddInventory, doResetInventory} = slice.actions;

export const selectInventory = state => state?.inventory?.details;
export const selectLocalLicenseInventory = state => (state?.inventory?.details || []).filter(it => {
  return it?.itemType === 'LICENSE';
}).sort(itemNumberComparator);
export const selectWarehouseInventory = state => (state?.inventory?.details || []).filter(it => {
  return it?.itemType !== 'LICENSE' && it?.itemType !== 'HW';
}).sort(itemNumberComparator);
export const selectLocalHardwareInventory = state => (state?.inventory?.details || []).filter(it => {
  return it?.itemType === 'HW';
}).sort(itemNumberComparator);
/**
 * @return {available: number}
 */
export const selectInventorySummaryHardware = state => {
  return selectLocalHardwareInventory(state).reduce((acc, cur) => {
    acc.available += cur?.availableQuantity;
    return acc;
  }, {
    available: 0,
  });
};
export const selectUnassignedDevices = state => {
  if (state?.inventory?.unassignedDetailsMaxPage < 0) {
    return [];
  }
  const obj = state?.inventory?.unassignedDetails || {};
  let ar = [];
  for (let i = 0; i <= state?.inventory?.unassignedDetailsMaxPage; i++) {
    ar = ar.concat(obj[i]);
  }
  return ar;
}
