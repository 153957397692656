import _ from 'lodash';
import {envClientTypeId} from '../../common/env';
import {createLogger, isEmpty} from '../../common/util';
import {getAxiosInstance, setAxiosInstance} from './axiosCommon';
import {GrantType, startLogin, startLogout} from '../_authSlice';
import {addToast} from '../../common/toast';

const logger = createLogger('ui:axiosInterceptor');

export const axiosInterceptorSupplier = {
  create: ({ axiosInstance, store, history, dispatch }) => {
    logger('Register axios interceptor');
    setAxiosInstance(axiosInstance);
    axiosInstance.interceptors.request.use(
      (request) => {
        setHeader({ request, store });
        logger('Request:', request);
        return request;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    axiosInstance.interceptors.response.use(
      (response) => {
        logger('Response:', response);
        return response;
      },
      async function (error) {
        const userAgentId = _.get(store.getState(), 'auth.userAgentId');
        if (error?.response?.status === 401) {
          const originalRequest = error.config;
          if (!isEmpty(userAgentId) && !originalRequest._retry) {
            originalRequest._retry = true;
            const refreshToken = _.get(store.getState(), 'auth.token.refresh_token');
            const result = await dispatch(startLogin({ ug: userAgentId, gt: GrantType.REFRESH_TOKEN, r: refreshToken }));
            const status = _.get(result, 'meta.requestStatus');
            if (status === 'fulfilled') {
              setHeader({ request: originalRequest, store, force: true });
              return getAxiosInstance()(originalRequest);
            } else {
              addToast('Your sign in token has expired. Please sign in again to refresh it', 'error', true);
              startLogout({ dispatch, history, showToast: false }).then(() => {});
            }
          } else {
            addToast('Your sign in token has expired. Please sign in again to refresh it', 'error', true);
            startLogout({ dispatch, history, showToast: false }).then(() => {});
          }
        }

        logger('log response error:', error);

        return Promise.reject(error);
      }
    );
  }
};

function setHeader({ request, store, force = false }) {
  const headers = {};
  if (!request.headers.Authorization || force) {
    headers.Authorization = `Bearer ${_.get(store.getState(), 'auth.token.access_token', '')}`;
  }
  if (!request.headers['X-SMB-Client-Type-Id'] || force) {
    headers['X-SMB-Client-Type-Id'] = `${envClientTypeId}`;
  }
  if (!request.headers['X-SMB-Realm-Id'] || force) {
    headers['X-SMB-Realm-Id'] = `${_.get(store.getState(), 'auth.customerConfig.realmId', '')}`;
  }
  if (!request.headers['Content-Type']) {
    headers['Content-Type'] = 'application/json';
  }
  Object.assign(request.headers, headers);
}
