import React from 'react';
import {OAuthType} from '../_oauth';
import {OAuthLoginPage} from './OAuthLoginPage';
import {BasicLogin} from './BasicLogin';
import {useSelector} from 'react-redux';
import {selectLoginPolicy} from '../_authSliceSelect';
import {debug} from 'debug';
import {CenterContent} from '../CenterContent';

const logger = debug('sf:ui:login-page');

export const LoginPage = () => {
  const loginPolicy = useSelector(selectLoginPolicy);
  logger('Login page rendered with login policy:', loginPolicy);
  return <CenterContent>{renderLogin(loginPolicy)}</CenterContent>;
};

export function renderLogin(authType) {
  switch (authType) {
    case OAuthType.AZURE:
      return <OAuthLoginPage />;
    case OAuthType.SMB:
    default:
      return <BasicLogin />;
  }
}
