import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getAxiosInstance as axios} from '../auth/axios/axiosCommon';
import {envBaseApiUrl} from '../common/env';
import {createLogger, LoadingState, unwrapErrorResponse} from '../common/util';
import {setProfileState} from '../auth/_authSlice';

const logger = createLogger('ui:user:slice');

export const startFetchUserPreferences = createAsyncThunk('user/FetchUserPreferences', async () => {
  const url = `${envBaseApiUrl}/user-service/rest/accounts/self/preferences`;

  return await axios()
    .get(url)
    .then((response) => {
      logger('fetch user preferences response: %o', response.data);
      return response.data;
    })
    .catch((error) => {
      logger('fetch user preferences failed: %o', error);
      throw unwrapErrorResponse(error);
    });
});

export const startEditUserName = createAsyncThunk('user/EditUserName', async (args, { dispatch }) => {
  const { firstname, lastname } = args;
  const url = `${envBaseApiUrl}/user-service/rest/accounts/self`;

  return axios()
    .put(url, {
      firstname: firstname,
      lastname: lastname
    })
    .then((response) => {
      logger('Edit username response: %o', response.data);
      dispatch(setProfileState(LoadingState.NONE));
      return response.data;
    })
    .catch((error) => {
      logger('Edit user username failed: %o', error);
      throw unwrapErrorResponse(error);
    });
});

export const startEditHasMFA = createAsyncThunk('user/EditHasMFA', async (args, { dispatch }) => {
  const { hasMFA } = args;
  const url = `${envBaseApiUrl}/user-service/rest/accounts/self`;

  return axios()
    .put(url, {
      hasMFA: hasMFA
    })
    .then((response) => {
      logger('Edit hasMFA response: %o', response.data);
      dispatch(setProfileState(LoadingState.NONE));
      return response.data;
    })
    .catch((error) => {
      logger('Edit hasMFA failed: %o', error);
      throw unwrapErrorResponse(error);
    });
});

export const startEditUserProfileImage = createAsyncThunk('user/EditUserProfileImage', async (args, { dispatch }) => {
  const { avatarUrl } = args;
  const url = `${envBaseApiUrl}/user-service/rest/accounts/self/preferences`;

  return axios()
    .put(url, {
      avatarUrl: avatarUrl
    })
    .then((response) => {
      logger('Edit user preferences response: %o', response.data);
      dispatch(setProfileState(LoadingState.NONE));
      dispatch(startFetchUserPreferences());
      return response.data;
    })
    .catch((error) => {
      logger('Edit user preferences failed: %o', error);
      throw unwrapErrorResponse(error);
    });
});

export const startEditLanguage = createAsyncThunk('user/EditLanguage', async (args, { dispatch }) => {
  const { locale } = args;
  const url = `${envBaseApiUrl}/user-service/rest/accounts/self/preferences`;

  return axios()
    .put(url, {
      locale: locale
    })
    .then((response) => {
      logger('Edit user language response: %o', response.data);
      dispatch(startFetchUserPreferences());
      return response.data;
    })
    .catch((error) => {
      logger('Edit user language failed: %o', error);
      throw unwrapErrorResponse(error);
    });
});

export const startFetchUserLanguageOptions = createAsyncThunk('user/fetchUserLanguageOptions', async (input, thunkAPI) => {
  logger('[fetchUserLanguageOptions] begin: %o', input);
  const url = `${envBaseApiUrl}/user-service/rest/iso-language-codes`;
  return axios()
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw unwrapErrorResponse(error);
    });
});

export const startEditTimezone = createAsyncThunk('user/EditTimezone', async (args, { dispatch }) => {
  const { timezone } = args;
  const url = `${envBaseApiUrl}/user-service/rest/accounts/self/preferences`;

  return axios()
    .put(url, {
      timezone: timezone
    })
    .then((response) => {
      logger('Edit user timezone response: %o', response.data);
      dispatch(startFetchUserPreferences());
      return response.data;
    })
    .catch((error) => {
      logger('Edit user timezone failed: %o', error);
      throw unwrapErrorResponse(error);
    });
});

export const slice = createSlice({
  name: 'user',
  initialState: {},
  reducers: {},
  extraReducers: {
    [startFetchUserPreferences.pending]: (state, action) => {
      logger('startFetchUserPreferences.pending: %o, %o', state, action);
    },
    [startFetchUserPreferences.fulfilled]: (state, action) => {
      logger('startFetchUserPreferences.fulfilled: %o, %o', state, action);
      state.preferences = action?.payload;
    },
    [startFetchUserPreferences.rejected]: (state, action) => {
      logger('startFetchLanguageOptions.rejected: %o, %o', state, action);
    },
    [startFetchUserLanguageOptions.pending]: (state, action) => {
      logger('startFetchUserLanguageOptions.pending: %o, %o', state, action);
    },
    [startFetchUserLanguageOptions.fulfilled]: (state, action) => {
      logger('startFetchUserLanguageOptions.fulfilled: %o, %o', state, action);
      state.languageOptions = action?.payload;
    },
    [startFetchUserLanguageOptions.rejected]: (state, action) => {
      logger('startFetchUserLanguageOptions.rejected: %o, %o', state, action);
    }
  }
});

export const selectUserPreferences = (state) => state?.user?.preferences;
export const selectUserLanguageOptions = (state) => _.get(state, 'user.languageOptions', []);
