export const getStorageValue = (key, defaultValue) => {
  if (window.localStorage) {
    try {
      const value = JSON.parse(window.localStorage.getItem(key));
      return value;
    } catch (e) {
      return defaultValue;
    }
  }
  return defaultValue;
};

export const setStorageValue = (key, value) => {
  if (window.localStorage) {
    if (value !== null) {
      window.localStorage.setItem(key, JSON.stringify(value));
    } else {
      console.warn(`setStorageValue: null value for ${key}`);
    }
  }
};

export const removeStorageValue = (key) => {
  if (window.localStorage) {
    window.localStorage.removeItem(key);
  }
};
