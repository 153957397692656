import React, {useEffect, useRef} from 'react';
import * as d3 from 'd3';
import _ from 'lodash';
import global from "../../../assets/topology/globe.svg"
import RedGlobal from "../../../assets/topology/globe-red.svg";
import onlineLight from "../../../assets/topology/online-light.svg";
import offlineLight from "../../../assets/topology/offline-light.svg";
import {createLogger} from "../../common/util";

const logger = createLogger('ui:topology:device-pill');

export function doPrepareSvgDevicePill(base, {nodeWidth, nodeHeight}) {
  logger('base', base);
  const fullWidth = nodeWidth;
  const fullHeight = nodeHeight;
  base.append('rect')
    .attr('width', fullWidth)
    .attr('height', fullHeight)
    .attr('fill', '#F6F6F6')
    .attr('rx', fullHeight / 2)
    .attr('ry', fullHeight / 2);

  const title = base
    .append('text')
    .text((d) => d?.data?.friendlyName)
    .attr('x', fullWidth / 2)
    .attr('y', fullHeight / 2 - 10)
    .attr('alignment-baseline', 'middle')
    .attr('text-anchor', 'middle')
    .attr('font-weight', 'bold')
    .attr('class', 'topology-title');
  const titleBBox = title.node().getBBox();

  base
    .append('svg:image')
    .attr('xlink:href', (d) => d?.data?.status === 'ONLINE' ? onlineLight : offlineLight)
    .attr('x', titleBBox.x - 15)
    .attr('y', titleBBox.y + 4)
    .attr('width', 8)
    .attr('height', 8)
    .attr('class', 'topology-status');

  base
    .append('text')
    .text((d) => {
      let message = '';
      if (d?.data?.status === 'ONLINE') {
        const c = _.get(d, 'data.count', 0);
        message = `${c} devices`;
      } else {
        message = 'Offline';
      }
      return message;
    })
    .attr('x', fullWidth / 2)
    .attr('y', fullHeight / 2 + 10)
    .attr('alignment-baseline', 'middle')
    .attr('text-anchor', 'middle')
    .attr('class', 'topology-count');

}

export function doPrepareSvgNodePill(base, {nodeWidth, nodeHeight}) {
  logger('base', base);
  const fullWidth = nodeWidth;
  const fullHeight = nodeHeight;
  base.append('rect')
    .attr('width', fullWidth)
    .attr('height', fullHeight)
    .attr('fill', '#F6F6F6')
    .attr('rx', fullHeight / 2)
    .attr('ry', fullHeight / 2);

  base
    .append('text')
    .text((d) => d?.data?.macAddress)
    .attr('x', fullWidth / 2)
    .attr('y', fullHeight / 2)
    .attr('alignment-baseline', 'middle')
    .attr('text-anchor', 'middle')
    .attr('font-weight', 'bold')
    .attr('class', 'topology-title');

}

export function SvgPill() {
  const myRef = useRef(null);

  useEffect(() => {
    const root = d3.select(myRef.current);
    doPrepareSvgDevicePill(root);
  }, [myRef]);

  return <g ref={myRef}/>;
}

export function doPrepareSvgTopPill(base, {total, nodeWidth, nodeHeight}) {
  logger('baseTOP', base);
  const fullWidth = nodeWidth;
  const fullHeight = nodeHeight;
  base.append('rect')
    .attr('width', fullWidth)
    .attr('height', fullHeight)
    .attr('fill', 'white')
    .attr('rx', fullHeight / 2)
    .attr('ry', fullHeight / 2)
    .attr('stroke', 'rgba(204, 204, 204, 1)')
    .attr('stroke-width', 0.8);

  const globalImage = base
    .append('svg:image')
    .attr('xlink:href', (d) => d?.data?.hasIssue ? RedGlobal : global)
    .attr('x', 32)
    .attr('y', 20)
    .attr('width', 48)
    .attr('height', 48)
    .attr('class', 'topology-top-image');

  const globalImageBBox = globalImage.node().getBBox();

  const totalNumber = base
    .append('text')
    .text(total)
    .attr('x', globalImageBBox.x + globalImageBBox.width + 15)
    .attr('y', fullHeight / 2 + 8)
    .attr('font-size', 22)
    .attr('font-weight', 'bold')
    .attr('class', 'topology-top-total');

  const totalNumberBBox = totalNumber.node().getBBox();

  base
    .append('text')
    .text('Devices')
    .attr('x', totalNumberBBox.x + totalNumberBBox.width + 10)
    .attr('y', fullHeight / 2 + 6)
    .attr('class', 'topology-top-count');

}
