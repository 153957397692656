import {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {selectCustomerId} from "../auth/_authSliceSelect";
import {createLogger} from "../common/util";
import {addToast} from "../common/toast";
import {useIoT, useIotMessageCallback} from "../iot/_iotHook";
import {selectViewNetworkId} from "./_topologySlice";
import {getTopic} from "./topologyIoT";
import {startFetchLatestSpeedTest} from "../common/commonAsyncThunks";

const logger = createLogger('ui:topology:speedTest:iot');

export const HomeSpeedTestIoT = () => {
  const dispatch = useDispatch();
  const networkId = useSelector(selectViewNetworkId);
  const customerId = useSelector(selectCustomerId);

  // IoT
  const iotParam = useMemo(() => {
    const topic = getTopic({customerId, networkId});
    logger(`useIotMessageCallback, topic: ${topic}`);
    return {
      topic: topic,
      orgId: customerId,
      filter: (message) => {
        const event = _.get(message, 'payload.event');
        return event === 'SPEED_TEST';
      },
      callback: (message) => {
        const event = _.get(message, '[0].payload.event');
        if (event === 'SPEED_TEST') {
          dispatch(startFetchLatestSpeedTest({networkId}));
          addToast('Speed Test is completed', 'info');
        }
        logger('IoT message received: ', message);
      }
    };
  }, [dispatch, customerId, networkId]);
  useIoT(iotParam);
  useIotMessageCallback(iotParam);
  return null;
}
