import React from 'react';
import {Brand} from './Brand';

export const SidebarHeader = ({ logo }) => {
  return (
    <div className='sidebar-header'>
      <Brand logo={logo} />
    </div>
  );
};
