import { superstructResolver } from '@hookform/resolvers/superstruct';
import React, { useState } from "react";
import { Button, Form, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as s from 'superstruct';
import { createLogger } from "../../common/util";
import { hasError } from "../../common/validation";
import { addURLFilter } from "../_securitySlice";
import { useDispatch } from "react-redux";

const logger = createLogger('ui:security:webfilter:AddUrlContent');

// const isURL = (string) => {
//   // eslint-disable-next-line no-useless-escape
//   const urlMatcher = /^(ht|f)tp(s?)\:\/\/[0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*(:(0-9)*)*(\/?)([a-zA-Z0-9\-\.\?\,\'\/\\\+&amp;%\$#_]*)?$/;
//   return urlMatcher.test(string);
// };

// const URL = s.define('URL', isURL);

const isHost = (string) => {
  // eslint-disable-next-line no-useless-escape
  const hostMatcher = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)+([A-Za-z]|[A-Za-z][A-Za-z0-9\-]*[A-Za-z0-9])$/;
  return hostMatcher.test(string);
};

const HOST = s.define('URL', isHost);

const schema = s.object({
  url: HOST,
  action: s.enums(['allow', 'block']),
});

export function AddUrlContent({ handleClose }) {
  const dispatch = useDispatch();
  const [switchValue, setSwitchValue] = useState();

  const form = useForm({
    resolver: superstructResolver(schema, {
      coerce: true
    })
  });

  const { register, setValue, handleSubmit, formState: { errors } } = form;

  const onSubmit = async data => {
    logger("submit: data=%o", data);
    dispatch(addURLFilter(data));
    handleClose(true);
  };

  const onError = (error) => {
    logger('add URL filter Form error:', error);
  };

  const handleChange = (val) => {
    logger('in handleChange val=%o', val);
    setSwitchValue(val);
    setValue('action', val);
  };

  return (
    <div>
      <div className="mb-6">
        <h3>Add a URL</h3>
      </div>
      <Form className="form-sf w-auto" onSubmit={handleSubmit(onSubmit, onError)}>
        <Form.Group className="mb-4">
          <Form.Control name="url" type="text" className={hasError(errors, `url`) ? "is-invalid" : ""}
            {...register("url")} />
          {
            hasError(errors, `url`) &&
            <div className="row mt-2">
              <div className="col-12 sf-text-f-body2 sf-text-c-red">must be a valid host</div>
            </div>
          }
        </Form.Group>
        <Form.Group className="mb-8">
          <Form.Label>Select an option</Form.Label>
          <div className="sf-switch-btn-group">
            <ToggleButtonGroup type="radio" name="sf-switch" value={switchValue} onChange={handleChange}>
              <ToggleButton id="tbg-radio-allow" value="allow" className={hasError(errors, `action`) ? "is-invalid sf-btn-allow" : "sf-btn-allow"}>
                {switchValue === 'allow' ?
                  <i className="sf-icon-allow-selected mr-2" /> :
                  <i className="sf-icon-allow mr-2" />
                }
                Allow
              </ToggleButton>
              <ToggleButton id="tbg-radio-block" value="block" className={hasError(errors, `action`) ? "is-invalid sf-btn-block" : "sf-btn-block"}>
                {switchValue === 'block' ?
                  <i className="sf-icon-block-selected mr-2" /> :
                  <i className="sf-icon-block mr-2" />
                }
                Block
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          {
            hasError(errors, "action") &&
            <div className="row mt-2">
              <div className="col-12 sf-text-f-body2 sf-text-c-red">Option must be selected</div>
            </div>
          }
        </Form.Group>
        <Form.Group>
          <Button className="sf-btn-w-default mr-4" variant="primary" type="submit" size="xlg">Save</Button>
          <Button className="sf-btn-w-default" variant="secondary" onClick={e => { e.preventDefault(); handleClose(false); }}>Cancel</Button>
        </Form.Group>
      </Form>
    </div>
  );
}
