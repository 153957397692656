import React, {useMemo} from "react";
import _ from "lodash";
import {useSelector} from "react-redux";
import SpeedTestUpload from "../../assets/speeds/speedtest-download.svg";
import SpeedTestLatency from "../../assets/speeds/speedtest-latency.svg";
import SpeedTestDownload from "../../assets/speeds/speedtest-upload.svg";
import {selectAllSpeedTestResults, selectAllSpeedTestResultsLoadingState} from "./_speedsSlice";
import {SfCollapse} from "../common/SfCollapse";
import {LoadingOverlay} from "../loader/LoadingOverlay";
import {RemoteTable} from "../data/RemoteTable";
import {LoadingState} from "../common/util";


const columns = () => [
  {
    Header: 'Date & Time',
    accessor: row => {
      const dateTime = _.get(row, 'dateTime', '');
      return dateTime;
    },
    sortType: 'basic',
    minWidth: 150,
    width: 200,
  },
  {
    Header: 'Ping',
    accessor: row => {
      const latency = _.get(row, 'latency', '');
      return latency;
    },
    Cell: (cell) => {
      const latency = _.get(cell.row.original, 'latency', '')
      return (
        <div className="d-flex justify-content-start align-items-center">
          <img alt='' src={SpeedTestLatency}/>
          <div className='ml-3'>{latency} ms</div>
        </div>
      )
    },
    sortType: 'basic',
    minWidth: 150,
    width: 200,
  },
  {
    Header: 'Download',
    accessor: row => {
      const download = _.get(row, 'download', '');
      return download;
    },
    Cell: (cell) => {
      const download = _.get(cell.row.original, 'download', '')
      return (
        <div className="d-flex justify-content-start align-items-center">
          <img alt='' src={SpeedTestDownload}/>
          <div className='ml-3'>{download} mbps</div>
        </div>
      )
    },
    sortType: 'basic',
    minWidth: 150,
    width: 200,
  },
  {
    Header: 'Upload',
    accessor: row => {
      const upload = _.get(row, 'upload', '');
      return upload;
    },
    Cell: (cell) => {
      const upload = _.get(cell.row.original, 'upload', '')
      return (
        <div className="d-flex justify-content-start align-items-center">
          <img alt='' src={SpeedTestUpload}/>
          <div className='ml-3'>{upload} mbps</div>
        </div>
      )
    },
    sortType: 'basic',
    minWidth: 150,
    width: 200,
  }
]

export const SpeedTestAllResults = () => {
  const loadingState = useSelector(selectAllSpeedTestResultsLoadingState);
  const results = useSelector(selectAllSpeedTestResults);
  const load = useMemo(() => async () => {
    return null;
  }, []);
  return (
    <SfCollapse text="Show details">
      <div className='w-100 mt-4'>
        <LoadingOverlay initialized={loadingState === LoadingState.LOADED}
                        className="display-5 d-flex rounded-lg">
          {loadingState === LoadingState.LOADED &&
          <RemoteTable className={results?.length === 0 ? "d-none" : "mx-n4"}
                       scrollableTarget="customer-device-app-body"
                       columns={columns()} data={results}
                       loader={load} tableHeaderHeight={59}/>
          }
        </LoadingOverlay>
      </div>
    </SfCollapse>
  );
}