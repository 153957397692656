import React from 'react';
import {SidebarHeader} from './SidebarHeader';
import {SidebarFooter} from './SidebarFooter';
import {SidebarContent} from './SidebarContent';

/**
 * Sidebar component
 *
 * Please refer to the structure of menu items below
 *
 const menuItems = [
 {
    title: 'Title to display',
    link: '/link/to/page',
    icon: {
      type: 'js' | 'url',
      src: <FontAwesomeIcon icon={faUsers} className='mr-3'/>, // use in type='js'
      src: <SvgReactComponent/>, // use in type='js'
      src: `${process.env.PUBLIC_URL}/images/image.svg`, // use in type='url'
    },
    hidden: () => false | true, // Whether to display this item, default is a function returns `false`
    pathMatch: 'full' | 'prefix'
  }
 ];
 *
 * @param className
 * @param displayHeader
 * @param displayFooter
 * @param menuItems
 * @param logo
 * @param version
 * @returns {JSX.Element}
 */
export const Sidebar = ({ className, displayHeader = false, displayFooter = false, menuItems = [], logo, version, SidebarContentHeader }) => {
  return (
    <div className={`sidebar ${className || ""}`}>
      {displayHeader && <SidebarHeader logo={logo} />}
      <SidebarContent SidebarContentHeader={SidebarContentHeader} menuItems={menuItems} />
      {displayFooter && <SidebarFooter version={version} />}
    </div>
  );
};
