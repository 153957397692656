import React from "react";
import SpeedTestClock from "../../assets/speeds/speedtest-clock.png";
import {SpeedTestButton} from "./SpeedTestButton";

export const SpeedTestNoRecord = () => {
  return (
    <div className='py-7 d-flex align-items-center justify-content-center flex-column'>
      <h4>You haven’t run any speed checks yet</h4>
      <div className='my-5'>
        <img alt='' src={SpeedTestClock}/>
      </div>
      <div>
        <SpeedTestButton text='Run Speed Test'/>
      </div>
    </div>
  );
};