import React, {useEffect} from "react";
import {useForm} from "react-hook-form";
import {Dropdown} from 'react-bootstrap';
import SpeedTestUpload from "../../assets/speeds/speedtest-download.svg";
import SpeedTestDownload from "../../assets/speeds/speedtest-upload.svg";
import SpeedTestLatency from "../../assets/speeds/speedtest-latency.svg";
import {SfFrame} from "../common/SfFrame";
import {addToast} from "../common/toast";
import {SfFormSelect} from "../form/SfFormSelect";

export const SpeedTrendsCard = (
  {
    speed,
    latency,
    dateTime,
    download = 0,
    upload = 0
  }) => {
  const form = useForm({
    defaultValues: {
      type: 'download'
    }
  });
  const {control, watch} = form;
  const watchType = watch('type');
  useEffect(() => {
    if (watchType === 'upload') {
      addToast('Not implemented');
    }
  }, [watchType])
  return (
    <SfFrame>
      <div className='p-6'>
        <div className='d-flex justify-content-between'>
          <h4>{speed}</h4>
          <span className='sf-text-secondary sf-font-body2'>
            {dateTime}
          </span>
        </div>
        <div className='mt-6'>
          <CardSelect control={control} hidden={speed === 'Average'}/>
        </div>
        <div className='d-flex align-items-center mt-6'>
          <div className='mr-4'>
            <img alt='' src={SpeedTestLatency}/>
          </div>
          <h1>{latency}</h1>
          <div className='sf-text-f-body1 sf-text-f-body2 sf-text-c-secondary ml-4 mt-2'>Ping<br/>ms</div>
        </div>
        <div className='d-flex align-items-center mt-6'>
          <div className='mr-4'>
            <img alt='' src={SpeedTestDownload}/>
          </div>
          <h1>{download}</h1>
          <div className='sf-text-f-body1 sf-text-f-body2 sf-text-c-secondary ml-4 mt-2'>Download<br/>mbps</div>
        </div>
        <div className='d-flex align-items-center mt-6'>
          <div className='mr-4'>
            <img alt='' src={SpeedTestUpload}/>
          </div>
          <h1>{upload}</h1>
          <div className='sf-text-f-body1 sf-text-f-body2 sf-text-c-secondary ml-4 mt-2'>Upload<br/>mbps</div>
        </div>
      </div>
    </SfFrame>
  );
};

const CardSelect = ({control, hidden}) => {
  return (
    <div style={{height: '3rem'}}>
      {
        !hidden &&
        <SfFormSelect name="type" defaultValue="download" control={control}>
          <Dropdown.Item eventKey="download">Download Speed</Dropdown.Item>
          <Dropdown.Item eventKey="upload">Upload Speed</Dropdown.Item>
        </SfFormSelect>
      }
    </div>
  );
}