import {store} from '../../common/app';
import * as _ from 'lodash';
import {addToast} from '../../common/toast';
import {isEmpty} from '../../common/util';

/**
 * See if user has required role permission.
 * Need to call setStore() to assign store instance to sf-ui-components.
 * @param reqRole{Array | string} required roles, can be string or Array
 * @returns {boolean} whether the user has permission
 */
export function hasPermission(reqRoles) {
  if (store === undefined) {
    addToast('Redux store instance not found!', 'error');
    return false;
  }
  const userRoles = _.get(store.getState(), 'auth.profile.roles', []);
  let r = [];
  if (Array.isArray(reqRoles) && reqRoles.length > 0) {
    r = reqRoles;
  } else if (typeof reqRoles === 'string' && !isEmpty(reqRoles)) {
    r.push(..._.split(reqRoles, ','));
  } else {
    return false;
  }
  return _.intersection(userRoles, r).length > 0;
}
