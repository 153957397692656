import { useEffect } from "react";
import { selectNetwork, startFetchNetwork } from "../network/_networkSlice";
import { LoadingState } from "../common/util";
import {
  doResetDevicesLoadingState,
  selectDevicesLoadingState,
  selectOfflineDevices,
  selectOnlineDevices,
  startFetchDevices
} from "./_devicesSlice";
import { useDispatch, useSelector } from "react-redux";

export function useConnectedClients({networkId, portal = 'employee', forceReload = false, deviceId = false}) {
  const dispatch = useDispatch();
  const network = useSelector(selectNetwork);
  const devicesLoadingState = useSelector(selectDevicesLoadingState);
  const onlineClients = useSelector(selectOnlineDevices);
  const offlineClients = useSelector(selectOfflineDevices);

  // clear connected client data if force reload.
  useEffect(() => {
    if (forceReload) {
      dispatch(doResetDevicesLoadingState());
    }
  }, [dispatch, forceReload]);

  useEffect(() => {
    if (!network && networkId) {
      dispatch(startFetchNetwork({
        networkId
      }));
    }
  }, [ dispatch, network, networkId ]);

  // main tabs and device list behavior
  useEffect(() => {
    if (networkId && devicesLoadingState === LoadingState.NONE) {
      dispatch(startFetchDevices({ networkId, portal }));
    }
  }, [ dispatch, networkId, devicesLoadingState, portal ]);

  return {
    reload: () => {
      dispatch(doResetDevicesLoadingState());
    },
    network,
    onlineClients,
    offlineClients
  };
}