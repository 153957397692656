import React, { useEffect, useState } from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import { useProfile } from '../../auth/_authHooks';
import { debug } from 'debug';
import _ from 'lodash';
import { isEmpty } from '../../common/util';
import { useDispatch } from 'react-redux';
import { startLogout } from '../../auth/_authSlice';
import { useHistory } from 'react-router-dom';
import { PeopleAvatar } from 'lsui-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const logger = debug('sf:ui:userMenu');

/**
 * See @param dropdownList for example.
 *
 * @param menuConfig:

 defaultMenuConfig = {
  profilePath: '/profile',
  showProfile: true,
  showLogout: true,
  dropdownList: [
    {
      title: 'Profile',
      handleClick: ({ history }) => {
        logger('Profile is clicked');
        history.push('/profile');
      }
    },
    {
      title: 'Settings',
      handleClick: ({ history }) => {
        logger('Setting is clicked');
        history.push('/settings');
      }
    },
    {
      title: 'Logout',
      handleClick: ({ history }) => {
        logger('Logout is clicked');
      }
    }
  ]
}

 * @returns {JSX.Element}
 * @constructor
 */
export const UserMenu = ({ menuConfig = defaultMenuConfig }) => {
  return <UserNavDropDown menuConfig={menuConfig} />;
};

export const defaultMenuConfig = {
  dropdownList: [
    {
      title: 'Profile',
      handleClick: ({ history }) => {
        logger('Profile is clicked');
        history.push('/profile');
      }
    },
    {
      title: 'Settings',
      handleClick: ({ history }) => {
        logger('Setting is clicked');
        history.push('/settings');
      }
    },
    {
      title: 'Logout',
      handleClick: ({ history }) => {
        logger('Logout is clicked');
      }
    }
  ]
};

const UserNavDropDown = ({ menuConfig }) => {
  const profile = useProfile();
  return (
    <Nav className='justify-content-end align-items-center'>
      <NavDropdown title={DropDownTitle({ profile })} bsPrefix='user-menu'>
        {renderItems({ profile, menuConfig })}
      </NavDropdown>
    </Nav>
  );
};

const DropDownTitle = ({ profile }) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='mr-4'>
        <UserIcon profile={profile} />
      </div>
      <div>
        <FontAwesomeIcon icon={faChevronDown} />
      </div>
    </div>
  );
};

const renderItems = ({ profile, menuConfig: { dropdownList } }) => {
  return dropdownList.map((item, index) => {
    return <NavItem key={index} profile={profile} item={item} isLastItem={index === dropdownList.length - 1} />;
  });
};

const NavItem = ({ profile, item, isLastItem }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const email = _.get(profile, 'username', '');
  const firstName = _.get(profile, 'firstname', '');
  const lastName = _.get(profile, 'lastname', '');
  switch (item.title) {
    case 'Profile':
      return (
        <NavDropdown.Item key={item} onClick={() => item.handleClick({ history })} className={item.className}>
          {firstName} {lastName}
          <div id='auth-nav-email'>{email}</div>
        </NavDropdown.Item>
      );
    case 'Logout':
      return (
        <>
          {isLastItem && <NavDropdown.Divider />}
          <NavDropdown.Item
            key={item}
            onClick={() => {
              if (typeof item.handleClick === 'function') {
                item.handleClick({ history });
              }
              startLogout({ dispatch, history }).then(() => {});
            }}
            className={item.className}
          >
            Sign out
          </NavDropdown.Item>
        </>
      );
    default:
      return (
        <NavDropdown.Item key={item} onClick={() => item.handleClick({ history })} className={item.className}>
          {item.title}
        </NavDropdown.Item>
      );
  }
};

const UserIcon = ({ profile }) => {
  const avatarUrl = _.get(profile, 'preferences.avatarUrl');
  const [imgSrc, setImgSrc] = useState(avatarUrl);
  const firstName = _.get(profile, 'firstname', '');
  const lastName = _.get(profile, 'lastname', '');
  useEffect(() => {
    if (!isEmpty(avatarUrl)) {
      setImgSrc(avatarUrl);
    } else {
      // Will continue in Corporate portal.
      // dispatch(startFetchUserIcon());
    }
  }, [profile]);
  return <PeopleAvatar imageUrl={imgSrc} text={firstName + ' ' + lastName} letters={2} />;
};
