import {createAsyncThunk, createSlice, unwrapResult} from '@reduxjs/toolkit';
import {getAxiosInstance as axios} from '../auth/axios/axiosCommon';
import _ from "lodash";
import {composeUrl, createLogger, LoadingState, unwrapErrorResponse} from "../common/util";
import {envBaseApiUrl} from "../common/env";

const logger = createLogger("ui:network:slice");

export const IOT_STATUS_EVENTS = Object.freeze({
  DEVICE_ONLINE: 'DEVICE_ONLINE',
  DEVICE_OFFLINE: 'DEVICE_OFFLINE',
  VPN_UP: 'VPN_UP',
  VPN_DOWN: 'VPN_DOWN'
});

export const startFetchAccountNetwork = createAsyncThunk(
  'network/fetchAccountNetwork',
  async (input, thunkAPI) => {
    logger('[fetchAccountNetwork] begin: %o', input);
    const { customerId, accountId, fields = ['Stat', 'VPN'] } = input;
    const url = composeUrl(`${envBaseApiUrl}/organization-service/rest/organizations/${customerId}/networks`, {
      accountId: accountId,
      fields,
    });

    return axios().get(url)
      .then(it => it?.data)
      .catch((error) => {
        throw unwrapErrorResponse(error);
      });
  }
);

export const startFetchFirstAccountNetwork = createAsyncThunk(
  'network/fetchFirstAccountNetwork',
  async (input, {dispatch}) => {
    logger('[fetchFirstAccountNetwork] begin: %o', input);
    await dispatch(startFetchAccountNetwork(input))
      .then(unwrapResult);
    dispatch(doUseFirstNetwork());
  }
);

export const startFetchNetwork = createAsyncThunk(
  'topology/fetchNetwork',
  async (input, thunkAPI) => {
    logger('[fetchNetwork] begin: %o', input);
    const { networkId, fields } = input;
    const url = composeUrl(`${envBaseApiUrl}/network-service/rest/networks/${networkId}`, {
      fields
    });

    return axios().get(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw unwrapErrorResponse(error);
      })
  }
);

export const startFetchNetworkDevices = createAsyncThunk(
  'network/fetchNetworkDevices',
  async (input, thunkAPI) => {
    logger('[fetchNetworkDevices] begin: %o', input);
    const { networkId } = input;
    const url = composeUrl(`${envBaseApiUrl}/network-service/rest/networks/${networkId}/devices`);

    return axios().get(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        const errorResp = unwrapErrorResponse(error);
        throw errorResp;
      })
  }
);

export const startFetchNetworkClientsCount = createAsyncThunk(
  'network/fetchNetworkClientsCount',
  async (input, thunkAPI) => {
    logger('[fetchNetworkClientsCount] begin: %o', input);
    const { networkId } = input;
    const url = composeUrl(`${envBaseApiUrl}/network-delegation-service/rest/networks/${networkId}/clients/count`);

    return axios().get(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw unwrapErrorResponse(error);
      })
  }
);

export const networkSlice = createSlice({
  name: 'network',
  initialState: {
    accountNetworks: undefined,
    network: undefined,
    networkLoadingState: LoadingState.NONE,
    devicesLoadingState: LoadingState.NONE
  },
  reducers: {
    updateNetworkLoadingState: (state, action) => {
      state.networkLoadingState = action.payload;
    },
    updateNetworkDevicesLoadingState: (state, action) => {
      state.devicesLoadingState = action.payload;
    },
    updateNetworkDevices: (state, action) => {
      logger("Update network devices: %o", action?.payload);
      if (state.devices == null) {
        state.devices = [];
      }
      state.devices = action?.payload?.data;
    },
    useFirstNetwork: (state, action) => {
      state.network = _.get(state, "accountNetworks[0]", null);
    }
  },
  extraReducers: {
    [startFetchAccountNetwork.pending]: (state, action) => {
      logger("startFetchAccountNetwork.pending: %o, %o", state, action);
      state.networkLoadingState = LoadingState.LOADING;
    },
    [startFetchAccountNetwork.fulfilled]: (state, action) => {
      logger("startFetchAccountNetwork.fulfilled: %o, %o", state, action);
      state.accountNetworks = action.payload?.data;
      state.networkLoadingState = LoadingState.LOADED;
    },
    [startFetchAccountNetwork.rejected]: (state, action) => {
      logger("startFetchAccountNetwork.rejected: %o, %o", state, action);
      state.networkLoadingState = LoadingState.LOADED;
    },
    [startFetchNetwork.pending]: (state, action) => {
      logger("startFetchNetwork.pending: %o, %o", state, action);
      state.networkLoadingState = LoadingState.LOADING;
    },
    [startFetchNetwork.fulfilled]: (state, action) => {
      logger("startFetchNetwork.fulfilled: %o, %o", state, action);
      state.network = action?.payload;
      state.networkLoadingState = LoadingState.LOADED;
    },
    [startFetchNetwork.rejected]: (state, action) => {
      logger("startFetchNetwork.rejected: %o, %o", state, action);
      state.networkLoadingState = LoadingState.LOADED;
    },
    [startFetchNetworkDevices.pending]: (state, action) => {
      logger("startFetchNetworkDevices.pending: %o, %o", state, action);
      state.devicesLoadingState = LoadingState.LOADING;
    },
    [startFetchNetworkDevices.fulfilled]: (state, action) => {
      logger("startFetchNetworkDevices.fulfilled: %o, %o", state, action);
      state.devicesLoadingState = LoadingState.LOADED;
      return networkSlice.caseReducers.updateNetworkDevices(state, action);
    },
    [startFetchNetworkDevices.rejected]: (state, action) => {
      logger("startFetchNetworkDevices.rejected: %o, %o", state, action);
      state.devicesLoadingState = LoadingState.LOADED;
    },
    [startFetchNetworkClientsCount.pending]: (state, action) => {
      logger("startFetchNetworkClientsCount.pending: %o, %o", state, action);
    },
    [startFetchNetworkClientsCount.fulfilled]: (state, action) => {
      logger("startFetchNetworkClientsCount.fulfilled: %o, %o", state, action);
      state.clientsCount = action.payload;
    },
    [startFetchNetworkClientsCount.rejected]: (state, action) => {
      logger("startFetchNetworkClientsCount.rejected: %o, %o", state, action);
    },
  }
});

export const {
  updateNetworkLoadingState,
  updateNetworkDevices: doUpdateNetworkDevices,
  updateNetworkDevicesLoadingState: doUpdateNetworkDevicesLoadingState,
  useFirstNetwork: doUseFirstNetwork,
} = networkSlice.actions;

export const selectNetworks = state => _.get(state, "network.networks");
export const selectNetwork = state => state?.network?.network;
export const selectNetworkId = state => state?.network?.network?.id;
export const selectNetworkDevices = state => _.get(state, 'network.devices', []);
export const selectNetworkLoadingState = state => _.get(state, 'network.networkLoadingState', LoadingState.NONE);
export const selectNetworkDevicesLoadingState = state => _.get(state, 'network.devicesLoadingState', LoadingState.NONE);
export const selectNetworkDeviceCounts = state => _.get(state, 'network.network.stat.deviceCounts', 0);
export const selectNetworkOfflineDeviceCounts = state => _.get(state, 'network.network.stat.offlineDeviceCounts', 0);
export const selectNetworkUnProvisionedCounts = state => _.get(state, 'network.network.stat.unProvisionedCounts', 0);
export const selectNetworkClientsCount = state => _.get(state, 'network.clientsCount');

export const networkReducer = networkSlice.reducer;
