import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {IspForm} from "./IspForm";
import {ISP_ACTIONS, selectIspAction, selectIspInfo, updateIspAction} from "./_speedsSlice";

export const IspInfo = ({editable = false}) => {
  const dispatch = useDispatch();
  const ispAction = useSelector(selectIspAction);
  return (
    <div>
      <div className="d-flex align-items-center row">
        <h3 className='col-md-10 col-lg-8'>ISP Info</h3>
        {
          ispAction === ISP_ACTIONS.VIEW && editable &&
          <div>
            <a className='sf-text-primary sf-font-h5' href='#/' onClick={(e) => {
              e.preventDefault();
              dispatch(updateIspAction(ISP_ACTIONS.EDIT));
            }}>Edit</a>
          </div>
        }
      </div>
      <div className='mt-6 row'>
        <div className='col-md-10 col-lg-6'>
          {ispAction === ISP_ACTIONS.EDIT && <IspForm/>}
          {ispAction === ISP_ACTIONS.VIEW && <IspView/>}
        </div>
      </div>
    </div>
  );
};

const IspView = () => {
  const ispInfo = useSelector(selectIspInfo);
  return (
    <>
      <div className='row'>
        <div className='col-3 sf-text-secondary sf-font-body1'>
          ISP
        </div>
        <div className='col-9 sf-font-body1'>
          {ispInfo?.ispName}
        </div>
      </div>
      <div className='row'>
        <div className='col-3 sf-text-secondary sf-font-body1'>
          Self-reported ISP <br/> download plan:
        </div>
        <div className='col-9 sf-font-body1'>
          {ispInfo?.ispMaxSpeed} Mbps
        </div>
      </div>
    </>
  );
};
