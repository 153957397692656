import React, {useMemo, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faTimes} from '@fortawesome/free-solid-svg-icons';
import {useDispatch, useSelector} from 'react-redux';
import {setIsPasswordMatchRequirements} from '../_authSlice';
import {selectIsPasswordMatchRequirements} from '../_authSliceSelect';

export const PasswordSecurityRequirements = ({ className, value = '' }) => {
  const [isRule1Valid, setIsRule1Valid] = useState(false); // 10 Characters
  const [isRule2Valid, setIsRule2Valid] = useState(false); // Upper & lowercase letters
  const [isRule3Valid, setIsRule3Valid] = useState(false); // 1 number
  const [isRule4Valid, setIsRule4Valid] = useState(false); // Special character
  const regexNumber = new RegExp('(.*[0-9].*)');
  const regexUppercase = new RegExp('(.*[A-Z].*)');
  const regexLowercase = new RegExp('(.*[a-z].*)');
  const regexOneSpecialCharacter = new RegExp(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/);
  const isValid = useSelector(selectIsPasswordMatchRequirements);
  const dispatch = useDispatch();

  useMemo(() => {
    setIsRule1Valid(value.length >= 10);
    setIsRule2Valid(regexUppercase.test(value) && regexLowercase.test(value));
    setIsRule3Valid(regexNumber.test(value));
    setIsRule4Valid(regexOneSpecialCharacter.test(value));
  }, [value]);

  useMemo(() => {
    const result = isRule1Valid && isRule2Valid && isRule3Valid && isRule4Valid;
    if (result !== isValid) {
      dispatch(setIsPasswordMatchRequirements(result));
    }
  }, [isRule1Valid, isRule2Valid, isRule3Valid, isRule4Valid]);

  return (
    <div className={className}>
      <section>
        <div className='sf-font-h5'>Your password must have:</div>
      </section>
      <section className='mt-4'>
        <Rule name='At least 10 characters' isValid={isRule1Valid} />
        <Rule name='Upper & lowercase letters' isValid={isRule2Valid} />
        <Rule name='1 number' isValid={isRule3Valid} />
        <Rule name='1 special character' isValid={isRule4Valid} />
      </section>
    </div>
  );
};

const Rule = ({ name, isValid = false }) => {
  return (
    <div className='mt-2 d-flex'>
      {isValid ? (
        <div className='sf-text-c-lime-green mr-1' style={{ width: '1.2rem' }}>
          <FontAwesomeIcon className='visible-animated' icon={faCheck} />
        </div>
      ) : (
        <div className='sf-text-c-red mr-1' style={{ width: '1.2rem' }}>
          <span>
            <FontAwesomeIcon className='visible-animated' icon={faTimes} />
          </span>
        </div>
      )}
      <span className={'sf-font-body1 ' + (isValid ? '' : 'sf-text-c-red')}>{name}</span>
    </div>
  );
};
