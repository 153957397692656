import {useDispatch, useSelector} from 'react-redux';
import {
  pushMessageToQueue,
  removeMessageFromQueue,
  removeMessagesFromQueue,
  selectIotCredentials,
  selectIotStatus,
  selectIotTopicMessage,
  selectIotTopicSubscribed,
  startGetCredentials,
  updateIotStatus
} from './_iotSlice';
import {createContext, useContext, useEffect, useState} from 'react';
import {selectToken} from '../auth/_authSliceSelect';
import awsIot from 'aws-iot-device-sdk';
import {envAwsIotHostUrl, envAwsRegion, portal} from '../common/env';
import {addToast} from '../common/toast';
import {createLogger, isEmpty, parseJson, timeout, unit8ArrayDecoder} from '../common/util';
import {IotStatus, subscribe, unsubscribe} from './_iot';

const logger = createLogger('ui:iot:hook');
const trace = createLogger('ui:iot:hook:trace');

export const IotContext = createContext(null);

export function useIotConnection({ orgId, networkId }) {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const credentials = useSelector(selectIotCredentials);
  const { device, setDevice } = useContext(IotContext);

  useEffect(() => {
    if (isEmpty(token)) {
      return;
    }
    switch (portal) {
      case 'employee':
        if (!isEmpty(orgId) && !isEmpty(networkId)) {
          trace('IoT: start getting credentials.');
          dispatch(startGetCredentials({ orgId, networkId }));
        }
        break;
      case 'corporate':
        if (!isEmpty(orgId)) {
          trace('IoT: start getting credentials.');
          dispatch(startGetCredentials({ orgId }));
        }
        break;
      case 'linksys':
      default:
        trace('IoT: start getting credentials.');
        dispatch(startGetCredentials({}));
    }
  }, [token, portal, orgId, networkId]);

  useEffect(() => {
    if (credentials) {
      let disconnectWith;
      setDevice((it) => {
        if (it !== undefined) {
          return it;
        }
        const { accessKeyId, secretAccessKey, sessionToken, subject, subjectId } = credentials;
        const clientId = `${subject}:${subjectId}`;
        trace('Start connecting IoT, client: ' + clientId);
        const aDevice = awsIot.device({
          host: envAwsIotHostUrl,
          clientId: clientId,
          region: envAwsRegion,
          protocol: 'wss',
          accessKeyId: accessKeyId,
          secretKey: secretAccessKey,
          sessionToken: sessionToken
        });
        disconnectWith = aDevice;
        trace('Create device instance:', aDevice);

        aDevice.on('connect', function () {
          dispatch(updateIotStatus(IotStatus.CONNECT));
          addToast('IoT is connected');
        });
        aDevice.on('close', function () {
          dispatch(updateIotStatus(IotStatus.CLOSE));
          logger('IoT is closed');
        });
        aDevice.on('reconnect', function () {
          dispatch(updateIotStatus(IotStatus.RECONNECT));
          // addToast('IoT is reconnected', 'success');
          logger('IoT is reconnected');
        });
        aDevice.on('message', function (topic, payload) {
          try {
            const p = parseJson(unit8ArrayDecoder(payload));
            trace('IoT message received: %s, %o', topic, p);
            // redux
            dispatch(pushMessageToQueue({ topic, payload: p, ts: new Date().getTime() }));
          } catch (e) {
            logger('Error processing message: %o', e);
            addToast('IoT message processing failed', 'error');
          }
        });
        aDevice.on('error', function (error) {
          // addToast(`IoT error occurred: ${error.toString()}`, 'error');
          logger(`IoT error occurred:`, error);
        });
        return aDevice;
      });

      // Looks like it will unnecessarily disconnect after switching page. Disable for now.
      // if (disconnectWith != null) {
      //   return () => disconnect({dispatch, device: disconnectWith});
      // }
    }
  }, [credentials]);
}

export function useIoT({ topic, orgId, networkId }) {
  const dispatch = useDispatch();
  const { device, setDevice } = useContext(IotContext);
  const status = useSelector(selectIotStatus);
  const [subscribing, setSubscribing] = useState(false);
  useIotConnection({ orgId, networkId });
  const subscribed = useSelector(selectIotTopicSubscribed(topic));

  useEffect(() => {
    setSubscribing((it) => {
      if (!it && status === IotStatus.CONNECT && !subscribed) {
        trace('Start subscribing topic %s: %s, %s, %s', topic, it, status, subscribed);
        return true;
      }
      return it;
    });
  }, [status, topic, subscribed]);

  useEffect(() => {
    if (subscribing) {
      subscribe({ dispatch, topic, device });
      return () => unsubscribe({ dispatch, topic, device });
    }
  }, [subscribing]);
}

/**
 * A hook to wait for IoT message.
 * This is useful for the scenario when we want to wait for certain amount of time or timeout.
 *
 * The hook internally maintain one promise.
 * The promise will be activated everytime waitForPromise() is called, and dismissed when a message is received or time is up.
 *
 * Please be advised the promise cannot overlap, i.e. waitForPromise() cannot be called unless all previous promise are completed.
 *
 * @param topic
 * @return {{waitForPromise: (function(*=): Promise<unknown>), removeMessagePromise: removeMessagePromise}}
 */
export function useIotMessage({ topic, filter }) {
  const dispatch = useDispatch();
  const message = useSelector(selectIotTopicMessage(topic, filter));
  const [promise, setPromise] = useState({});

  useEffect(() => {
    logger('Promise changed', promise);
  }, [promise]);

  useEffect(() => {
    logger('IoT message received: %o', message);
    setPromise((it) => {
      if (it.resolve != null) {
        if (Array.isArray(message) && message.length <= 0) {
          // Empty message means nothing in the mailbox, skip it.
          return it;
        }
        // if we have a promise running, resolve it and remove the running promise from state.
        trace('IoT promise resolve with %o', message);
        it.resolve(message);
        return it;
      }
      return it;
    });
  }, [message]);

  const waitForPromise = (maxMillis = 5000) => {
    const p = new Promise((resolve, reject) => {
      setPromise((it) => {
        if (it.promise != null && it.reject != null) {
          it.reject();
        }
        it.resolve = resolve;
        it.reject = reject;
        return it;
      });
    });
    // setPromise((it) => {
    //   it.promise = p;
    //   return it;
    // });
    return Promise.race([p, timeout(maxMillis)]).finally(() => {
      // remove the running promise when promise is fulfilled
      setPromise(() => {
        return {};
      });
    });
  };
  const removeMessagePromise = (messages) => {
    if (Array.isArray(messages) && messages.length > 0) {
      const promises = messages
        .map((it) => it.id)
        .map((id) => {
          logger('Removing IoT message #%s', id);
          return dispatch(removeMessageFromQueue({ topic, id }));
        });
      return Promise.all(promises);
    } else {
      return dispatch(removeMessageFromQueue({ topic, id }));
    }
  };
  return {
    waitForPromise,
    removeMessagePromise
  };
}

/**
 * A hook to use callback style of notification.
 * Once callback is handled, the messages will be deleted from mailbox.
 *
 * @param topic
 * @param {function(any[])} callback Called when new message arrives. An array of newly received messages is used as the only parameter.
 */
export function useIotMessageCallback({ topic, callback, filter }) {
  if (callback == null) {
    throw new Error('callback is required');
  }
  const dispatch = useDispatch();
  const message = useSelector(selectIotTopicMessage(topic, filter));

  useEffect(() => {
    if (Array.isArray(message) && message.length > 0) {
      logger('Processing message through callback: %o', message);
      // notify the caller
      callback(message);
      // remove messages
      const ids = message.map((it) => it.id);
      dispatch(removeMessagesFromQueue({ topic, ids }));
    }
  }, [message]);
}
