import React, {useState} from "react";
import {Button} from "react-bootstrap";
import {useSelector} from "react-redux";
import {FirmwareUpdateDialog} from "./FirmwareUpdateDialog";
import {selectFwUpdateDownloadUrl} from "./_firmwareUpdateSlice";

export const ButtonFwUpdate = ({networkId}) => {
  const downloadUrl = useSelector(selectFwUpdateDownloadUrl);
  const [showFwUpdateDialog, setShowFwUpdateDialog] = useState(false);

  const onClickHandler = () => {
    setShowFwUpdateDialog(true);
  };
  return (
    <>
      <Button variant='primary' className='sf-btn-w-xlg' type='button' onClick={onClickHandler}>
        Update Firmware
      </Button>
      <FirmwareUpdateDialog networkId={networkId} downloadUrl={downloadUrl}
                            showing={showFwUpdateDialog} setShowing={setShowFwUpdateDialog}/>
    </>
  );
};
