import { slice } from "./_inventorySlice";

export { itemNumberComparator } from './_inventoryComparator';
export { useInventory, useInventoryHelper } from './_inventoryHook';
export { toAllInventory, toAvailableInventory, toAllLocalInventory } from './_inventoryMapper';
export {
  selectWarehouseInventory,
  selectLocalLicenseInventory,
  selectInventory,
  selectLocalHardwareInventory,
  selectInventorySummaryHardware,
  selectUnassignedDevices,
  startFetchInventory,
  startFetchUnassignedDevices,
  doAddInventory,
  doResetInventory,
} from './_inventorySlice';

export { InventoryBlock } from './InventoryBlock';
export { LicenseView } from './LicenseView';
export { LocalInventoryView } from './LocalInventoryView';
export { SalesSkuView } from './SalesSkuView';

export const inventoryReducer = slice.reducer;