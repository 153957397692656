import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import {Controller} from 'react-hook-form';
import {createLogger} from '../common/util';

const logger = createLogger('ui:SfFormSelect');

export function SfFormSelect({ children, className, name, control, width = '100%', defaultValue }) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => {
        let toggleContents;

        let displayChildren = children;
        if (children != null) {
          const arrayChildren = React.Children.toArray(children);
          if (arrayChildren.length > 0) {
            // enabledChildren = children.filter(child => child.props.disabled !== true);
            displayChildren = arrayChildren.filter((child) => child.props.eventKey !== value);
            const selectedItem = arrayChildren.find((child) => child.props.eventKey === value);
            // logger('selectedItem=%o', selectedItem);
            if (selectedItem) {
              toggleContents = selectedItem.props.children;
            } else {
              if (defaultValue) {
                const defaultItem = arrayChildren.find((child) => child.props.eventKey === defaultValue);
                if (defaultItem) {
                  toggleContents = defaultItem.props.children;
                } else {
                  toggleContents = defaultValue;
                }
              } else {
                toggleContents = arrayChildren[0].props.children;
              }
            }
          }
        }

        return (
          <>
            <Dropdown
              className='customized-select'
              style={{ width: `${width}` }}
              onSelect={(eventKey) => {
                logger('eventKey=%o', eventKey);
                onChange(eventKey);
              }}
            >
              <Dropdown.Toggle
                variant='white'
                name={name}
                id={name}
                className={`text-left sf-text-f-body1 sf-text-lh-sm form-control icon-muted ${className}`}
              >
                {toggleContents}
                <i className='sf-icon-chevron-down' />
              </Dropdown.Toggle>

              <Dropdown.Menu align={{ lg: 'end' }}>{displayChildren}</Dropdown.Menu>
            </Dropdown>
          </>
        );
      }}
    />
  );
}
